import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ControlledInput from 'components/Form/ControlledInput';
import { useStyles } from '../styles';
import { sortBy } from 'utilities/sorting';

export default function InquiryComments({
  inquiry = {},
  comments,
  viewer,
  handleCreateComment,
}) {
  const classes = useStyles();

  // form states
  const { control, errors, handleSubmit, formState, watch } = useForm();
  const { isSubmitting } = formState;
  const watchIsNote = watch('isNote');

  const messageInputs = [{
    type: 'text',
    name: 'message',
    label: '',
    autoFocus: true,
    size: 'small',
    multiline: true,
    rows: 10,
    required: true,
    placeholder: 'Type here...',
  }];

  if (viewer === 'admin') {
    messageInputs.push({
      type: 'checkbox',
      name: 'isNote',
      label: 'Administrator Note',
    });
  }

  // timeline state
  const { name } = inquiry;
  const filteredComments = ((viewer === 'admin') ?
    comments : comments.filter(({ type }) => type === 'message')).sort(sortBy('createdAt'));

  return (
    <>
      <div className={classes.scrollableTimeline}>
        {filteredComments.map((comment, index) => {
          const { author, type, body, bodyHtml } = comment;
          const commentTitle = author === 'participant' ? `${name}` :
            type === 'note' ? 'Note' : 'Admin';
          return (
            <Timeline key={index} align={author === 'participant' ? 'right' : ''}>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {comment.createdAt.split('T')[0]} ({moment(comment.createdAt).fromNow()})
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={type === 'note' ? classes.paperNote : classes.paper}>
                    <Typography variant="h6" component="h1">{commentTitle}</Typography>

                    {bodyHtml ?
                      <div dangerouslySetInnerHTML={{ __html: bodyHtml }} /> :
                      <Typography>{body}</Typography>}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          );
        })}
      </div>
      {inquiry.status !== 'closed' && (
        <Grid container>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item sm={12}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(handleCreateComment)}
              noValidate
            >
              <Grid container>
                {messageInputs.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <ControlledInput
                        control={control}
                        errors={errors}
                        {...input}
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    size="medium"
                    fullWidth
                    variant="contained"
                    color={(!watchIsNote || watchIsNote === false) ? 'primary' : 'secondary'}
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    {(!watchIsNote || watchIsNote === false) ? 'Send Message' : 'Create Note'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={6}>

          </Grid>
        </Grid>
      )}
    </>
  );
}

InquiryComments.propTypes = {
  inquiry: PropTypes.object,
  comments: PropTypes.array,
  viewer: PropTypes.string,
  handleCreateComment: PropTypes.func,
};
