import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import TripsTable from 'pages/Admin/components/TripsTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';

import { getTrips } from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Trips() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState([]);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);

    const processStatus = ['pending', 'processed', 'failed'];
    const results = await Promise.all(processStatus.map((processStatus) => {
      const queryParams = {
        processStatus,
        createdAt: {
          between: [from, to],
        },
      };
      return asyncListAll(getTrips, queryParams, { bypassCache: true });
    }));

    const trips = results.reduce((allItems, items) => {
      return [...allItems, ...items];
    }, []);

    setTrips(trips.sort(sortBy('createdAt', true)));
    setIsLoading(false);
  };

  useEffect(() => { }, []);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='week'
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />
      <TripsTable data={trips} />
    </div>
  );
}
