import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import StatementsTable from 'pages/Admin/components/StatementsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Statements() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StatementsTable />
    </div>
  );
}
