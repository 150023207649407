import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ParticipantMileageReports from './components/ParticipantMileageReports';
import ParticipantVehicles from './components/ParticipantVehicles';
// import ParticipantComments from './components/ParticipantComments';
import ParticipantTrips from './components/ParticipantTrips';
import VerticalTabs from 'components/Tab/VerticalTabs';
import ParticipantStatements from './components/ParticipantStatements';
import ParticipantNotifications from './components/ParticipantNotifications';
import ParticipantMros from './components/ParticipantMros';
import MroEventsTable from 'pages/Admin/components/MroEventsTable';
import Payments from 'pages/Admin/components/Payments';

import { asyncGet } from 'utilities/graph';

export default function Participant({ username: inUsername, computedMatch }) {
  const [username, setUsername] = useState();
  const [tabs, setTabs] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    if (inUsername) {
      setUsername(inUsername);
    } else
    if (computedMatch) {
      const { params: { username } } = computedMatch;
      setUsername(username);
    }
  }, [inUsername, computedMatch]);

  useEffect(() => {
    if (username) {
      (async () => {
        console.log(username);

        const { data: { getParticipant: user } } = await asyncGet(/* GraphQL */ `
        query GetParticipant($username: String!) {
          getParticipant(username: $username) {
            username
            email
            firstName
            middleName
            lastName
            participantPilotProgramId
            pilotProgram {
              id
              name
              shortName
            }
          }
        }
      `, { username });
        console.log(user);
        setUser(user);
      })();
    }
  }, [username]);

  useEffect(() => {
    if (!username) return;

    const tabs = [
      {
        label: 'Vehicles',
        component: <ParticipantVehicles
          username={username}
        />,
      },
      {
        label: 'MRO Devices',
        component: <ParticipantMros
          username={username}
        />,
      },
      {
        label: 'MRO Events',
        component: <MroEventsTable
          username={username}
        />,
      },
      {
        label: 'Trips',
        component: <ParticipantTrips
          username={username}
          viewer="admin"
        />,
      },
      // {
      //   label: 'Comments',
      //   component: <ParticipantComments
      //     username={username}
      //   />,
      // },
      {
        label: 'Mileage Reports',
        component: <ParticipantMileageReports
          username={username}
        />,
      },
      {
        label: 'Statements',
        component: <ParticipantStatements
          username={username}
        />,
      },
      {
        label: 'Payments',
        component: <Payments username={username} />,
      },
      {
        label: 'Notifications',
        component: <ParticipantNotifications
          username={username}
        />,
      },
    ];
    setTabs(tabs);
  }, [username]);

  if (!username || !user) {
    return null;
  }

  return (
    <Grid container direction="column">
      {computedMatch &&
      <div style={{ paddingBottom: 16 }}>
        <Typography variant="h5" component="h5" align="center">
          {user.firstName} {user.lastName}
        </Typography>
        <Typography variant="body2" component="p" align="center" color="textSecondary" gutterBottom>
          {user.pilotProgram.name} ({user.pilotProgram.shortName})
        </Typography>
      </div>}
      <VerticalTabs
        tabs={tabs}
      />

    </Grid>
  );
}

Participant.propTypes = {
  username: PropTypes.string,
  computedMatch: PropTypes.object,
};
