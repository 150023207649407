import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import { GetTransactionsByStatusByCreatedAt } from './queries';

import TransactionsTable from 'pages/Admin/components/TransactionsTable';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Transactions() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);

    const transactionStatus = ['pending', 'processed', 'failed'];

    const results = await Promise.all(transactionStatus.map((status) => {
      return asyncListAll(GetTransactionsByStatusByCreatedAt, {
        status,
        createdAt: {
          between: [from, to],
        },
      }, {
        bypassCache: true,
      });
    }));

    const transactions = results.reduce((allItems, items) => {
      return [...allItems, ...items];
    }, []).map((transaction) => {
      transaction.participant.fullName = `${transaction.participant.firstName} ${transaction.participant.lastName}`;
      return transaction;
    });

    setTransactions(transactions.sort(sortBy('createdAt', true)));
    setIsLoading(false);
  };

  useEffect(() => { }, []);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='week'
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />
      <TransactionsTable data={transactions} />
    </div>
  );
}
