export const listMileageReportingOptions = /* GraphQL */ `
query ListMileageReportingOptions(
  $username: String
  $id: ModelStringKeyConditionInput
  $filter: ModelMileageReportingOptionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMileageReportingOptions(
    username: $username
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      username
      id
      vehicleId
      deviceSerialNumber
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      deliveredDate
      activationDate
      lastReportedDate
      participant {
        username
        firstName
        lastName
        email
        status
      }
      createdAt
      updatedAt
    }
    nextToken
  }
} 
`;

export const getParticipantByStatus = /* GraphQL */ `
  query GetParticipantsByStatus(
    $status: Status
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $limitVehicles: Int
    $nextToken: String
  ) {
    getParticipantsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        email
        firstName
        middleName
        lastName
        status
        mroDevicePreference
        participantPilotProgramId
        vehicles(limit: $limitVehicles) {
          items {
            id
            mroId
          }
        }
      }
      nextToken
    }
  }
`;
