import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import CognitoUsersTable from './CognitoUsersTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Permission() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <CognitoUsersTable />
    </div>
  );
}
