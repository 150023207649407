import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  alert: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  root: {
    flex: 1,
    position: 'relative',
  },
  floatingCreateButton: {
    position: 'absolute',
    bottom: theme.spacing(-8),
    right: theme.spacing(2),
  },
  floatingBulkUploadButton: {
    position: 'absolute',
    bottom: theme.spacing(-8),
    right: theme.spacing(10),
  },
}));
