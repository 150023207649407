import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { asyncListAll } from 'utilities/graph';
import EpaVehiclesTable from './EpaVehiclesTable';
import { getEPAVehiclesByMakeByYear } from 'graphql/queries';
import { vehicleMakesAndModels } from 'utilities/constants';
import { sortBy } from 'utilities/sorting';

const makeOptions = vehicleMakesAndModels.map(({ brand }) => {
  return brand;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    // width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
}));

const DEFAULT_MAKE = 'Toyota';
const DEFAULT_YEAR = 2020;

export default function EpaVehicles() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [make, setMake] = useState(DEFAULT_MAKE);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [searchParams, setSearchParams] = useState({
    make: DEFAULT_MAKE,
    year: { eq: DEFAULT_YEAR },
  });

  useEffect(() => {
    if (!searchParams) return;
    (async () => {
      try {
        setIsSubmitting(true);

        searchParams.make = `${searchParams.make}`.toUpperCase();

        const records = await asyncListAll(getEPAVehiclesByMakeByYear, searchParams);
        setData(records
          .sort(sortBy('trany'))
          .sort(sortBy('model')),
        );
      } catch (e) {
        console.log(e);
      } finally {
        setIsSubmitting(false);
      }
    })();
  }, [searchParams]);

  return (
    <div>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              id="make"
              options={makeOptions}
              getOptionLabel={(option) => option}
              value={make}
              onChange={(e, newValue) => {
                setMake(newValue);
              }}
              renderInput={(params) => <TextField
                {...params}
                name="make"
                variant="outlined"
                fullWidth
                label="Vehicle Make"
              />}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="year"
              name="year"
              type="number"
              variant="outlined"
              fullWidth
              label="Vehicle Year"
              defaultValue={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} sm={2} alignItems="center">
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                setSearchParams({
                  make: make,
                  year: { eq: year },
                });
              }}
            >
              search
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <EpaVehiclesTable
        data={data}
      />
    </div>
  );
}
