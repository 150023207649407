import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import ControlledInput from 'components/Form/ControlledInput';

import {
  agreeToTerms,
} from 'graphql/mutations';
import {
  asyncRetryMutation,
} from 'utilities/graph';

const RegisterAgreements = ({
  participant,
  username,
  onPreviousStep,
  onCompleteStep,
}) => {
  const classes = useStyles();

  // preference form state
  const { control, errors, handleSubmit, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { isSubmitting } = formState;
  const [error, setError] = useState(false);

  const agreementsInputs = [{
    type: 'checkbox',
    name: 'agreeGlobalTermsAndConditions',
    label: 'By clicking here you agree to:',
    required: true,
    invalidText: 'You must accept all agreements',
  }];

  async function handleAgreements() {
    try {
      await Promise.all([
        asyncRetryMutation(agreeToTerms, {
          input: {
            username,
            agreementDate: new Date().toISOString(),
            agreements: [{
              name: 'agreeGlobalTermsAndConditions',
              version: '0.0.1',
            }],
          },
        }),
      ]);

      onCompleteStep();
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">Agreements</Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleAgreements)}
        noValidate
      >
        <Grid container spacing={2}>
          {agreementsInputs.map((input, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          <ul className={classes.condensedList}>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://tetcoalitionmbuf.org/wp-content/uploads/2023/06/TETC_MBUF_Flyer_CT-Participant-Agreement.pdf">
                The Eastern Transportation Coalition Participant Agreement
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://tetcoalitionmbuf.org/wp-content/uploads/2023/06/TETC_MBUF_Flyer_CT-Participant-Agreement.pdf">
                The Transurban Terms and Conditions and Privacy Policy
              </a>
            </li>
          </ul>

          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              onClick={() => {
                onPreviousStep();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || !formState.isValid}
            >
              Complete
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterAgreements.propTypes = {
  participant: PropTypes.object,
  username: PropTypes.string,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
};

export default RegisterAgreements;
