import React, { Fragment, useState } from 'react';
import { useHistory, Link as RouteLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Logo from '../../components/Logo';
import { useStyles } from './components/commonStyles';
import ControlledInput from 'components/Form/ControlledInput';

const Register = () => {
  const classes = useStyles();
  const history = useHistory();

  // form states
  const { control, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const [email, setEmail] = useState('');
  const [completeNewPassword, setCompleteNewPassword] = useState(false);
  const [error, setError] = useState(false);

  const forgotInputs = [{
    type: 'text',
    name: 'email',
    label: 'Email Address',
    autoFocus: true,
    required: true,
    invalidText: 'A correctly formatted email is required',
  }];

  const completeInputs = [{
    type: 'text',
    name: 'code',
    label: 'Verification Code',
    autoFocus: true,
    required: true,
    invalidText: 'A verification code is required',
  }, {
    type: 'password',
    name: 'newPassword',
    label: 'New Password',
    required: true,
    invalidText: 'A new password is required',
  }];

  async function handleForgotPassword({ email }) {
    try {
      const normalized = email.trim().toLowerCase();
      await Auth.forgotPassword(normalized);

      setEmail(email);
      setCompleteNewPassword(true);
    } catch (e) {
      // @todo surface error messaging
    }
  }

  async function handleCompleteNewPassword({ code, newPassword }) {
    try {
      const normalized = email.trim().toLowerCase();
      await Auth.forgotPasswordSubmit(
        normalized,
        code,
        newPassword,
      );

      await Auth.signIn(normalized, newPassword);
      history.push('/dashboard');
    } catch (e) {
      if (e.code === 'CodeMismatchException') {
        setError(e.message);
      } else if (e.code === 'InvalidPasswordException') {
        setError('Passwords must be at least 8 characters in length, contain one uppercase letter, one lowercase letter and a number.');
      } else {
        setError('There was a problem resetting your password, please contact support');
      }
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo width={250} margin={16} />
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        {!completeNewPassword && (
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleForgotPassword)}
            noValidate
          >
            <Grid container spacing={2}>
              {forgotInputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Reset
            </Button>
          </form>
        )}

        {completeNewPassword && (
          <Fragment>
            <p>Please choose a new password and provide the code we sent to your email address at:</p>
            <p><strong>{email}</strong></p>
            <form
              className={classes.form}
              onSubmit={handleSubmit(handleCompleteNewPassword)}
              noValidate
            >
              <Grid container spacing={2}>
                {completeInputs.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <ControlledInput
                        control={control}
                        errors={errors}
                        {...input}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Set New Password
              </Button>
            </form>
          </Fragment>
        )}
        <Grid container>
          <Grid item xs>
            <Link to="/signin" href="#" variant="body2" component={RouteLink}>
              Back To Sign In
            </Link>
          </Grid>
        </Grid>
        <Snackbar
          open={error !== false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default Register;
