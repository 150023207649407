import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  useHistory,
} from 'react-router-dom';

import { Auth } from 'aws-amplify';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Logo from 'components/Logo';
import { useStyles } from './styles';

export default function CustomAppBar({ routes }) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [pilotAnchor, setPilotAnchor] = useState(null);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleToggleMenu() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleCloseMenu(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  async function handleSignOut(event) {
    handleCloseMenu(event);
    try {
      await Auth.signOut();
      history.push('/signin');
    } catch (e) {
      history.push('/signin');
    }
  }

  const handleOpenPilotMenu = (event) => {
    setPilotAnchor(event.currentTarget);
  };

  const handleClosePilotMenu = () => {
    setPilotAnchor(null);
  };

  return (
    <AppBar position="absolute" color="primary">
      <Toolbar className={classes.toolbar}>
        <Link to="/dashboard" data-test-id="title" style={{ height: 38, marginRight: 32 }}>
          <Logo darkBackground={true} />
        </Link>
        {routes.filter((x) => !x.hideFromMenu && !x.pilotSettings).map((route) => (
          <Button key={route.path} className={classes.title} color="inherit" component={Link} to={route.path} data-test-id={route.title}>
            <Typography component="p" color="inherit" noWrap>
              {route.title}
            </Typography>
          </Button>
        ))}
        {routes.filter(({ pilotSettings }) => pilotSettings).length > 0 && (
          <>
            <Button
              aria-controls="pilot-settings"
              className={classes.title}
              onClick={handleOpenPilotMenu}
              color="inherit"
            >
              <Typography component="p" color="inherit" noWrap>
                Pilot Settings
              </Typography>
            </Button>
            <Menu
              id="pilot-settings"
              anchorEl={pilotAnchor}
              keepMounted
              open={pilotAnchor ? true : false}
              onClose={handleClosePilotMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              {routes.filter((x) => !x.hideFromMenu && x.pilotSettings).map((route) => (
                <MenuItem key={route.path} onClick={handleClosePilotMenu}>
                  <Link to={route.path} data-test-id={route.title} className={classes.menuHyperlink}>
                    {route.title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        <div className={classes.flexbox} />
        <IconButton
          ref={anchorRef}
          color="inherit"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={handleToggleMenu}
        >
          <PersonIcon />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <MenuList id="user-menu" autoFocusItem={open}>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Toolbar>
    </AppBar >
  );
}

CustomAppBar.propTypes = {
  routes: PropTypes.array.isRequired,
};
