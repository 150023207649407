import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';

import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { getVehicleByMroId } from 'graphql/queries';
import {
  deleteMileageReportingOption,
  updateVehicle,
} from 'graphql/mutations';

import {
  asyncGet,
  asyncRetryMutation,
} from 'utilities/graph';

const RemoveMRODeviceDialog = ({
  isOpen,
  onClose,
  mroDevice,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveMRODevice = async () => {
    setIsLoading(true);
    try {
      const { username, id } = mroDevice;
      const {
        data: {
          getVehicleByMroId: {
            items: vehicles,
          },
        },
      } = await asyncGet(getVehicleByMroId, {
        mroId: id,
      });

      const vehicle = vehicles[0];

      await Promise.all([
        await asyncRetryMutation(deleteMileageReportingOption, {
          input: {
            id,
            username,
          },
        }),
        await asyncRetryMutation(updateVehicle, {
          input: {
            username,
            id: vehicle.id,
            mroId: 'N/A',
            updatedBy: localStorage.getItem('ruc:username'),
          },
        }),
      ]);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
      onClose(true);
    }
  };

  if (!mroDevice) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="remove-mro-device-dialog"
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
      >
        <DialogTitle>Remove MRO Device</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unassociate the following device?<br /><br />
            Serial Number: <strong>{mroDevice.deviceSerialNumber}</strong><br />
            Participant: <strong>{mroDevice.participant.lastName}, {mroDevice.participant.firstName}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-test-id="remove-mro-device-cancel"
            color="inherit"
            variant="contained"
            onClick={() => {
              onClose();
            }}
          >Cancel</Button>
          <Button
            disabled={isLoading}
            data-test-id="remove-mro-device-confirm"
            color="primary"
            variant="contained"
            onClick={handleRemoveMRODevice}
          >Confirm Remove</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider >
  );
};

RemoveMRODeviceDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  mroDevice: PropTypes.object,
};

export default RemoveMRODeviceDialog;
