import React, { Component } from 'react';
import { withLeaflet } from 'react-leaflet';
import MeasureControlDefault from 'react-leaflet-measure';

const MeasureControlRenderer = withLeaflet(MeasureControlDefault);

export default class MeasureControl extends Component {
  render() {
    const options = {
      position: 'topleft',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'acres',
      activeColor: '#db4a29',
      completedColor: '#9b2d14',
    };

    return (<MeasureControlRenderer {...options} />);
  }
}
