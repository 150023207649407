import USPS from 'usps-webtools';

// usps
const usps = new USPS({
  server: 'https://secure.shippingapis.com/ShippingAPI.dll',
  userId: '958TRANS0101',
  ttl: 5000,
});

export const getCityStateByZip = async (zip) => {
  return new Promise((resolve, reject) => {
    usps.cityStateLookup(zip, (err, result) => {
      return (err) ? reject(err) : resolve(result);
    });
  });
};

export const validateAddress = async ({
  address1: street1,
  address2: street2,
  city,
  state,
  postalCode: zip,
}) => {
  return new Promise((resolve, reject) => {
    usps.verify({
      street1,
      street2,
      city,
      state,
      zip,
    }, (err, result) => {
      return (err) ? reject(err) : resolve(result);
    });
  });
};
