import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  paperNote: {
    padding: '6px 16px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  scrollableTimeline: {
    height: '40vh',
    overflow: 'auto',
  },
}));
