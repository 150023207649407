import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CordonVisualization from 'pages/Admin/components/CordonVisualization';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Cordon({ computedMatch }) {
  const classes = useStyles();

  const [id, setId] = useState();

  useEffect(() => {
    if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
    }
  }, [computedMatch]);

  if (!id) return null;

  return (
    <div className={classes.root}>
      <CordonVisualization
        id={id}
        height={'calc(100vh - 200px)'}
      />
    </div>
  );
}

Cordon.propTypes = {
  computedMatch: PropTypes.object,
};
