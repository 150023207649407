import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import LinkButton from 'components/Table/LinkButton';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { listPilotPrograms } from 'graphql/queries';
import { updateParticipant, createParticipantConnection } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { formatAddress } from 'utilities/format';

import {
  participantStatus,
  participantContactType,
  mroDevicePreferences,
} from 'utilities/constants';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Participant from 'pages/Admin/Participant';
import CloseAccountDialog from 'pages/Admin/components/CloseAccountDialog';
import { sortBy } from 'utilities/sorting';

const LIST_PARTICIPANTS_CACHE_KEY = 'ParticipantsTable-ListParticiapnts';
const useStyles = makeStyles((theme) => ({
  spinner: {
    marginTop: theme.spacing(20),
  },
}));

function ParticipantsTable({ data: inData, title = 'Participants', description = '' }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [pilotPrograms, setPilotPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // close account
  const [closeAccountDialog, setCloseAccountDialog] = useState(false);
  const [accountToClose, setAccountToClose] = useState(null);

  const columns = [
    {
      name: 'status',
      label: 'Status',
      edit: {
        type: 'select',
        menu: participantStatus.map((item) => {
          return { label: item.toUpperCase(), value: item };
        }),
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return value ? value.toUpperCase() : '';
        },
        customFilterListOptions: {
          render: (x) => `Status:${x}`,
        },
      },
    },
    {
      name: 'closedDate',
      label: 'Closed Date',
      type: 'date',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'closedReason',
      label: 'Closed Reason',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'participantPilotProgramId',
      label: 'Program',
      edit: {
        type: 'select',
        menu: pilotPrograms.map(({ id, shortName }) => {
          return { label: shortName, value: id };
        }),
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          const { shortName } = pilotPrograms.find(({ id }) => id === value) || {};
          return shortName;
        },
        customFilterListOptions: {
          render: (x) => `Program:${x}`,
        },
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'middleName',
      label: 'Middle Name',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'preferredContactType',
      label: 'Contact Type',
      edit: {
        type: 'select',
        menu: participantContactType,
      },
      options: {
        display: false,
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (x) => `Contact Type:${x}`,
        },
        filterOptions: {
          names: ['email', 'phone'],
        },
      },
    },
    {
      name: 'mroDevicePreference',
      label: 'Reporting Method',
      edit: {
        type: 'select',
        menu: mroDevicePreferences,
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(item) {
          const preference = mroDevicePreferences.find(({ value }) => value === item) || {};
          const { label = 'N/A' } = preference;
          return label;
        },
        customFilterListOptions: {
          render: (x) => `Reporting Method:${x}`,
        },
      },
    },
    {
      name: 'mroManualCapture',
      label: 'Manual',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (x) => `Manual Capture:${x ? 'Yes' : 'No'}`,
        },
        filterOptions: {
          renderValue: (x) => x ? 'Yes' : 'No',
        },
      },
    },
    {
      name: 'isVIP',
      label: 'VIP',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (x) => `VIP:${x ? 'Yes' : 'No'}`,
        },
        filterOptions: {
          renderValue: (x) => x ? 'Yes' : 'No',
        },
      },
    },
    {
      name: 'governmentAffiliation',
      label: 'Govt Affiliation',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}`}
              label="View participant details"
            />
          );
        },
      },
    },
  ];

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { username } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Participant username={username} />
        </NestedTableContainer>
      );
    },
  };

  const onUpate = async (item, dataIndex) => {
    console.log('onUpdate', item, dataIndex);
    console.log(data, data[dataIndex]);

    const input = {
      username: item.username,
      updatedBy: localStorage.getItem('ruc:username'),
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });

    if (item.status === 'closed') {
      setCloseAccountDialog(true);
      setAccountToClose({
        dataIndex,
        account: input,
      });
      return;
    }

    // participant updates
    const promises = [asyncRetryMutation(
      updateParticipant, { input }, { clearCacheKeys: [LIST_PARTICIPANTS_CACHE_KEY] },
    )];

    if (
      data[dataIndex].mroDevicePreference !== 'telematics' &&
      item.mroDevicePreference === 'telematics'
    ) {
      promises.push(asyncRetryMutation(createParticipantConnection, {
        input: {
          resourceProvider: 'smartcar',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          username: item.username,
          forceRestoreConnection: true,
        },
      }));
    }

    await Promise.all(promises);

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  const handleOnClose = (response) => {
    if (response) {
      const { dataIndex, account } = response;
      Object.assign(data[dataIndex], account);
      setData([...data]);
    }
    setCloseAccountDialog(false);
    setAccountToClose(null);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const [participantRecords, pilotProgramRecords] = await Promise.all([
          inData ? inData : asyncListAll( /* GraphQL */ `
            query ListParticipants(
              $username: String
              $filter: ModelParticipantFilterInput
              $limit: Int
              $nextToken: String
              $sortDirection: ModelSortDirection
            ) {
              listParticipants(
                username: $username
                filter: $filter
                limit: $limit
                nextToken: $nextToken
                sortDirection: $sortDirection
              ) {
                items {
                  username
                  email
                  firstName
                  middleName
                  lastName
                  phoneNumber
                  preferredContactType
                  status
                  mroDevicePreference
                  mroManualCapture
                  roadChargeCreditCents
                  sumFuelTaxCreditCents
                  sumMileageUserFeeCents
                  participantPilotProgramId
                  pilotProgram {
                    id
                    name
                  }
                  isVIP
                  governmentAffiliation
                  address {
                    address1
                    address2
                    city
                    state
                    postalCode
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }`, undefined, {
            bypassCache: true,
            cacheKey: LIST_PARTICIPANTS_CACHE_KEY,
          }), asyncListAll(listPilotPrograms)]);

        setData(participantRecords
          .sort(sortBy('firstName'))
          .sort(sortBy('lastName'))
          .sort(sortBy('status')));

        setPilotPrograms(pilotProgramRecords.sort(sortBy('name')).map((item) => {
          item.displayName = `${item.name} (${item.shortName})`;
          return item;
        }));

        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [inData]);

  if (isLoading) {
    return (
      <Grid container className={classes.spinner} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <div data-test-id="participants-table">
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        onUpdateItem={onUpate}
      />
      <CloseAccountDialog
        isOpen={closeAccountDialog}
        accountToClose={accountToClose}
        onClose={handleOnClose}
      />
    </div>
  );
}

ParticipantsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};

export default ParticipantsTable;
