import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll, asyncGet } from 'utilities/graph';
import {
  getInquiriesByStatusByCreatedAt,
  getParticipant,
  listVehicles,
} from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },

}));

export default function InquiryReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      let allInquiriesByDate = await Promise.all([
        asyncListAll(getInquiriesByStatusByCreatedAt, { createdAt: { between: [from, to] }, status: 'new' }),
        asyncListAll(getInquiriesByStatusByCreatedAt, { createdAt: { between: [from, to] }, status: 'inProgress' }),
        asyncListAll(getInquiriesByStatusByCreatedAt, { createdAt: { between: [from, to] }, status: 'awaitingCustomerResponse' }),
        asyncListAll(getInquiriesByStatusByCreatedAt, { createdAt: { between: [from, to] }, status: 'responseReceived' }),
        asyncListAll(getInquiriesByStatusByCreatedAt, { createdAt: { between: [from, to] }, status: 'awaitingInternal' }),
        asyncListAll(getInquiriesByStatusByCreatedAt, { createdAt: { between: [from, to] }, status: 'closed' }),
      ]);

      allInquiriesByDate = allInquiriesByDate.flat().filter(({ username = null }) => {
        return username && username !== 'N/A';
      });

      for (let i = 0; i < allInquiriesByDate.length; i += 1) {
        const { username } = allInquiriesByDate[i];
        const [
          { data: { getParticipant: participant } },
          vehicles,
        ] = await Promise.all([
          asyncGet(getParticipant, { username }),
          asyncListAll(listVehicles, { username }),
        ]);

        Object.entries(participant).forEach(([key, value]) => {
          allInquiriesByDate[i][`participant-${key}`] = value;
        });

        Object.entries(vehicles[0]).forEach(([key, value]) => {
          allInquiriesByDate[i][`vehicle-${key}`] = value;
        });
      }

      return allInquiriesByDate;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Participant Inquiry Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Export_Inquiries.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Trips Data
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
