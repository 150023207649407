import deepcopy from 'deepcopy';
import polyline from '@mapbox/polyline';
import { lineString } from '@turf/helpers';
import length from '@turf/length';

export const switchCoordinatesLatLng = (coordinates = []) => {
  let newCoordinates = deepcopy(coordinates);

  const switchLatLng = (item) => {
    const a = item[0];
    const b = item[1];
    item[0] = b;
    item[1] = a;
    return item;
  };

  if (newCoordinates.length === 0) return [];

  if (!Array.isArray(newCoordinates[0])) {
    newCoordinates = switchLatLng(newCoordinates);
  } else {
    newCoordinates = newCoordinates.map((item) => {
      return switchCoordinatesLatLng(item);
    });
  }

  return newCoordinates;
};

export const calculatePolylineDistance = (encodedPolyline) => {
  const mileageUnit = localStorage.getItem('ruc:configuration:MILEAGE_UNIT');
  const coordinates = switchCoordinatesLatLng(polyline.decode(encodedPolyline));
  const tripLine = lineString(coordinates);
  const tripLength = +(length(tripLine, { units: mileageUnit === 'mi' ? 'miles' : 'kilometers' }).toFixed(2));
  return tripLength;
};
