/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default function DeviceExplanation({ preference }) {
  return (
    <Box marginTop={4}>
      <Box marginBottom={2}>
        <Typography component="p" variant="body1" gutterBottom>
          For this MBUF Study you can choose between three different reporting options:
        </Typography>
        <ol>
          <li><Typography component="p" variant="body1" gutterBottom>A device with a GPS that plugs into your car</Typography></li>
          <li><Typography component="p" variant="body1" gutterBottom>A device without a GPS that plugs into your car</Typography></li>
          <li><Typography component="p" variant="body1" gutterBottom>Embedded telematics</Typography></li>
        </ol>
      </Box>
      {
        preference === 'telematics' && (
          <>
            <Typography component="p" variant="body1" gutterBottom>
              To participate using telematics, you must connect your vehicle to the MBUF Study.  When you click &ldquo;Continue&ldquo; below you will be prompted to sign in with your connected services account and asked to give Odometer and Location permissions to the MBUF Study.
            </Typography>
          </>
        )
      }
    </Box>
  );
}

DeviceExplanation.propTypes = {
  preference: PropTypes.string,
};
