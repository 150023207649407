import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1rem',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inline: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  condensedForm: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  condensedSubmit: {
    margin: 0,
  },
  secondaryAction: {
    margin: theme.spacing(0, 0, 2),
  },
  spinner: {
    marginTop: theme.spacing(20),
  },
  driveTU: {
    textAlign: 'left',
  },
  notRequired: {
    color: theme.palette.primary.main,
  },
}));
