// Ensure this matches <root>/amplify/backend/api/rucaccountmanagement/schema/ParticipantInquiry.graphql
export const inquiryStatuses = {
  'new': 'New',
  'inProgress': 'In Progress',
  'awaitingCustomerResponse': 'Awaiting Customer Response',
  'responseReceived': 'Response Received',
  'awaitingInternal': 'Awaiting Internal',
  'closed': 'Closed',
};

export const isInquiryClosed = (value) => ['close', 'closed', 'done', 'completed'].includes(value.toLowerCase());
