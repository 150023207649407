export const customerSupportTopics = [
  'Policy Question',
  'Tech Support',
  'Account Question',
  'Incentives',
  'Mileage Reporting',
  'Other',

  // 'Policy Question',
  // 'Account Creation',
  // 'Mileage Reporting',
  // 'Statements and Billing',
  // 'MBUF Technology',
  // 'Technical Support',
  // 'Account Closure',
  // 'Not Pilot Related',
  // 'Other',
];
