import React from 'react';

import VerticalTabs from 'components/Tab/VerticalTabs';
import PilotProgramsTable from 'pages/Admin/components/PilotProgramsTable';
import MRORatesTable from 'pages/Admin/components/MRORatesTable';
import CordonsTable from 'pages/Admin/components/CordonsTable';
import TodTable from 'pages/Admin/components/TodTable';
import TollTable from 'pages/Admin/components/TollTable';
import EpaVehicles from './components/EpaVehicles';

export default function Rates() {
  const tabs = [
    {
      label: 'Programs',
      component: <PilotProgramsTable />,
    },
    {
      label: 'States',
      component: <MRORatesTable />,
    },
    {
      label: 'Cordons',
      component: <CordonsTable />,
    },
    {
      label: 'Time of Day',
      component: <TodTable />,
    },
    {
      label: 'Tolls',
      component: <TollTable />,
    },
    {
      label: 'EPA Vehicles',
      component: <EpaVehicles />,
    },
  ];

  return (
    <VerticalTabs
      tabs={tabs}
    />
  );
}
