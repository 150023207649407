import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export default function MultiSelect({ label, optionsLabelKey, options = [], value = [], ...props }) {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        input={<Input id="select-multiple-chip" />}
        value={value}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((item, index) => (
              <Chip key={index} label={item[optionsLabelKey]} className={classes.chip} />
            ))}
          </div>
        )}
        {...props}
      >
        {
          options.map((item, index) => (
            <MenuItem key={index} value={item}>
              <Checkbox checked={value.find((x) => x[optionsLabelKey] === item[optionsLabelKey] ) ? true : false} />
              <ListItemText primary={item.displayName} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>);
}

MultiSelect.propTypes = {
  label: PropTypes.string,
  optionsLabelKey: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
};
