import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TripReport from './TripReport';
import VinReport from './VinReport';
import InquiryReport from './InquiryReport';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Report() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TripReport />
      <VinReport />
      <InquiryReport />
    </div>
  );
}
