import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  selectedRadioLabel: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

const Mask = ({
  mask,
  children,
  name,
  defaultValue,
  setValue,
}) => {
  return (
    <InputMask
      mask={mask}
      defaultValue={defaultValue}
      onChange={(e) => {
        setValue(name, e.target.value);
      }}
    >
      {() => {
        return (children);
      }}
    </InputMask>
  );
};

Mask.propTypes = {
  mask: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

const ControlledInput = ({
  control,
  errors,
  type,
  name,
  label,
  autoFocus = false,
  required = false,
  disabled = false,
  multiline = false,
  rows = null,
  mask = null,
  pattern = null,
  validate = null,
  minLength = null,
  maxLength = null,
  options = null,
  placeholder = null,
  invalidText,
  setValue = null,
  register = null,
  shrinkLabel = null,
  size = null,
  accept = 'image/*',
  defaultValue = '',
  defaultChecked = null,
  inputProps = {},
  InputProps = {},
  renderOptions = {},
}) => {
  const classes = useStyles();
  if (
    type === 'text' ||
    type === 'password' ||
    type === 'number' ||
    type === 'date' ||
    type === 'email'
  ) {
    const additionalOptions = {};
    if (shrinkLabel) {
      additionalOptions['InputLabelProps'] = {
        shrink: true,
      };
    }

    if ((type === 'text' || type === 'email') && setValue) {
      inputProps.onBlur = async (e) => {
        setValue(name, e.target.value.trim());
      };
    }

    let field = (
      <TextField
        id={name}
        name={name}
        label={label}
        type={type}
        required={required}
        multiline={multiline}
        rows={rows}
        size={size}
        placeholder={placeholder}
        fullWidth
        autoFocus={autoFocus}
        autoComplete={name}
        variant="outlined"
        error={errors[name]}
        helperText={errors[name] ? invalidText : null}
        disabled={(!mask) ? disabled : null}
        inputProps={inputProps}
        InputProps={InputProps}
        {...additionalOptions}
      />
    );

    if (mask) {
      field = (
        <Mask
          mask={mask}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          setValue={setValue}
        >
          {field}
        </Mask>
      );
    }

    return (
      <Controller
        name={name}
        control={control}
        rules={buildRules()}
        defaultValue={defaultValue}
        as={field}
      />
    );
  }

  if (type === 'hidden') {
    return (
      <Input type="hidden" name={name} value={defaultValue} />
    );
  }

  if (type === 'select') {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={errors[name]}
        required={required}
      >
        <InputLabel id={name}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          rules={buildRules()}
          defaultValue={defaultValue}
          as={
            <Select
              labelId={name}
              label={label}
              fullWidth
              value={'select'}
              disabled={disabled}
            >
              {options.map((option, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={option.value}
                    data-test-value={option.testValue || undefined}
                  >{option.label}</MenuItem>
                );
              })}
            </Select>
          }
        />
        {errors[name] && invalidText && (
          <FormHelperText id={`${name}-helper-text`}>
            {invalidText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControl
        className={classes.formControl}
        error={errors[name]}
        required={required}
      >
        <Controller
          name={name}
          control={control}
          rules={buildRules()}
          defaultValue={defaultValue}
          render={({ value, onChange }) => {
            if (value === '' && setValue) {
              setValue(name, defaultChecked);
            }
            return (
              /* eslint-disable */
              <FormControlLabel
                label={label}
                value={value}
                control={<Checkbox
                  id={name}
                  inputProps={inputProps}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  disabled={disabled}
                />}
              />
            )
          }}
        />
        {errors[name] && invalidText && (
          <FormHelperText id={`${name}-helper-text`}>
            {invalidText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  if (type === 'radio') {
    const {
      row = false,
      labelPlacement = 'end',
    } = renderOptions;
    return (
      <FormControl
        className={classes.formControl}
        component="fieldset"
        error={errors[name]}
        required={required}
      >
        <FormLabel
          data-label={name}
          className={classes.formLabel}
          component="legend"
        >
          {label}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={buildRules()}
          defaultValue={defaultValue}
          render={({ value, onChange }) => {
            return (
              <RadioGroup
                id={name}
                required={required}
                name={name}
                defaultValue={defaultValue}
                row={row}
                fullWidth={row}
              >
                {options.map((option, index) => {
                  const { disabled, value: optionValue } = option;
                  return (
                    <FormControlLabel
                      className={(value === optionValue) ? classes.selectedRadioLabel : classes.formLabel}
                      key={index}
                      value={option.value}
                      label={option.label}
                      labelPlacement={labelPlacement}
                      onChange={(e) => onChange(e.target.value)}
                      control={<Radio
                        onChange={inputProps.onChange}
                        disabled={disabled}
                        icon={(option.icon) ? option.icon : null}
                        checkedIcon={(option.checkedIcon) ? option.checkedIcon : null}
                        inputProps={{
                          'data-value': option.value,
                        }}
                      />}
                    />
                  );
                })}
              </RadioGroup>
            );
          }}
        />
      </FormControl>
    );
  }

  if (type === 'file') {
    return (
      <FormControl
        className={classes.formControl}
        component="fieldset"
        error={errors[name]}
        required={required}
      >
        <FormLabel
          data-label={name}
          className={classes.formLabel}
          component="legend"
        >
          {label}
        </FormLabel>
        <input
          type="file"
          accept={accept}
          name={name}
          ref={register(buildRules())}
          onChange={inputProps.onChange}
        />
      </FormControl>
    );
  }

  function buildRules() {
    const rules = {};
    if (required) {
      rules.required = true;
    }

    if (pattern) {
      rules.pattern = pattern;
    }

    if (validate) {
      rules.validate = validate;
    }

    if (minLength) {
      rules.minLength = minLength;
    }

    if (maxLength) {
      rules.maxLength = maxLength;
    }

    return rules;
  }

  return (<div />);
};

ControlledInput.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  mask: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  validate: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  invalidText: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  shrinkLabel: PropTypes.bool,
  size: PropTypes.string,
  accept: PropTypes.string,
  defaultValue: PropTypes.any,
  defaultChecked: PropTypes.bool,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  renderOptions: PropTypes.object,
};

export default ControlledInput;
