import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import {
  Map,
  LayersControl,
  TileLayer,
  // Polygon,
  FeatureGroup,
} from 'react-leaflet';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import { GoogleLayer } from 'react-leaflet-google-v2';
import { EditControl } from 'react-leaflet-draw';
import p from '@mapbox/polyline';
import { updateCordon } from 'graphql/mutations';
import { getCordon } from 'graphql/queries';
import { asyncGet, asyncRetryMutation } from 'utilities/graph';
import CordonVisualizationList, { CORDON_COLORS } from './CordonVisualizationList';

const { BaseLayer } = LayersControl;

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    flex: 1,
  },
}));

let currentCordon;

export default function CordonVisualization({ id, height = 600 }) {
  const classes = useStyles();
  const [zones, setZones] = useState([]);
  const [cordon, setCordon] = useState({});
  const [mapElement, setMapElement] = useState();

  const getGeoJson = () => {
    const features = zones.map(({ name, description, color, polyline }, index) => {
      const coordinates = (p.decode(polyline));

      return {
        type: 'Feature',
        properties: {
          name,
          description,
          color: color || CORDON_COLORS[index],
        },
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      };
    });

    const geojson = {
      type: 'FeatureCollection',
      features,
    };

    return new L.GeoJSON(geojson, {
      style: (feature) => {
        return feature.properties;
      },
    });
  };

  const getEncodedPolylineFromLayer = (layer) => {
    const { coordinates } = layer.toGeoJSON().geometry;
    const encodedPolylineString = p.encode(coordinates[0]);
    return encodedPolylineString;
  };

  const getZoneDescription = (layer) =>{
    const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
    const readableArea = L.GeometryUtil.readableArea(area, false);
    return readableArea;
  };

  const onFeatureGroupReady = (reactFGref) => {
    if (!reactFGref || !reactFGref.leafletElement) return;

    const leafletGeoJSON = getGeoJson();
    leafletGeoJSON.eachLayer((layer) => {
      reactFGref.leafletElement.addLayer(layer);
    });

    const bounds = reactFGref.leafletElement.getBounds();

    if (mapElement && bounds._northEast) {
      mapElement.fitBounds(bounds, { padding: [50, 50] });
    }
  };

  const onEdited = async (e) => {
    e.layers.eachLayer((layer) => {
      const { name } = layer.options;
      const encodedPolylineString = getEncodedPolylineFromLayer(layer);

      const matchedZone = currentCordon.zones.find((zone) => zone.name === name);
      matchedZone.polyline = encodedPolylineString;
      matchedZone.description = getZoneDescription(layer);
    });

    const input = {
      id,
      zones: [...currentCordon.zones],
    };

    await asyncRetryMutation(updateCordon, { input });

    currentCordon.zones = input.zones;
    setZones(input.zones);
  };

  const onCreated = async (e) => {
    const encodedPolylineString = getEncodedPolylineFromLayer(e.layer);
    const name = uuidv4();
    e.layer.options.name = name;

    const input = {
      id,
      zones: [...currentCordon.zones, {
        name,
        polyline: encodedPolylineString,
        description: getZoneDescription(e.layer),
      }],
    };
    await asyncRetryMutation(updateCordon, { input });

    currentCordon.zones = input.zones;
    setZones(input.zones);
  };

  const onDeleted = async (e) => {
    e.layers.eachLayer((layer) => {
      const { name } = layer.options;

      const index = currentCordon.zones.findIndex((zone) => zone.name === name);
      currentCordon.zones.splice(index, 1);
    });

    const input = {
      id,
      zones: [...currentCordon.zones],
    };
    await asyncRetryMutation(updateCordon, { input });

    currentCordon.zones = input.zones;
    setZones(input.zones);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const { data: { getCordon: cordon } } = await asyncGet(getCordon, { id });
        setCordon(cordon);
        setZones(cordon.zones);

        currentCordon = cordon;
      })();
    }
  }, [id]);

  if (!cordon) return null;

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        {cordon.name}
      </Grid>
      <Grid item xs={12} md={10} style={{ height }}>
        <div className={classes.mapContainer} style={{ height }}>
          <Map
            zoom={10}
            ref={(ref) => {
              if (!ref || !ref.leafletElement) return;
              setMapElement(ref.leafletElement);
            }}
          >
            <LayersControl position='topright'>
              <BaseLayer name="Open Street Map" checked={true}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution=""
                />
              </BaseLayer>
              {
                ['ROARDMAP', 'TERRAIN', 'SATELLITE', 'HYBRID'].map((type, index)=>(
                  <BaseLayer key={index} name={`Google Maps - ${type}`}>
                    <GoogleLayer googlekey={undefined} maptype={type}/>
                  </BaseLayer>
                ))
              }
            </LayersControl>
            <FeatureGroup
              ref={(ref) => onFeatureGroupReady(ref)}
            >
              <EditControl
                position='topright'
                onEdited={onEdited}
                onCreated={onCreated}
                onDeleted={onDeleted}
                draw={{
                  polyline: false,
                  polygon: true,
                  rectangle: false,
                  circle: false,
                  marker: false,
                  circlemarker: false,
                }}
              />
            </FeatureGroup>
          </Map>
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <CordonVisualizationList zones={zones} />
      </Grid>
    </Grid>
  );
}

CordonVisualization.propTypes = {
  id: PropTypes.string,
  height: PropTypes.number,
};
