import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import TripSegmentsTable from 'pages/Admin/components/TripSegmentsTable';
import TripAdjustmentsTable from 'pages/Admin/components/TripAdjustmentsTable';
import AggregateTripSegmentsTable from 'pages/Admin/components/AggregateTripSegmentsTable';

import { asyncListAll } from 'utilities/graph';
import {
  getTripSegmentsByTransaction,
  getTripAdjustmentsByTransactionByPaymentStatus,
} from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function Transaction({ id: inId, computedMatch, viewer = 'admin' }) {
  const classes = useStyles();

  const [id, setId] = useState();
  const [isNested, setIsNested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
      setIsNested(true);
    } else if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
      setIsNested(false);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        try {
          const [
            segments,
            adjustments,
          ] = await Promise.all([
            asyncListAll(getTripSegmentsByTransaction, { transactionId: id }),
            asyncListAll(getTripAdjustmentsByTransactionByPaymentStatus, { transactionId: id }),
          ]);

          const tabs = [];
          if (viewer === 'admin') {
            if (segments.length > 0) {
              tabs.push({
                label: 'Trip Segments',
                component: <TripSegmentsTable data={segments} />,
              });
            }

            if (adjustments.length > 0) {
              tabs.push({
                label: 'Trip Adjustments',
                component: <TripAdjustmentsTable data={adjustments} />,
              });
            }
          }

          if (viewer === 'participant') {
            if (segments.length > 0) {
              tabs.push({
                label: 'Trips',
                component: <AggregateTripSegmentsTable data={segments} />,
              });
            }
          }

          setTabs(tabs);
        } catch (e) {
          console.warn(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id, classes, isNested, viewer]);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={isNested}
    />
  );
}

Transaction.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  viewer: PropTypes.string,
};
