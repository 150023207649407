import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TripsTable from 'pages/Admin/components/TripsTable';

import { asyncGet } from 'utilities/graph';

import {
  getTrip,
} from 'graphql/queries';

function AggregateTripSegmentsTable({ title = 'Trips', description = '', data = [] }) {
  const [trips, setTrips] = useState([]);

  const aggregatedTrips = data.reduce((acc, next) => {
    if (!acc || !Array.isArray(acc)) {
      acc = [];
    }
    if (acc.indexOf(next.tripId) === -1) {
      acc.push(next.tripId);
    }
    return acc;
  });

  useEffect(() => {
    (async () => {
      if (aggregatedTrips.length > 0) {
        const trips = [];
        for (let i = 0; i < aggregatedTrips.length; i += 1) {
          const { data: { getTrip: trip } } = await asyncGet(getTrip, {
            id: aggregatedTrips[i],
          });
          trips.push(trip);
        }

        setTrips(trips);
      }
    })();
  }, [aggregatedTrips, data]);

  return (
    <TripsTable
      title="Trips"
      data={trips}
      viewer="participant"
      nested={true}
    />
  );
}

AggregateTripSegmentsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
};

export default AggregateTripSegmentsTable;
