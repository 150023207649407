import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import LinkButton from 'components/Table/LinkButton';
import { listPilotPrograms } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import { tripPaymentStatus } from 'utilities/constants';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Trip from 'pages/Admin/Trip';
import TripReviewButton from './TripReviewButton';

// RUC-435
const MINIMUM_MILEAGE_VIEW_THRESHOLD = 0.06;

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginTop: theme.spacing(20),
  },
}));

function TripsTable({
  data: inData,
  title = 'Trips',
  description = '',
  viewer = 'admin',
  nested = false,
  pilotProgram = '',
}) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pilotPrograms, setPilotPrograms] = useState([]);

  const columns = [
    {
      name: 'pilotProgramId',
      label: 'Program',
      options: {
        display: viewer === 'admin',
        filter: true,
        sort: true,
        customBodyRender(value) {
          const { shortName } = pilotPrograms.find(({ id }) => id === value) || {};
          return shortName;
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'recordId',
      label: 'Record ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'recordTripId',
      label: 'Record Trip ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'mroId',
      label: 'MRO ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'mroDeviceSerialNumber',
      label: 'MRO Device #',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'vehicleVin',
      label: 'VIN',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'vehicleId',
      label: 'Vehicle ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'processStatus',
      label: 'Status',
      options: {
        display: viewer === 'admin',
        filter: true,
        sort: true,
      },
    },
    {
      name: 'processMsg',
      label: 'Status Msg',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tsStart',
      label: 'Start',
      type: 'datetime',
      options: {
        display: viewer === 'participant',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tsEnd',
      label: 'End',
      type: 'datetime',
      options: {
        display: viewer === 'participant',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distMro',
      label: 'MRO: Distance',
      type: 'mileage',
      options: {
        display: viewer === 'participant',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'odoMro',
      label: 'MRO: Odometer',
      type: 'mileage',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distGps',
      label: 'GPS: Distance',
      type: 'mileage',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'distRoute',
      label: 'Route: Distance',
      type: 'mileage',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fuel',
      label: 'Fuel',
      type: 'fuel',
      options: {
        display: viewer === 'participant',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mileageFeeCents',
      label: 'Mileage Fee',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'cordonFeeCents',
      label: 'Cordon Fee',
      type: 'currency',
      options: {
        display: viewer === 'participant' && pilotProgram === 'MBUF+C',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'todFeeCents',
      label: 'TOD Fee',
      type: 'currency',
      options: {
        display: viewer === 'participant' && pilotProgram === 'MBUF+ToD',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tollFeeCents',
      label: 'Toll Fee',
      type: 'currency',
      options: {
        display: viewer === 'participant' && pilotProgram === 'MBUF+TF',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fuelFeeCents',
      label: 'Fuel Tax Credits',
      type: 'currency-negative',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjustments.items.length',
      label: 'ADJ',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'paymentStatus',
      label: 'Payment Status',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const trip = data ? data[dataIndex] : null;
          if (trip && trip.tripSegments) {
            const { tripSegments: { items: segments = [] } } = trip;
            return (segments.length > 0) ? tripPaymentStatus(segments) : 'pending';
          }
        },
      },
    },
    {
      name: 'reviewStatus',
      label: 'Audit',
      options: {
        display: viewer === 'admin',
        filter: true,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const trip = data ? data[dataIndex] : null;
          return (
            <TripReviewButton
              trip={trip}
              onUpdate={(updatedTrip) => {
                data[dataIndex] = updatedTrip;
                setData([...data]);
              }}
            />
          );
        },
      },
    },
    {
      name: 'username',
      label: 'Participant',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Trips`}
              label="View participant details"
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Details',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/trip/${id}`}
              label="View trip details"
            />
          );
        },
      },
    },
  ].filter((x) => {
    return viewer === 'participant' && x.options ? x.options.display !== false : true;
  });

  const options = {
    expandableRows: true,
    isRowExpandable: () => nested ? false : true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Trip id={id} viewer={viewer} pilotProgram={pilotProgram} />
        </NestedTableContainer>
      );
    },
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        if (pilotPrograms.length === 0) {
          setPilotPrograms(await asyncListAll(listPilotPrograms));
        }

        const trips = inData ? inData : await asyncListAll( /* GraphQL */ `
        query ListTrips(
          $filter: ModelTripFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              status
              recordId
              recordTripId
              mroDeviceSerialNumber
              vehicleVin
              tsStart
              tsEnd
              polyline
              distMro
              odoMro
              fuel
              distGps
              odoGps
              odoMroStart
              odoMroEnd
              fuelStart
              fuelEnd
              fuelAdded
              totalFuel
              username
              vehicleId
              pilotProgramId
              mroId
              processStatus
              processMsg
              logs
              mileageFeeCents
              fuelFeeCents
              cordonFeeCents
              todFeeCents
              tollFeeCents
              createdAt
              updatedAt
              adjustments {
                items {
                  tripId
                  id
                }
              }
              reviewStatus
            }
            nextToken
          }
        }
      `, undefined, {
          bypassCache: true,
        });

        setData(trips
          .filter(({ distMro, processStatus }) => {
            return (viewer === 'participant' ? parseFloat(distMro) >= MINIMUM_MILEAGE_VIEW_THRESHOLD && processStatus === 'processed' : true);
          })
          .sort(sortBy('createdAt', true)),
        );

        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [inData, viewer, pilotPrograms.length]);

  if (isLoading) {
    return (
      <Grid container className={classes.spinner} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

TripsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  nested: PropTypes.bool,
  pilotProgram: PropTypes.string,
};

export default TripsTable;
