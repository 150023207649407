import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Logo from 'components/Logo';
import awsConfig from 'aws-exports';
import { version } from '../../../package.json';

const env = awsConfig.aws_cloud_logic_custom[0].endpoint.split('/').pop();

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
  },
}));

export default function Footer() {
  const classes = useStyles();

  const info = `v${version}` + (process.env.NODE_ENV === 'development' ? ` (${env})` : '');

  return (
    <Grid container className={classes.container} direction="row" justify="center" alignItems="center">
      <Logo avatar={true} />
      <div>
        © Copyright {moment().format('YYYY')} <Link
          href="https://www.transurban.com/"
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Transurban Limited
        </Link>
        &nbsp;
        All rights reserved.
        &nbsp;|&nbsp;
        <Link
          href="/tetc"
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Tester Agreement
        </Link>
        &nbsp;|&nbsp;
        <Link
          href="/tutc"
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Terms and Conditions
        </Link>
        &nbsp;|&nbsp;
        {info}
      </div>
    </Grid>
  );
}
