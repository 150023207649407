import React from 'react';
import PropTypes from 'prop-types';

import MRODevices from 'pages/Admin/MRODevices';

const ParticipantMros = ({ username }) => {
  return (
    <React.Fragment>
      <MRODevices
        username={username}
      />
    </React.Fragment>
  );
};

ParticipantMros.propTypes = {
  username: PropTypes.string,
};

export default ParticipantMros;
