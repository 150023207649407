import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import StatementsTable from 'pages/Admin/components/StatementsTable';

import { asyncListAll } from 'utilities/graph';
import { getParticipantStatementsByParticipantByMonth } from 'graphql/queries';

import { useStyles } from '../styles';

const ParticipantStatements = ({ username }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const records = await asyncListAll(getParticipantStatementsByParticipantByMonth, { username, sortDirection: 'DESC' }, { bypassCache: true });
        setData(records);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <StatementsTable
      data={data}
    />
  );
};

ParticipantStatements.propTypes = {
  username: PropTypes.string,
};

export default ParticipantStatements;
