/* eslint-disable max-len */
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default function BillingExplanation() {
  return (
    <Box marginTop={4}>
      <Box marginBottom={2}>
        <Typography component="p" variant="body1" gutterBottom>
          A mock billing system will be utilized for this MBUF Study.  The billing details are fake and you will not be charged in any way.
        </Typography>
      </Box>
    </Box>
  );
}
