import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const timeZones = [
  {
    label: 'Eastern Time',
    value: 'America/New_York',
  },
  {
    label: 'Central Time',
    value: 'America/Chicago',
  },
  {
    label: 'Mountain Time',
    value: 'America/Phoenix',
  },
  {
    label: 'Pacific Time',
    value: 'America/Los_Angeles',
  },
];

const timeframePresets = [{
  label: 'Today',
  key: 'today',
  offsetDays: 0,
}, {
  label: 'Last 3 days',
  key: '3days',
  offsetDays: -3,
}, {
  label: 'Last 7 days',
  key: '7days',
  offsetDays: -7,
}, {
  label: 'last 30 days',
  key: '30days',
  offsetDays: -30,
}];

export default function DateRangeSelector({
  // buttonText = 'Submit',
  preset: inPreset = 'today',
  onSubmit,
  disabled = false,
}) {
  const classes = useStyles();

  const [timeZone, setTimeZone] = useState(localStorage.getItem('ruc:timeZone') || timeZones[0].value);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [preset, setPreset] = useState();
  const [cache, setCache] = useState();

  useEffect(() => {
    if (inPreset && !preset) {
      setPreset(inPreset);
    }
  }, [inPreset, preset]);

  useEffect(() => {
    if (preset) {
      const matched = timeframePresets.find((x) => x.key === preset);
      if (matched) {
        setFrom(moment().add(matched.offsetDays, 'days').startOf('day').format('YYYY-MM-DD'));
        setTo(moment().endOf('day').format('YYYY-MM-DD'));
      }
    }
  }, [preset]);

  useEffect(() => {
    if (disabled) return;
    const data = {
      timeZone,
      from: moment(from).tz(timeZone).startOf('day').toISOString(),
      to: moment(to).tz(timeZone).endOf('day').toISOString(),
    };
    if (JSON.stringify(data) === cache) return;
    setCache(JSON.stringify(data));
    if (onSubmit) {
      onSubmit(data);
    }
  }, [disabled, onSubmit, from, to, timeZone, cache]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              disabled={disabled}
            />
          </Grid>
          <Grid container item xs={12} md={2} alignItems = "center" justify = "center" >
            <FormControl fullWidth variant="outlined">
              <InputLabel id="time-zone-label">
                Time Zone
              </InputLabel>
              <Select
                labelId="time-zone-label"
                id={'time-zone'}
                value={timeZone}
                label="Time Zone"
                disabled={disabled}
                onChange={(e) => {
                  setTimeZone(e.target.value);
                  localStorage.setItem('ruc:timeZone', e.target.value);
                }}
              >
                {timeZones.map(({ value, label }, index) => {
                  return (<MenuItem key={index} value={value}>{label}</MenuItem>);
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={4} alignItems="center" justify="left">
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              {
                timeframePresets.map((item) => (
                  <Button
                    key={item.key}
                    variant={preset === item.key ? 'contained' : 'outlined'}
                    disabled={disabled}
                    onClick={() => {
                      setPreset(item.key);
                    }}>
                    {item.label}
                  </Button>
                ))
              }
            </ButtonGroup>
            {disabled && <CircularProgress color="primary" size={25} style={{ marginLeft: 16 }} />}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

DateRangeSelector.propTypes = {
  buttonText: PropTypes.string,
  preset: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};
