export const getMroEventsByMroDeviceByDateTime = /* GraphQL */ `
  query GetMroEventsByMroDeviceByDateTime(
    $mroDeviceSerialNumber: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByMroDeviceByDateTime(
      mroDeviceSerialNumber: $mroDeviceSerialNumber
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        participant {
          username
          firstName
          lastName
          email
          status
        }
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMroEventsByUsernameByFlag = /* GraphQL */ `
  query GetMroEventsByUsernameByFlag(
    $username: String
    $flagged: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByUsernameByFlag(
      username: $username
      flagged: $flagged
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        participant {
          username
          firstName
          lastName
          email
          status
        }
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listMroEvents = /* GraphQL */ `
  query ListMroEvents(
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMROEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        participant {
          username
          firstName
          lastName
          email
          status
        }
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMroEventsByIsProcessedByDateTime = /* GraphQL */ `
  query GetMroEventsByIsProcessedByDateTime(
    $isProcessed: Int
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByIsProcessedByDateTime(
      isProcessed: $isProcessed
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        participant {
          username
          firstName
          lastName
          email
          status
        }
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
