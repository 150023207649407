import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { listVehicles } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';

import { useStyles } from './styles';

const VehicleInfo = ({ user }) => {
  const classes = useStyles();

  const [vehicle, setVehicle] = useState(null);
  const { username } = user;

  useEffect(() => {
    (async () => {
      try {
        const records = await asyncListAll(listVehicles, {
          username,
        });

        if (records && records[0]) {
          setVehicle(records[0]);
        }
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [username]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <DriveEtaOutlinedIcon color="inherit" />
        </Avatar>
        {vehicle ? (
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              My Vehicle
            </Typography>
            <form className={classes.form} onSubmit={(e) => {
              e.preventDefault();
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="make"
                    name="make"
                    variant="outlined"
                    fullWidth
                    label="Vehicle Make"
                    defaultValue={vehicle.make}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="model"
                    name="model"
                    variant="outlined"
                    fullWidth
                    label="Vehicle Model"
                    defaultValue={vehicle.model}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="year"
                    name="year"
                    variant="outlined"
                    fullWidth
                    label="Vehicle Year"
                    defaultValue={vehicle.year}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="type"
                    name="type"
                    variant="outlined"
                    fullWidth
                    label="Vehicle Type"
                    defaultValue={vehicle.type}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="registrationState"
                    name="registrationState"
                    variant="outlined"
                    fullWidth
                    label="Registration State"
                    defaultValue={vehicle.registrationState}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="licensePlate"
                    name="licensePlate"
                    variant="outlined"
                    fullWidth
                    label="License Plate No."
                    defaultValue={vehicle.licensePlate}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={true}
              >
                Update
              </Button>
            </form>
          </div>
        ) : (
          <div className={classes.notFound}>
            <Typography component="h1" variant="h5">
              Oh no!
            </Typography>
            <p>It looks like you have no registered vehicles.  <br />Please contact customer support for assistance.</p>
          </div>
        )}
      </div>
    </Container >
  );
};

VehicleInfo.propTypes = {
  user: PropTypes.object,
};

export default VehicleInfo;
