import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Logo from 'components/Logo';
import ControlledInput from 'components/Form/ControlledInput';

import { useStyles } from './components/commonStyles';

import {
  surveyVehicleStyles,
  surveyVehicleEligibilityYears,
  surveyVehicleTypes,
  surveyComfortability,
  surveyAreas,
  surveyAreaFrequencies,
  surveyTimesOfDay,
  regex,
} from 'utilities/constants';

import {
  mutationUnauth,
} from 'utilities/graph';
import { createSurvey } from 'graphql/mutations';

const ELIGIBILITY_SURVEY_ID = 'EligibilitySurvey';

const EligibilitySurvey = () => {
  const classes = useStyles();
  // form states
  const { control, errors, handleSubmit, formState, watch, getValues, trigger } = useForm({
    reValidateMode: 'onChange',
  });
  const { isSubmitting } = formState;
  const watchVehicleManufactureYear = watch('vehicleManufactureYear');
  const watchHasMonitoringDevice = watch('hasMonitoringDevice');
  const watchVehicleType = watch('vehicleType');
  const watchManualDeviceComfortability = watch('manualDeviceComfortability');
  const watchWillingToParticipateWithManualDevice = watch('willingToParticipateWithManualDevice');

  const [isTerminated, setIsTerminated] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [terminatedState, setTerminatedState] = useState({});

  const inputs = [{
    type: 'email',
    name: 'email',
    label: 'What is your preferred email address?',
    autoFocus: true,
    required: true,
    invalidText: 'Please provide a valid email address',
    defaultValue: terminatedState.email || '',
    pattern: regex.emailAddress,
  }, {
    type: 'number',
    name: 'postalCode',
    label: 'What is your zip code?',
    minLength: 5,
    maxLength: 5,
    required: true,
    invalidText: 'This is a required field',
    defaultValue: terminatedState.postalCode || '',
  }, {
    type: 'radio',
    name: 'hasDriverLicense',
    label: 'Do you have a driver license?',
    options: [{
      value: 'Yes',
      label: 'Yes',
    }, {
      value: 'No',
      label: 'No',
    }],
    required: true,
    invalidText: 'This is a required field',
    inputProps: {
      onChange: (event) => {
        const { value } = event.target;
        if (value === 'No') {
          terminateSurvey();
        }
      },
    },
    defaultValue: terminatedState.hasDriverLicense || '',
  }, {
    type: 'radio',
    name: 'vehicleStyle',
    label: 'Which of the following best describes your vehicle?',
    options: surveyVehicleStyles.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    required: true,
    invalidText: 'This is a required field',
    inputProps: {
      onChange: (event) => {
        const { value } = event.target;
        if (
          value === 'Motorcycle' ||
          value === 'I don\'t drive' ||
          value === 'Commercial van/truck'
        ) {
          terminateSurvey();
        }
      },
    },
    defaultValue: terminatedState.vehicleStyle || '',
  }, {
    type: 'radio',
    name: 'vehicleManufactureYear',
    label: 'What year was your vehicle manufactured?',
    options: surveyVehicleEligibilityYears.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    required: true,
    invalidText: 'This is a required field',
    defaultValue: terminatedState.vehicleManufactureYear || '',
  }, {
    type: 'radio',
    name: 'hasMonitoringDevice',
    label: 'Do you currently have a vehicle monitoring device installed in your vehicle (i.e. for car insurance)?',
    options: [{
      value: 'Yes',
      label: 'Yes',
    }, {
      value: 'No',
      label: 'No',
    }],
    invalidText: 'This is a required field',
    disabled: watchVehicleManufactureYear === 'Up to 2005',
    required: watchVehicleManufactureYear !== 'Up to 2005',
    defaultValue: terminatedState.hasMonitoringDevice || '',
  }, {
    type: 'radio',
    name: 'vehicleType',
    label: 'What engine does your vehicle use?',
    options: surveyVehicleTypes.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    invalidText: 'This is a required field',
    disabled: (
      watchVehicleManufactureYear === 'Up to 2005' ||
      watchHasMonitoringDevice === 'Yes'
    ),
    required: (
      watchVehicleManufactureYear !== 'Up to 2005' &&
      watchHasMonitoringDevice !== 'Yes'
    ),
    defaultValue: terminatedState.vehicleType || '',
  }, {
    type: 'radio',
    name: 'willingToParticipateWithManualDevice',
    /* eslint-disable-next-line */
    label: 'Unfortunately, your prior selection may disqualify you for participant in this study.  We would still love for you to take part but can only provide you a manual reporting option.  This involves manual entry of your vehicle odometer mileage and photo verification once per month.  Would you still be willing to participate?',
    options: [{
      value: 'Yes',
      label: 'Yes',
    }, {
      value: 'No',
      label: 'No',
    }],
    invalidText: 'This is a required field',
    inputProps: {
      onChange: (event) => {
        const { value } = event.target;
        if (value === 'No') {
          terminateSurvey();
        }
      },
    },
    disabled: (
      watchVehicleType === 'Gasoline' ||
      watchVehicleType === 'Hybrid'
    ),
    required: (
      watchVehicleType !== 'Gasoline' &&
      watchVehicleType !== 'Hybrid'
    ),
  }, {
    type: 'radio',
    name: 'manualDeviceComfortability',
    label: 'How comfortable would you be installing a plug-in device in your vehicle?',
    options: surveyComfortability.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    invalidText: 'This is a required field',
    disabled: (
      watchWillingToParticipateWithManualDevice === 'Yes'
    ),
    required: (
      watchWillingToParticipateWithManualDevice === 'No'
    ),
    defaultValue: terminatedState.manualDeviceComfortability || '',
  }, {
    type: 'text',
    multiline: true,
    name: 'comfortabilityReason',
    label: 'Please explain why you would be uncomfortable.',
    invalidText: 'This is a required field',
    disabled: (
      watchWillingToParticipateWithManualDevice === 'Yes' ||
      (
        watchManualDeviceComfortability !== 'Very uncomfortable' &&
        watchManualDeviceComfortability !== 'Somewhat uncomfortable'
      )
    ),
    required: (
      watchWillingToParticipateWithManualDevice === 'No' &&
      watchManualDeviceComfortability === 'Very uncomfortable' ||
      watchManualDeviceComfortability === 'Somewhat uncomfortable'
    ),
    defaultValue: terminatedState.comfortabilityReason || '',
  }, {
    type: 'radio',
    name: 'drivingAreas',
    label: 'Starting in June, in which areas will you most likely drive?',
    options: surveyAreas.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    required: true,
    invalidText: 'This is a required field',
    defaultValue: terminatedState.drivingAreas || '',
  }, {
    type: 'radio',
    name: 'drivingFrequency',
    label: 'Starting in June, how frequently will you drive in the indicated area?',
    options: surveyAreaFrequencies.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    required: true,
    invalidText: 'This is a required field',
    defaultValue: terminatedState.drivingFrequency || '',
  }, {
    type: 'radio',
    name: 'drivingTimesOfDay',
    label: 'Starting in June, what time of day will you be driving in the indicated area?',
    options: surveyTimesOfDay.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    required: true,
    invalidText: 'This is a required field',
    defaultValue: terminatedState.drivingTimesOfDay || '',
  }];

  async function handleSubmitSurvey(formData) {
    const survey = {
      surveyId: ELIGIBILITY_SURVEY_ID,
      questions: [],
    };

    inputs.forEach((input) => {
      survey.questions.push({
        type: input.name,
        question: input.label,
        answer: (typeof formData[input.name] !== 'undefined') ? formData[input.name] : 'N/A',
      });
    });

    await mutationUnauth(createSurvey, {
      input: survey,
    });

    setIsComplete(true);
  }

  function terminateSurvey() {
    setTerminatedState(getValues());
    setIsTerminated(true);
  }

  function backToSurvey() {
    setIsTerminated(false);
    trigger();
  }

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Box marginBottom={4}>
          <Logo width="100%" withETCoalition={true} />
        </Box>
        <Typography component="h3" variant="h3" className={classes.driveTU}>
          MBUF Study Eligibility Survey
        </Typography>
        {!isTerminated && !isComplete && (
          <div>
            <p>
              With more fuel efficient and electric vehicles on the roadways the funds that are collected
              from the state gas tax are decreasing.  This impacts finances used for public infrastructure
              improvements, maintenance and operations.
            </p>
            <p>
              This MBUF Study tests the feasibility of a mileage-based user fee (MBUF) as a replacement
              to the state gas tax.  You will receive a mock invoice based on the number of miles driven
              as part of your participation.
            </p>
            <p>
              To be eligible to participante, you need to have a vehicle that is from 2006 or newer and
              uses either gas or is a hybrid.  This real-world study runs from June to the end of October 2021.
            </p>
            <form
              className={classes.form}
              onSubmit={handleSubmit(handleSubmitSurvey)}
              noValidate
            >
              <Grid container justify="center">
                {inputs.map((input, index) => {
                  input.label = `${index + 1}. ${input.label}`;
                  if (input.disabled) {
                    return (
                      <Grid item xs={12} key={index}>
                        <Box marginBottom={2}>
                          <span className={classes.notRequired}>
                            {`Question ${index + 1} is not required due to a previous answer.`}
                          </span>
                        </Box>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs={12} key={index}>
                      <Box marginBottom={2}>
                        <ControlledInput
                          control={control}
                          errors={errors}
                          {...input}
                        />
                      </Box>
                    </Grid>
                  );
                })}
                <Grid item xs={12} sm={6}>
                  <Box marginBottom={4} marginTop={4}>
                    <Button
                      type="submit"
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </div>
        )}

        {isTerminated && (
          <Box marginTop={4}>
            <Box marginBottom={4}>
              <Alert severity="info">
                <p>
                  We want to thank you for taking our survey but unfortunately, you are not eligible to participant
                  in our MBUF Study.  To be eligible you must have a vehicle fitting study criteria or be willing
                  to use the manual reporting option.  If you have any questions, please email our study Help Desk
                  at <a href="mailto:connecticut@mbufstudy.org">connecticut@mbufstudy.org</a>.  If you would like to retake the
                  survey to submit a different vehicle, please re-fresh the page.  Safe travels.
                </p>
              </Alert>
            </Box>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="default"
              onClick={backToSurvey}
            >
              Start Over
            </Button>
          </Box>
        )}

        {isComplete && (
          <Box marginTop={4}>
            <Box marginBottom={4}>
              <Alert severity="success">
                <p>
                  Congratulations, you are eligible for the MBUF Study!  We will review eligible applications, and
                  if selected, will contact you shortly about full enrollment using the email address you provided.
                  If you have any questions, please email our study Help Desk at
                  <a href="mailto:connecticut@mbufstudy.org">connecticut@mbufstudy.org</a>.  Safe travels.
                </p>
              </Alert>
            </Box>
          </Box>
        )}
      </div>
    </Container >
  );
};

EligibilitySurvey.propTypes = {};
export default EligibilitySurvey;
