import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import CreateTripAdjustmentDialog from 'pages/Admin/components/CreateTripAdjustmentDialog';
import { sortBy } from 'utilities/sorting';
import { asyncListAll } from 'utilities/graph';
import { listTripAdjustments, getTripAdjustmentsByTripSegmentIdByCreatedAt } from 'graphql/queries';

function TripAdjustmentsTable({
  title = 'Adjustments',
  description = '',
  data: inData,
  tripId,
  tripSegmentId,
  viewer = 'admin',
  pilotProgram = '',
}) {
  const [data, setData] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const columns = [
    {
      name: 'tripId',
      label: 'Trip ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'username',
      label: 'User ID',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'adjMileage',
      label: 'ADJ Mileage',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjMileageFeeCents',
      label: 'ADJ Mileage Fee',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjFuel',
      label: 'ADJ Fuel',
      type: 'fuel',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjFuelFeeCents',
      label: 'ADJ Fuel Tax Credits',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjCordonFeeCents',
      label: 'ADJ Cordon Fee',
      type: 'currency',
      options: {
        display: viewer === 'participant' && pilotProgram === 'MBUF+C',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjTodFeeCents',
      label: 'ADJ TOD Fee',
      type: 'currency',
      options: {
        display: viewer === 'participant' && pilotProgram === 'MBUF+ToD',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'adjTollFeeCents',
      label: 'ADJ Toll Fee',
      type: 'currency',
      options: {
        display: viewer === 'participant' && pilotProgram === 'MBUF+TF',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'paymentStatus',
      label: 'Payment Status',
      options: {
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
  ].filter((x) => {
    return viewer === 'participant' && x.options ? x.options.display !== false : true;
  });

  const options = {};

  useEffect(() => {
    if (inData) {
      setData(inData);
      return;
    }
    (async () => {
      let data = [];

      if (tripSegmentId) {
        data = await asyncListAll(getTripAdjustmentsByTripSegmentIdByCreatedAt, { tripSegmentId });
      } else
      if (tripId) {
        data = await asyncListAll(listTripAdjustments, { tripId });
      }

      setData(data.map((item) => {
        item.name = item.cordonName || item.todName || item.tollId || item.state;
        return item;
      }));
    })();
  }, [inData, tripId, tripSegmentId]);

  return (
    <React.Fragment>
      <Table
        title={title}
        description={description}
        data={data.sort(sortBy('createdAt', true))}
        columns={columns}
        options={options}
        onHandleCreateItem={viewer === 'admin' ? () => setCreateDialogOpen(true) : null}
      />
      <CreateTripAdjustmentDialog
        isOpen={createDialogOpen}
        onClose={(savedItems) => {
          setCreateDialogOpen(false);
          if (savedItems) {
            setData([
              ...savedItems.map((item)=>{
                item.name = item.cordonName || item.todName || item.tollId || item.state;
                return item;
              }),
              ...data]);
          }
        }}
        tripId={tripId}
        tripSegmentId={tripSegmentId}
      />
    </React.Fragment>
  );
}

TripAdjustmentsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tripId: PropTypes.string,
  tripSegmentId: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  pilotProgram: PropTypes.string,
};

export default TripAdjustmentsTable;
