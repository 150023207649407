const drivetu = {
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#0A4842',
      },
    },
    MuiInputAdornment: {
      textTransform: 'lowercase',
    },
  },
  palette: {
    primary: {
      light: '#00cc84',
      main: '#14A248',
      dark: '#0A4842',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ED9B2B',
    },
  },
};

const connecticut = {
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#052b48',
      },
    },
    MuiInputAdornment: {
      textTransform: 'lowercase',
    },
    MuiInputBase: {
      inputMultiline: {
        resize: 'vertical',
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1a428a',
      light: '#6786b8',
      dark: '#052b48',
    },
    secondary: {
      main: '#f18a00',
    },
    success: {
      main: '#8dc63f',
    },
  },
  typography: {
    fontFamily: 'futura-pt',
  },
};

export {
  connecticut,
  drivetu,
};
