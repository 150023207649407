import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';

import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import MileageReport from 'pages/Participant/Account/components/MileageReport';

const CreateMileageReportDialog = ({ isOpen, onClose, username }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="register-vehicle-dialog"
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
      >
        <DialogContent>
          <MileageReport
            username={username}
            onComplete={() => {
              onClose(true);
            }}
            onCancel={onClose}
            options={{
              submitCopy: 'Create Mileage Report',
              cancelCopy: 'Cancel',
            }}

          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

CreateMileageReportDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  username: PropTypes.string,
};

export default CreateMileageReportDialog;
