import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Alert from '@material-ui/lab/Alert';

import Logo from 'components/Logo';
import { useStyles } from './components/commonStyles';

const Oops = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo width={250} margin={16} />
      </div>
      <Alert severity="error">
        <Typography component="h1" variant="h5">Oops!</Typography>
        <p>It looks like you attempted to register with an invalid MBUF Study.</p>
        <p>Please reference the invitation that was sent to your email address and navigate to the registration hyperlink provided in that message.</p>
        <p><strong>Note</strong>: attempting to login without using the aforementioned link may redirect you back to this page.</p>
      </Alert>
    </Container>
  );
};

export default Oops;
