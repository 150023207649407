import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { flavor } from '../../index';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(0),
    padding: 5,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function Logo({ fullColor, withETCoalition, width = 0, height = 0, margin = 0, darkBackground = false, avatar }) {
  const classes = useStyles();

  if (avatar) {
    return (
      <Avatar variant="circle" className={classes.avatar} src="/favicon.ico" alt="TU" />
    );
  }

  let filepath = 'DriveTU-Logo.svg';
  if (flavor === 'connecticut') {
    filepath = `tetc-tu-combined-logo${darkBackground ? '-white' : ''}.svg`;
  } else {
    if (withETCoalition) {
      filepath = 'DriveTU-EasternTransportationCoalition-Logo.png';
    } else if (fullColor) {
      filepath = 'DriveTU-Logo-FullColor.svg';
    }
  }

  return (
    <img
      src={`/images/${filepath}`}
      alt="Transurban"
      style={{ margin }}
      width={width || 'auto'}
      height={height || '100%'}
    />
  );
}

Logo.propTypes = {
  fullColor: PropTypes.bool,
  withETCoalition: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number,
  darkBackground: PropTypes.bool,
  avatar: PropTypes.bool,
};
