import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import VehicleTable from 'pages/Admin/components/VehicleTable';
import CreateVehicleDialog from './CreateVehicleDialog';
import RemoveVehicleDialog from './RemoveVehicleDialog';

import { asyncListAll } from 'utilities/graph';
import {
  getParticipantVehicles,
} from './queries';

import { useStyles } from '../styles';

const ParticipantVehicles = ({ username }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [vehicles, setVehicles] = useState([]);

  const [createVehicleDialogIsOpen, setCreateVehicleDialogIsOpen] = useState(false);
  const [removeVehicleDialogIsOpen, setRemoveVehicleDialogIsOpen] = useState(false);
  const [actionVehicle, setActionVehicle] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const vehicles = await asyncListAll(
          getParticipantVehicles,
          { username }, { bypassCache: true },
        );
        setVehicles(vehicles);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username, forceRefresh]);

  const handleOpenCreateVehicleDialog = () => {
    setCreateVehicleDialogIsOpen(true);
  };

  const handleCloseCreateVehicleDialog = (forceUpdate) => {
    setCreateVehicleDialogIsOpen(false);
    if (forceUpdate) {
      setForceRefresh(!forceRefresh);
    }
  };

  const handleOpenRemoveVehicleDialog = (vehicle) => {
    setRemoveVehicleDialogIsOpen(true);
    setActionVehicle(vehicle);
  };

  const handleCloseRemoveVehicleDialog = (forceUpdate) => {
    setRemoveVehicleDialogIsOpen(false);
    setActionVehicle(null);
    if (forceUpdate) {
      setForceRefresh(!forceRefresh);
    }
  };

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <>
      <VehicleTable
        title="Participant's Vehicles"
        data={vehicles}
        onHandleCreate={handleOpenCreateVehicleDialog}
        onHandleRemove={handleOpenRemoveVehicleDialog}
      />
      <>
        <CreateVehicleDialog
          isOpen={createVehicleDialogIsOpen}
          onClose={handleCloseCreateVehicleDialog}
          username={username}
        />
        <RemoveVehicleDialog
          isOpen={removeVehicleDialogIsOpen}
          onClose={handleCloseRemoveVehicleDialog}
          vehicle={actionVehicle}
        />
      </>
    </>
  );
};

ParticipantVehicles.propTypes = {
  username: PropTypes.string,
};

export default ParticipantVehicles;
