import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '@material-ui/core/Container';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { listParticipantConnections } from './queries';
import { listVehicles } from 'graphql/queries';
import { updateParticipantConnection } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { connect, rucScope } from 'utilities/smartcar';

import { useStyles } from './styles';

const Connections = ({ user }) => {
  const classes = useStyles();

  const [connections, setConnections] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const { username } = user;

  useEffect(() => {
    (async () => {
      try {
        const [
          connections,
          vehicles,
        ] = await Promise.all([
          asyncListAll(listParticipantConnections, { username }),
          asyncListAll(listVehicles, { username }),
        ]);

        setConnections(connections);
        setVehicle(vehicles[0]);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [username]);

  const reconnectSmartcar = async (connection) => {
    try {
      const code = await connect(rucScope, {
        vehicleInfo: {
          make: vehicle.make.toUpperCase(),
        },
      });

      const updatedConnection = Object.assign({}, connection, {
        accessToken: '',
        authorizationCode: code,
        forceRestoreConnection: false,
        refreshToken: '',
        updatedAt: new Date().toISOString(),
        username,
      });

      await asyncRetryMutation(updateParticipantConnection, {
        input: updatedConnection,
      });

      const updatedIndex = connections.findIndex(({ id }) => {
        return id === updatedConnection.id;
      });

      setConnections(connections.map((connection, index) => {
        return (index === updatedIndex) ? updatedConnection : connection;
      }));
    } catch (e) {
      console.warn(e.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <DriveEtaOutlinedIcon color="inherit" />
        </Avatar>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Connections
          </Typography>
          <List>
            {connections.map((connection, index) => {
              if (connection.resourceProvider === 'smartcar') {
                return (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={`Smartcar - ${moment(connection.createdAt).format('YYYY/MM/DD')}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        color={connection.forceRestoreConnection ? 'secondary' : 'primary'}
                        onClick={() => {
                          if (connection.forceRestoreConnection) {
                            reconnectSmartcar(connection);
                          }
                        }}
                      >
                        {connection.forceRestoreConnection ? (
                          <Tooltip title="Restore Connection" placement="right">
                            <ClearIcon />
                          </Tooltip>
                        ) : (
                          <CheckIcon />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
            })}
          </List>
        </div>
      </div>
    </Container >
  );
};

Connections.propTypes = {
  user: PropTypes.object,
};

export default Connections;
