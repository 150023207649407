/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import BasicInfo from './components/BasicInfo';
import BillingInfo from './components/BillingInfo';
import Inquiries from './components/Inquiries';
import MileageReport from './components/MileageReport';
import MROInfo from './components/MROInfo';
import ParticipantTrips from 'pages/Admin/Participant/components/ParticipantTrips';
import Statements from './components/Statements';
import VehicleInfo from './components/VehicleInfo';
import Connections from './components/Connections';
import Dashboard from './components/Dashboard';

import { asyncGet } from 'utilities/graph';
import { getParticipant } from 'graphql/queries';
import { COLLECT_BILLING_INFORMATION } from 'utilities/constants/paymentStatus';

const MyAccount = ({ user }) => {
  const history = useHistory();
  const { username } = user;

  const [participant, setParticipant] = useState(null);

  useEffect(() => {
    (async () => {
      const {
        data: {
          getParticipant: participant,
        },
      } = await asyncGet(getParticipant, {
        username,
      });

      setParticipant(participant);
      localStorage.setItem('ruc:name', `${participant.firstName} ${participant.lastName}`);
      localStorage.setItem('ruc:email', participant.email);
    })();
  }, [username]);

  if (!participant) {
    return <div />;
  }

  if (participant.status === 'created') {
    return history.push('/register');
  }

  if (participant.status === 'onboarded') {
    return (
      <Grid container justify="center" data-test-id="account-review">
        <Grid item xs={12} sm={9} md={6}>
          <Alert severity="info">
            <AlertTitle>Registration Complete</AlertTitle>
            <p>
              Thank you for completing your MBUF Study enrollment. We will review your application within 2 – 3 business days. If your application is approved, we will email you with your account details and next steps.
            </p>
            <p>If you need help, please contact the MBUF Study Help Desk at <a href="mailto:connecticut@mbufstudy.org">connecticut@mbufstudy.org</a></p>
            <p>Safe travels.</p>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  const tabs = [
    {
      label: 'Dashboard',
      component: <Dashboard user={user} />,
    },
    {
      label: 'My Info',
      component: <BasicInfo user={user} />,
    },
    {
      label: 'Vehicles',
      component: <VehicleInfo user={user} />,
    },
    {
      label: 'Trips',
      component: <ParticipantTrips
        username={username}
        title="My Trips"
        viewer="participant"
        pilotProgram={participant.pilotProgram.shortName}
      />,
    },
    {
      label: 'Create Mileage Report',
      component: <MileageReport username={username} />,
      hideFromMenu: participant ? participant.pilotProgram.shortName !== 'OB-MBUF' : true,
    },
    {
      label: 'Reporting Device',
      component: <MROInfo user={user} />,
      hideFromMenu: participant.mroDevicePreference === 'telematics',
    },
    {
      label: 'Connections',
      component: <Connections user={user} />,
      hideFromMenu: participant.mroDevicePreference !== 'telematics',
    },
    {
      label: 'Billing Info',
      component: <BillingInfo user={user} />,
      hideFromMenu: !COLLECT_BILLING_INFORMATION,
    },
    {
      label: 'Statements',
      component: <Statements username={username} />,
    },
    {
      label: 'Inquiries',
      component: <Inquiries username={username} />,
    },
  ].filter(({ hideFromMenu }) => !hideFromMenu);

  return (
    <VerticalTabs
      tabs={tabs}
    />
  );
};

MyAccount.propTypes = {
  user: PropTypes.object,
};

export default MyAccount;
