import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Update';

import Table from 'components/Table';
import {
  vehicleMakesAndModels,
  states,
} from 'utilities/constants';
import { asyncRetryMutation } from 'utilities/graph';
import { updateVehicle } from 'graphql/mutations';

const VehicleTable = ({
  title = 'Vehicles',
  description = '',
  data: inData,
  onHandleCreate,
  onHandleRemove,
}) => {
  const [data, setData] = useState();

  const columns = [
    {
      name: 'mroId',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mro.deviceSerialNumber',
      label: 'MRO ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isPrimary',
      label: 'Primary',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'vin',
      label: 'VIN',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (value) ? value : (
            <Tooltip
              title="Waiting for vehicle to report data."
              placement="right"
            >
              <UpdateIcon />
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'make',
      label: 'Make',
      edit: {
        type: 'select',
        menu: vehicleMakesAndModels.map(({ brand }) => brand),
        rerenderAfterSelect: true, // to refresh model menu
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'model',
      label: 'Model',
      edit: {
        type: 'select',
        menu: (data) => {
          const { models } = vehicleMakesAndModels.find(({ brand }) => brand === data.make);
          return models;
        },
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'year',
      label: 'Year',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'licensePlate',
      label: 'License Plate',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'registrationState',
      label: 'State',
      edit: {
        type: 'select',
        menu: Object.keys(states),
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'beginningOdometerReading',
      label: 'Beginning Odometer Reading',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'currentOdometerReading',
      label: 'Current Odometer Reading',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mileage',
      label: 'Mileage',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: 'fuelTaxCreditCents',
    //   label: 'Fuel Tax Credit',
    //   type: 'currency',
    //   edit: {
    //     type: 'currency',
    //   },
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    // {
    //   name: 'mileageUserFeeCents',
    //   label: 'MBUF',
    //   type: 'currency',
    //   edit: {
    //     type: 'currency',
    //   },
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {};

  const onUpate = async (item, dataIndex) => {
    const input = {
      username: item.username,
      id: item.id,
      updatedBy: localStorage.getItem('ruc:username'),
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateVehicle, { input });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  useEffect(() => {
    setData(inData);
  }, [inData]);

  return (
    <div data-test-id="vehicles-table">
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        onHandleCreateItem={onHandleCreate}
        onHandleRemoveItem={onHandleRemove}
        onUpdateItem={onUpate}
      />
    </div>
  );
};

VehicleTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array.isRequired,
  onHandleCreate: PropTypes.func,
  onHandleRemove: PropTypes.func,
};

export default VehicleTable;
