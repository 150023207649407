import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import ControlledInput from 'components/Form/ControlledInput';

import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { customerSupportTopics } from 'utilities/constants';

import {
  createParticipantInquiry,
  createParticipantInquiryComment,
} from 'graphql/mutations';
import { asyncRetryMutation } from 'utilities/graph';

const MINIMUM_MESSAGE_LENGTH = 25;

const ContactUs = () => {
  const classes = useStyles();
  const history = useHistory();

  // state
  const [error, setError] = useState(false);

  // form states
  const {
    control,
    errors,
    formState,
    handleSubmit,
  } = useForm();

  const { isSubmitting } = formState;
  const inputs = [{
    type: 'select',
    name: 'topic',
    label: 'Topic',
    options: customerSupportTopics.map((topic) => {
      return {
        label: topic,
        value: topic,
      };
    }),
    required: true,
    invalidText: 'Topic is required',
  }, {
    type: 'text',
    name: 'subject',
    label: 'Subject',
    required: true,
    invalidText: 'Subject is required',
  }, {
    type: 'text',
    multiline: true,
    rows: 5,
    name: 'message',
    label: 'Message',
    required: true,
    invalidText: 'Message is required with at least 25 characters',
    inputProps: {
      minLength: MINIMUM_MESSAGE_LENGTH,
    },
  }];

  async function handleContact({
    topic,
    subject,
    message,
  }) {
    const username = localStorage.getItem('ruc:username');
    const email = localStorage.getItem('ruc:email');
    const name = localStorage.getItem('ruc:name');

    try {
      if (message.trim().length < MINIMUM_MESSAGE_LENGTH) {
        setError(`A minimum of ${MINIMUM_MESSAGE_LENGTH} characters is required for the message.`);
        return;
      }

      const {
        data: {
          createParticipantInquiry: {
            id: inquiryId,
          },
        },
      } = await asyncRetryMutation(createParticipantInquiry, {
        input: {
          username,
          createdBy: username,
          category: topic,
          subject: subject,
          body: message,
          status: 'new',
          name,
          email,
        },
      });

      await asyncRetryMutation(createParticipantInquiryComment, {
        input: {
          inquiryId,
          username,
          author: 'participant',
          body: message,
          type: 'message',
          status: 'unread',
        },
      });

      const autoResponseText = 'Your message has been received.  We will respond within 2 business days.';
      await asyncRetryMutation(createParticipantInquiryComment, {
        input: {
          inquiryId,
          username,
          author: 'system',
          body: autoResponseText,
          type: 'message',
          status: 'unread',
          emailPayload: {
            from: `Drive TU Support`,
            to: `${name} <${email}>`,
            subject: `Re: ${subject}`,
            text: autoResponseText,
            html: [
              `<p><b>Name: ${name}</b></p>`,
              `<p><b>Subject: ${subject}</b></p>`,
              `<p><b>Message: ${message}</b></p>`,
              '<br/>',
              `<p>${autoResponseText}</p>`,
            ].join(''),
          },
        },
      });

      return history.push('dashboard?tab=Inquiries');
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Contact Customer Support
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleContact)}
          noValidate
        >
          <Grid container spacing={2}>
            {inputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            Send
          </Button>
        </form>
      </div>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactUs;
