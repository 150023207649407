import React from 'react';
import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';
import { Storage } from 'aws-amplify';

import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { createBrowserHistory } from 'history';

import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/Home';
import { APP } from './utilities/constants';
import { authRoutes } from './routes';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
Analytics.disable();
Storage.configure({ level: 'public' });

const history = createBrowserHistory();

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        {authRoutes.map((item)=>(
          <item.route
            key={item.path}
            exact={item.exact}
            path={item.path}
            roles={item.roles}
            render={ (props) => (
              <DocumentTitle title={`${APP.SHORT_NAME} | ${item.title}`}>
                <item.component {...props} />
              </DocumentTitle>)
            }/>
        ))}
        <ProtectedRoute path="/" component={Home} />
      </Switch>
    </Router>
  );
};

export default App;
