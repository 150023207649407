import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChipInput from 'material-ui-chip-input';

import {
  listVehicles,
} from 'graphql/queries';
import {
  vinReport,
} from 'graphql/mutations';

import { asyncRetryMutation, asyncListAll } from 'utilities/graph';
import MultiSelect from 'components/MultiSelect';

const TIME_ZONE = 'America/New_York';
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },

}));

const options = [
  {
    key: 'summaryVins',
    label: 'VIN Summary Report.csv',
  },
  // {
  //   key: 'summaryMros',
  //   displayName: 'Summary for MROs.csv',
  // },
  // {
  //   key: 'summaryPilotPrograms',
  //   displayName: 'Summary for Rules.csv',
  // },
  // {
  //   key: 'trips',
  //   displayName: 'Mileage messages.csv',
  // },
  {
    key: 'mroEvents',
    label: 'Errors and Events Report.csv',
  },
  {
    key: 'info',
    label: 'Account and VIN Update Report.csv',
  },
  {
    key: 'mbuf',
    label: 'Mileage and MBUF Revenue Report.csv',
  },
  {
    key: 'enrollments',
    label: 'Enrollment Status.csv',
  },
  {
    key: 'mrdReporting',
    label: 'MRD Reporting.csv',
  },
].map((x) => {
  x.displayName = x.label;
  return x;
});

const EMAIL_CACHE_KEY = 'ruc:vinReportEmails';

export default function VinReport() {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(options);
  const [emails, setEmails] = useState(JSON.parse(localStorage.getItem(EMAIL_CACHE_KEY) || '[]'));
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  useEffect(() => {
    (async () => {
      const allVehicles = await asyncListAll(listVehicles);
      const vehicleOptions = allVehicles
        .filter(({ vin }) => vin)
        .map((item) => {
          item.displayName = `${item.vin || 'N/A'} (${item.licensePlate}-${item.registrationState})`;
          return item;
        })
        .sort((a, b) => a.displayName > b.displayName ? 1 : -1);
      setVehicles(vehicleOptions);
      setSelectedVehicles(vehicleOptions);
    })();
  }, []);

  const submit = async () => {
    try {
      setIsSubmitting(true);
      const payload = {
        input: {
          from: moment(from).tz(TIME_ZONE).startOf('day').toISOString(),
          to: moment(to).tz(TIME_ZONE).endOf('day').toISOString(),
          vins: selectedVehicles.map(({ vin }) => vin),
          emails,
          options: selectedOptions.map(({ key }) => key),
        },
      };
      console.log(payload);
      await asyncRetryMutation(vinReport, payload);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              VIN Reports
            </Typography>
            <MultiSelect
              label="VINs"
              options={vehicles}
              optionsLabelKey="displayName"
              value={selectedVehicles}
              onChange={(e) => {
                setSelectedVehicles(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelect
              label="Options"
              options={options}
              optionsLabelKey="displayName"
              value={selectedOptions}
              onChange={(e) => setSelectedOptions(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ChipInput
              label="Emails"
              fullWidth={true}
              defaultValue={emails}
              onChange={(chips) => {
                setEmails(chips);
                localStorage.setItem(EMAIL_CACHE_KEY, JSON.stringify(chips));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={emails.length === 0 || isSubmitting}
              onClick={submit}
            >
              Email VIN reports
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
