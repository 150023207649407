export const vehicleMakesAndModels = [
  {
    'brand': 'AM General',
    'models': [
      'DJ Po Vehicle 2WD',
      'FJ8c Post Office',
      'Post Office DJ5 2WD',
      'Post Office DJ8 2WD',
    ],
  },
  {
    'brand': 'ASC Incorporated',
    'models': [
      'GNX',
    ],
  },
  {
    'brand': 'Acura',
    'models': [
      '2.2CL/3.0CL',
      '2.3CL/3.0CL',
      '2.5TL',
      '2.5TL/3.2TL',
      '3.2CL',
      '3.2TL',
      '3.5RL',
      'ILX',
      'ILX Hybrid',
      'Integra',
      'Legend',
      'MDX',
      'MDX 2WD',
      'MDX 4WD',
      'MDX AWD',
      'MDX AWD A-SPEC',
      'MDX FWD',
      'MDX Hybrid AWD',
      'NSX',
      'NSX Hybrid',
      'RDX 2WD',
      'RDX 4WD',
      'RDX AWD',
      'RDX AWD A-SPEC',
      'RDX FWD',
      'RDX FWD A-SPEC',
      'RL',
      'RLX',
      'RLX Hybrid',
      'RSX',
      'SLX',
      'TL',
      'TL 2WD',
      'TL 4WD',
      'TLX',
      'TLX 2WD',
      'TLX 4WD',
      'TLX AWD',
      'TLX AWD A-SPEC',
      'TLX FWD',
      'TLX FWD A-SPEC',
      'TSX',
      'TSX Wagon',
      'Vigor',
      'ZDX 4WD',
    ],
  },
  {
    'brand': 'Alfa Romeo',
    'models': [
      '164',
      '4C',
      '8 C Spider',
      'GT V6 2.5',
      'GTV',
      'Giulia',
      'Giulia AWD',
      'Milano',
      'Spider',
      'Spider Veloce 2000',
      'Stelvio',
      'Stelvio AWD',
    ],
  },
  {
    'brand': 'American Motors Corporation',
    'models': [
      'Eagle',
      'Eagle 4DR Wagon',
      'Eagle 4WD',
      'Eagle SX/4 4WD',
    ],
  },
  {
    'brand': 'Aston Martin',
    'models': [
      'DB AR1',
      'DB-7 GT Coupe',
      'DB-7 GT Volante',
      'DB-7 Vantage Coupe',
      'DB-7 Vantage Volante',
      'DB11 V12',
      'DB11 V8',
      'DB7 Coupe',
      'DB7 Volante',
      'DB9',
      'DB9 Coupe',
      'DB9 Coupe Manual',
      'DB9 Volante',
      'DB9 Volante Manual',
      'DBS',
      'DBS Coupe',
      'DBX V8',
      'Lagonda',
      'Rapide',
      'Rapide S',
      'Saloon/Vantage/Volante',
      'Saloon/vantage/volante',
      'V12 Vanquish',
      'V12 Vanquish S',
      'V12 Vantage',
      'V12 Vantage S',
      'V8 Vantage',
      'V8 Vantage ASM',
      'V8 Vantage S',
      'Vanquish',
      'Vanquish S',
      'Vanquish S Zagato',
      'Vantage GT',
      'Vantage Manual',
      'Vantage V8',
      'Virage',
      'Virage Saloon',
      'Virage/Volante',
    ],
  },
  {
    'brand': 'Audi',
    'models': [
      '100',
      '100 Wagon',
      '100 quattro',
      '100 quattro Wagon',
      '200',
      '200 quattro',
      '200 quattro 20v',
      '200 quattro 20v Wagon',
      '200 quattro Wagon',
      '4000',
      '4000 CS quattro',
      '4000 S',
      '4000 S quattro',
      '4000s',
      '4000s quattro',
      '5000 CS Turbo',
      '5000 CS quattro',
      '5000 CS quattro Wagon',
      '5000 S',
      '5000 S quattro',
      '5000S',
      '5000S Wagon',
      '80',
      '80 quattro',
      '80/90',
      '80/90 quattro',
      '90',
      '90 quattro',
      '90 quattro 20v',
      'A3',
      'A3 Cabriolet',
      'A3 Cabriolet quattro',
      'A3 e-tron',
      'A3 e-tron ultra',
      'A3 quattro',
      'A4',
      'A4 Avant',
      'A4 Avant quattro',
      'A4 Cabriolet',
      'A4 Cabriolet quattro',
      'A4 S line quattro',
      'A4 Ultra',
      'A4 allroad quattro',
      'A4 quattro',
      'A5 Cabriolet',
      'A5 Cabriolet quattro',
      'A5 Sportback S line quattro',
      'A5 Sportback quattro',
      'A5 quattro',
      'A6',
      'A6 Allroad',
      'A6 Avant quattro',
      'A6 Wagon',
      'A6 Wagon quattro',
      'A6 quattro',
      'A6 quattro Wagon',
      'A7 quattro',
      'A8',
      'A8 L',
      'A8 quattro',
      'Allroad',
      'Allroad quattro',
      'Cabriolet',
      'Coupe GT',
      'Coupe quattro',
      'Q3',
      'Q3 S-line quattro',
      'Q3 quattro',
      'Q5',
      'Q5 Hybrid',
      'Q5 Sportback',
      'Q7',
      'Q8',
      'Quattro',
      'R8',
      'R8 2WD',
      'R8 AWD',
      'R8 RWD',
      'R8 Spyder',
      'R8 Spyder 2WD',
      'R8 Spyder AWD',
      'R8 Spyder RWD',
      'RS 3',
      'RS 5',
      'RS 5 Cabriolet',
      'RS 5 Sportback',
      'RS 6 Avant',
      'RS 7',
      'RS Q8',
      'RS3',
      'RS4',
      'RS4 Cabriolet',
      'RS6',
      'S3',
      'S4',
      'S4 Avant',
      'S4 Cabriolet',
      'S5',
      'S5 Cabriolet',
      'S5 Sportback',
      'S6',
      'S6 Avant',
      'S6 Wagon',
      'S7',
      'S8',
      'S8 quattro',
      'SQ5',
      'SQ5 Sportback',
      'SQ7',
      'SQ8',
      'TT Coupe',
      'TT Coupe quattro',
      'TT RS',
      'TT RS Coupe',
      'TT Roadster',
      'TT Roadster quattro',
      'TTS Coupe',
      'V8',
      'allroad quattro',
      'e-tron',
      'e-tron Sportback',
    ],
  },
  {
    'brand': 'Aurora Cars Ltd',
    'models': [
      'Aurora',
    ],
  },
  {
    'brand': 'Autokraft Limited',
    'models': [
      'A.C.MARK IV',
      'A.C.Mkiv',
    ],
  },
  {
    'brand': 'Avanti Motor Corporation',
    'models': [
      'Avanti II',
    ],
  },
  {
    'brand': 'Azure Dynamics',
    'models': [
      'Transit Connect Electric Van',
      'Transit Connect Electric Wagon',
    ],
  },
  {
    'brand': 'BMW',
    'models': [
      '128ci Convertible',
      '128i',
      '128i Convertible',
      '135i',
      '135i Convertible',
      '228i',
      '228i Convertible',
      '228i Gran Coupe',
      '228i xDrive',
      '228i xDrive Convertible',
      '228i xDrive Gran Coupe',
      '230i Convertible',
      '230i Coupe',
      '230i xDrive Convertible',
      '230i xDrive Coupe',
      '3 Series',
      '318i',
      '318i Convertible',
      '318i/318iS',
      '318i/318is',
      '318is',
      '318is Convertible',
      '318ti',
      '320i',
      '320i xDrive',
      '323ci',
      '323i',
      '323i Convertible',
      '323i Touring',
      '323is',
      '325ci',
      '325ci Convertible',
      '325i',
      '325i Convertible',
      '325i Sport Wagon',
      '325i/325iS',
      '325i/325is',
      '325ic',
      '325is',
      '325ix',
      '325xi',
      '325xi Sport Wagon',
      '328ci',
      '328ci Convertible',
      '328ci xDrive',
      '328cxi',
      '328d',
      '328d xDrive',
      '328d xDrive Sports Wagon',
      '328i',
      '328i Convertible',
      '328i Coupe',
      '328i Coupe xDrive',
      '328i Sport Wagon',
      '328i Sport Wagon xDrive',
      '328i Sports Wagon',
      '328i xDrive',
      '328i xDrive Gran Turismo',
      '328i xDrive Sport Wagon',
      '328i xDrive Sports Wagon',
      '328i/328is',
      '328ic',
      '328is',
      '328xi',
      '328xi Sport Wagon',
      '330ci',
      '330ci Convertible',
      '330e',
      '330e xDrive',
      '330i',
      '330i xDrive',
      '330i xDrive Gran Turismo',
      '330i xDrive Sports Wagon',
      '330xi',
      '335ci',
      '335ci Convertible',
      '335ci xDrive',
      '335cxi',
      '335d',
      '335i',
      '335i Convertible',
      '335i Coupe',
      '335i Coupe xDrive',
      '335i xDrive',
      '335i xDrive Gran Turismo',
      '335is Convertible',
      '335is Coupe',
      '335xi',
      '340i',
      '340i xDrive',
      '340i xDrive Gran Turismo',
      '428i Convertible',
      '428i Coupe',
      '428i Gran Coupe',
      '428i xDrive Convertible',
      '428i xDrive Coupe',
      '428i xDrive Gran Coupe',
      '430i Convertible',
      '430i Coupe',
      '430i Gran Coupe',
      '430i xDrive Convertible',
      '430i xDrive Coupe',
      '430i xDrive Gran Coupe',
      '435i Convertible',
      '435i Coupe',
      '435i Gran Coupe',
      '435i xDrive Convertible',
      '435i xDrive Coupe',
      '435i xDrive Gran Coupe',
      '440i Convertible',
      '440i Coupe',
      '440i Gran Coupe',
      '440i xDrive Convertible',
      '440i xDrive Coupe',
      '440i xDrive Gran Coupe',
      '5 Series',
      '525i',
      '525i Sport Wagon',
      '525i Touring',
      '525xi',
      '528i',
      '528i Sport Wagon',
      '528i Touring',
      '528i xDrive',
      '528xi',
      '530e',
      '530e xDrive',
      '530i',
      '530i Touring',
      '530i xDrive',
      '530xi',
      '530xi Sport Wagon',
      '535d',
      '535d xDrive',
      '535i',
      '535i Gran Turismo',
      '535i Sport Wagon xDrive',
      '535i xDrive',
      '535i xDrive Gran Turismo',
      '535xi',
      '535xi Sport Wagon',
      '540d xDrive',
      '540i',
      '540i Sport Wagon',
      '540i Touring',
      '540i xDrive',
      '545i',
      '550 GT',
      '550i',
      '550i Gran Turismo',
      '550i xDrive',
      '550i xDrive GT',
      '550i xDrive Gran Turismo',
      '6 Series',
      '635csi',
      '640i Convertible',
      '640i Coupe',
      '640i Gran Coupe',
      '640i xDrive Convertible',
      '640i xDrive Coupe',
      '640i xDrive Gran Coupe',
      '640i xDrive Gran Turismo',
      '645ci',
      '645ci Convertible',
      '650ci',
      '650ci Convertible',
      '650i Convertible',
      '650i Convertible xDrive',
      '650i Coupe',
      '650i Coupe xDrive',
      '650i Gran Coupe',
      '650i xDrive Convertible',
      '650i xDrive Coupe',
      '650i xDrive Gran Coupe',
      '7 Series',
      '735i',
      '735i Luxury',
      '735il',
      '740Ld xDrive',
      '740Li',
      '740Li xDrive',
      '740e xDrive',
      '740i',
      '740i xDrive',
      '740i/740i Sport',
      '740il',
      '740il/740il Protection',
      '745e xDrive',
      '745i',
      '745li',
      '750 Series',
      '750Li',
      '750Li xDrive',
      '750i',
      '750i xDrive',
      '750il',
      '750il/750il Protection',
      '750li',
      '760Li',
      '760i',
      '760li',
      '840ci',
      '840i Convertible',
      '840i Coupe',
      '840i Gran Coupe',
      '840i xDrive Convertible',
      '840i xDrive Coupe',
      '840i xDrive Gran Coupe',
      '850ci',
      '850csi',
      '850i',
      'Active E',
      'ActiveHybrid 3',
      'ActiveHybrid 5',
      'ActiveHybrid 7',
      'ActiveHybrid 7L',
      'ActiveHybrid 7Li',
      'ActiveHybrid 7i',
      'ActiveHybrid X6',
      'Alpina B6 Gran Coupe xDrive',
      'Alpina B6 xDrive Gran Coupe',
      'Alpina B7',
      'Alpina B7 LWB',
      'Alpina B7 LWB xDrive',
      'Alpina B7 SWB',
      'Alpina B7 SWB xDrive',
      'Alpina B7 xDrive',
      'Alpina XB7',
      'I8 Coupe',
      'I8 Roadster',
      'M Coupe',
      'M Roadster',
      'M1 Coupe',
      'M2',
      'M2 CS Coupe',
      'M2 Competition',
      'M2 Competition Coupe',
      'M235i',
      'M235i Convertible',
      'M235i xDrive',
      'M235i xDrive Convertible',
      'M235i xDrive Gran Coupe',
      'M240i Convertible',
      'M240i Coupe',
      'M240i Coupe M Performance',
      'M240i xDrive Convertible',
      'M240i xDrive Coupe',
      'M240i xDrive Coupe M Performance',
      'M3',
      'M3 CS',
      'M3 Competition',
      'M3 Convertible',
      'M3 Coupe',
      'M3 Sedan',
      'M340i',
      'M340i xDrive',
      'M4 CS',
      'M4 Competition Coupe',
      'M4 Convertible',
      'M4 Convertible Competition',
      'M4 Coupe',
      'M4 Coupe Competition',
      'M4 DTM Champions Edition',
      'M4 GTS',
      'M440i xDrive Coupe',
      'M5',
      'M5 Competition',
      'M550i xDrive',
      'M6',
      'M6 Convertible',
      'M6 Coupe',
      'M6 Gran Coupe',
      'M760i xDrive',
      'M8 Competition Convertible',
      'M8 Competition Coupe',
      'M8 Competition Gran Coupe',
      'M8 Convertible',
      'M8 Coupe',
      'M8 Gran Coupe',
      'M850i xDrive Convertible',
      'M850i xDrive Coupe',
      'M850i xDrive Gran Coupe',
      'X1 sDrive28i',
      'X1 xDrive28i',
      'X1 xDrive35i',
      'X2 M35i',
      'X2 sDrive28i',
      'X2 xDrive28i',
      'X3',
      'X3 3.0i',
      'X3 3.0si',
      'X3 M',
      'X3 M Competition',
      'X3 M40i',
      'X3 sDrive 28i',
      'X3 sDrive28i',
      'X3 sDrive30i',
      'X3 xDrive28d',
      'X3 xDrive28i',
      'X3 xDrive30e',
      'X3 xDrive30i',
      'X3 xDrive35i',
      'X4 M',
      'X4 M Competition',
      'X4 M40i',
      'X4 xDrive28i',
      'X4 xDrive30i',
      'X4 xDrive35i',
      'X5',
      'X5 3.0si',
      'X5 4.6is',
      'X5 4.8IS',
      'X5 4.8i',
      'X5 4.8is',
      'X5 M',
      'X5 M Competition',
      'X5 M50i',
      'X5 sDrive35i',
      'X5 sDrive40i',
      'X5 xDrive 35d',
      'X5 xDrive30i',
      'X5 xDrive35d',
      'X5 xDrive35i',
      'X5 xDrive40e',
      'X5 xDrive40i',
      'X5 xDrive45e',
      'X5 xDrive48i',
      'X5 xDrive50i',
      'X5 xDriveM',
      'X6 M',
      'X6 M Competition',
      'X6 M50i',
      'X6 sDrive35i',
      'X6 sDrive40i',
      'X6 xDrive35i',
      'X6 xDrive40i',
      'X6 xDrive50i',
      'X6 xDriveM',
      'X7 M50i',
      'X7 xDrive40i',
      'X7 xDrive50i',
      'Z3 Coupe',
      'Z3 Roadster',
      'Z4 3.0i',
      'Z4 3.0si',
      'Z4 3.0si Coupe',
      'Z4 Coupe',
      'Z4 M Coupe',
      'Z4 M Roadster',
      'Z4 M40i',
      'Z4 Roadster',
      'Z4 sDrive28i',
      'Z4 sDrive30i',
      'Z4 sDrive35i',
      'Z4 sDrive35is',
      'Z8',
      'i3',
      'i3 (94Ah)',
      'i3 (94Ah) with Range Extender',
      'i3 BEV',
      'i3 BEV (60  Amp-hour battery)',
      'i3 BEV (94 Amp-hour battery)',
      'i3 REX',
      'i3 REX  (94 Amp-hour battery)',
      'i3 with Range Extender',
      'i3s',
      'i3s (94Ah)',
      'i3s (94Ah) with Range Extender',
      'i3s with Range Extender',
      'i8',
    ],
  },
  {
    'brand': 'BMW Alpina',
    'models': [
      'B7',
      'Roadster V8',
    ],
  },
  {
    'brand': 'BYD',
    'models': [
      'e6',
    ],
  },
  {
    'brand': 'Bentley',
    'models': [
      'Arnage',
      'Arnage LWB',
      'Arnage RL',
      'Azure',
      'Bentayga',
      'Brooklands',
      'Brooklands R',
      'Brooklands R Limo',
      'Continental Flying Spur',
      'Continental GT',
      'Continental GT Convertible',
      'Continental GT Speed Convertible',
      'Continental GT3-R',
      'Continental GTC',
      'Continental R',
      'Continental SC',
      'Continental Supersports',
      'Continental Supersports Convertible',
      'Continental T',
      'Flying Spur',
      'Mulsanne',
      'Mulsanne EWB',
      'Turbo RT',
    ],
  },
  {
    'brand': 'Bertone',
    'models': [
      'X1/9',
    ],
  },
  {
    'brand': 'Bill Dovell Motor Car Company',
    'models': [
      'Dovell 230CE',
      'Dovell 230E',
    ],
  },
  {
    'brand': 'Bitter Gmbh and Co. Kg',
    'models': [
      'Bitter SC',
      'SC',
    ],
  },
  {
    'brand': 'Bugatti',
    'models': [
      'Chiron',
      'Chiron Pur Sport',
      'Divo',
      'Veyron',
    ],
  },
  {
    'brand': 'Buick',
    'models': [
      'Cascada',
      'Century',
      'Century Estate Wagon',
      'Century Wagon',
      'Coachbuilder Wagon',
      'Electra Estate Wagon',
      'Electra/Park Avenue',
      'Enclave AWD',
      'Enclave FWD',
      'Encore',
      'Encore AWD',
      'Encore FWD',
      'Encore GX AWD',
      'Encore GX FWD',
      'Envision AWD',
      'Envision FWD',
      'Estate Wagon',
      'Funeral Coach/Hearse',
      'LaCrosse',
      'LaCrosse AWD',
      'LaCrosse eAssist',
      'Lacrosse/Allure',
      'Lacrosse/Allure AWD',
      'LeSabre',
      'LeSabre/Electra Wagon',
      'Lucerne',
      'Park Avenue',
      'Rainier 2WD',
      'Rainier AWD',
      'Reatta',
      'Regal',
      'Regal AWD',
      'Regal TourX AWD',
      'Regal eAssist',
      'Regal/Century',
      'Rendezvous AWD',
      'Rendezvous FWD',
      'Riviera',
      'Riviera Convertible',
      'Roadmaster',
      'Roadmaster Wagon',
      'Skyhawk',
      'Skyhawk Wagon',
      'Skylark',
      'Somerset Regal',
      'Somerset/Skylark',
      'Terraza AWD',
      'Terraza FWD',
      'Verano',
    ],
  },
  {
    'brand': 'CCC Engineering',
    'models': [
      'Duntov GT',
    ],
  },
  {
    'brand': 'CODA Automotive',
    'models': [
      'CODA',
    ],
  },
  {
    'brand': 'CX Automotive',
    'models': [
      'CX 25 GTI',
      'CX 25 Prestige',
      'CX 25Tri',
      'Cxestate',
      'XM v6',
      'XM v6 Break',
      'XM v6a',
    ],
  },
  {
    'brand': 'Cadillac',
    'models': [
      'ATS',
      'ATS AWD',
      'ATS-V',
      'Allante',
      'Armored DTS',
      'Armored Deville',
      'Brougham',
      'Brougham/DeVille (RWD)',
      'CT4',
      'CT4 AWD',
      'CT4 V',
      'CT4 V AWD',
      'CT5',
      'CT5 AWD',
      'CT5 V',
      'CT5 V AWD',
      'CT6',
      'CT6 AWD',
      'CT6 Plug-In',
      'CTS',
      'CTS AWD',
      'CTS Sedan',
      'CTS Sedan AWD',
      'CTS V',
      'CTS Wagon',
      'CTS Wagon AWD',
      'CTS-V',
      'Catera',
      'Cimarron',
      'Commercial Chassis',
      'DTS',
      'DeVille',
      'DeVille/60 Special',
      'DeVille/Concourse',
      'ELR',
      'ELR Sport',
      'Eldorado',
      'Eldorado Convertible',
      'Escalade 2WD',
      'Escalade 4WD',
      'Escalade AWD',
      'Escalade ESV 2WD',
      'Escalade ESV 4WD',
      'Escalade ESV AWD',
      'Escalade Ext AWD',
      'Escalade Hybrid 2WD',
      'Escalade Hybrid 4WD',
      'Fleetwood',
      'Fleetwood Brougham',
      'Fleetwood Brougham (RWD)',
      'Fleetwood/DeVille',
      'Fleetwood/DeVille (FWD)',
      'Funeral Coach / Hearse',
      'Funeral Coach/Hearse',
      'Limousine',
      'SRX',
      'SRX 2WD',
      'SRX AWD',
      'STS',
      'STS 2WD',
      'STS AWD',
      'STS-V',
      'Seville',
      'XLR',
      'XLR-V',
      'XT4 AWD',
      'XT4 FWD',
      'XT5',
      'XT5 AWD',
      'XT5 FWD',
      'XT5 Hearse AWD',
      'XT5 Hearse FWD',
      'XT5 Limo AWD',
      'XT5 Limo FWD',
      'XT6 AWD',
      'XT6 FWD',
      'XTS',
      'XTS AWD',
      'XTS Hearse',
      'XTS Limo',
    ],
  },
  {
    'brand': 'Chevrolet',
    'models': [
      'Astro 2WD (cargo)',
      'Astro 2WD (cargo) Conversion',
      'Astro 2WD (passenger)',
      'Astro AWD (cargo)',
      'Astro AWD (cargo) Conversion',
      'Astro AWD (passenger)',
      'Avalanche 1500 2WD',
      'Avalanche 1500 4WD',
      'Avalanche 1500 AWD',
      'Aveo',
      'Aveo 5',
      'Beretta',
      'Blazer 1500 4WD',
      'Blazer 2WD',
      'Blazer 4WD',
      'Blazer AWD',
      'Blazer FWD',
      'Blazer V1500 4WD',
      'Bolt EV',
      'C10 Pickup 2WD',
      'C1500 Pickup 2WD',
      'C20 Pickup 2WD',
      'C2500 Pickup 2WD',
      'Camaro',
      'Caprice',
      'Caprice Wagon',
      'Caprice/Impala',
      'Caprice/Impala Wagon',
      'Captiva AWD',
      'Captiva FWD',
      'Cavalier',
      'Cavalier (Bi-fuel CNG)',
      'Cavalier Convertible',
      'Cavalier Dual-fuel',
      'Cavalier Wagon',
      'Celebrity',
      'Celebrity Wagon',
      'Chevette',
      'Chevette CS',
      'Citation II',
      'City Express Cargo Van',
      'Classic',
      'Coachbuilder Wagon',
      'Cobalt',
      'Cobalt Coupe',
      'Cobalt SS Coupe',
      'Cobalt Sedan',
      'Cobalt XFE',
      'Cobalt XFE Coupe',
      'Cobalt XFE Sedan',
      'Colorado 2WD',
      'Colorado 4WD',
      'Colorado Cab Chassis 2WD',
      'Colorado Cab Chassis inc 2WD',
      'Colorado Cab Chassis inc 4WD',
      'Colorado Crew Cab 2WD',
      'Colorado Crew Cab 4WD',
      'Colorado ZR2 4WD',
      'Corsica',
      'Corvette',
      'Corvette Convertible',
      'Corvette Z06',
      'Corvette ZR1',
      'Cruze',
      'Cruze Eco',
      'Cruze Hatchback',
      'Cruze Limited',
      'Cruze Limited  Eco',
      'Cruze Premier',
      'Cruze Premier Hatchback',
      'El Camino Pickup 2WD',
      'Epica',
      'Equinox AWD',
      'Equinox FWD',
      'Express 1500 2WD Cargo',
      'Express 1500 2WD Conversion Cargo',
      'Express 1500 2WD Passenger',
      'Express 1500 AWD',
      'Express 1500 AWD Cargo',
      'Express 1500 AWD Conversion Cargo',
      'Express 1500 AWD Passenger',
      'Express 1500/2500 2WD',
      'Express 2500 2WD Cargo',
      'Express 2500 2WD Conversion Cargo',
      'Express 2500 2WD Passenger',
      'Express 3500 2WD Cargo',
      'Express 3500 2WD Passenger',
      'Express Cargo (Bi-fuel)',
      'Express Cargo (dedicated CNG)',
      'Express Passenger (Bi-fuel)',
      'Express Passenger (dedicated CNG)',
      'G10/20 Sport Van 2WD',
      'G10/20 Van 2WD',
      'G10/20 Van 2WD (cargo)',
      'G30 Sport Van 2WD',
      'G30 Van 2WD',
      'G30 Van 2WD (cargo)',
      'HHR FWD',
      'HHR Panel FWD',
      'Impala',
      'Impala Bi-Fuel  (CNG)',
      'Impala Limited',
      'Impala Police',
      'Impala eAssist',
      'Impala/Caprice',
      'K10 Blazer 4WD',
      'K10 Pickup 4WD',
      'K1500 Pickup 4WD',
      'K20 Pickup 4WD',
      'K2500 Pickup 4WD',
      'K5/K10 Blazer 4WD',
      'Lumina',
      'Lumina Minivan 2WD',
      'Lumina/APV Minivan 2WD',
      'Lumina/Monte Carlo',
      'Malibu',
      'Malibu Hybrid',
      'Malibu Limited',
      'Malibu Maxx',
      'Malibu eAssist',
      'Metro',
      'Monte Carlo',
      'Nova',
      'Optra',
      'Optra 5',
      'Optra Wagon',
      'Pickup 2500 2WD',
      'Pickup 2500 4WD',
      'Postal Cab Chassis 2WD',
      'Prizm',
      'R10 Pickup 2WD',
      'R10 Suburban 2WD',
      'R1500 Suburban 2WD',
      'R20 (C20) Pickup 2WD',
      'S10 Blazer 2WD',
      'S10 Blazer 4WD',
      'S10 Cab Chassis 2WD',
      'S10 Electric',
      'S10 Pickup 2WD',
      'S10 Pickup 2WD FFV',
      'S10 Pickup 4WD',
      'S10 Utility Body 2WD',
      'SS',
      'SSR Pickup 2WD',
      'Silverado 15 Hybrid 2WD',
      'Silverado 15 Hybrid 4WD',
      'Silverado 1500 2WD',
      'Silverado 1500 4WD',
      'Silverado 1500 AWD',
      'Silverado 2500 2WD',
      'Silverado 2500 2WD (Bifuel)',
      'Silverado 2500 HD 2WD',
      'Silverado 2500 HD 2WD CNG',
      'Silverado 2500 HD 4WD CNG',
      'Silverado 2WD',
      'Silverado 4WD',
      'Silverado 4WD TrailBoss',
      'Silverado C10 2WD',
      'Silverado C10 Cab Chassis 2WD',
      'Silverado C10 XFE 2WD',
      'Silverado C15 2WD',
      'Silverado C15 Cab Chassis 2WD',
      'Silverado C15 XFE 2WD',
      'Silverado Cab Chassis 2WD',
      'Silverado Cab Chassis 4WD',
      'Silverado Classic 15 Hybrid 2WD',
      'Silverado Classic 15 Hybrid 4WD',
      'Silverado Classic 1500 2WD',
      'Silverado Classic 1500 4WD',
      'Silverado K10 4WD',
      'Silverado K10 4WD TrailBoss',
      'Silverado K10 Cab Chassis 4WD',
      'Silverado K15 4WD',
      'Silverado K15 Cab Chassis 4WD',
      'Silverado LD C15 2WD',
      'Silverado LD K15 4WD',
      'Silverado SS 1500 AWD',
      'Sonic',
      'Sonic 5',
      'Sonic 5 RS',
      'Sonic RS',
      'Spark',
      'Spark ACTIV',
      'Spark EV',
      'Spectrum',
      'Spectrum Turbo',
      'Sport Van G10/20 2WD',
      'Sport Van G10/20 2WD (passenger)',
      'Sport Van G30  2WD',
      'Sport Van G30 2WD',
      'Sport Van G30 2WD (cargo)',
      'Sprint',
      'Sprint ER',
      'Sprint Metro',
      'Sprint Plus',
      'Suburban 1500 2WD',
      'Suburban 1500 4WD',
      'Suburban 1500 AWD',
      'Suburban 2500 2WD',
      'Suburban 2500 4WD',
      'Suburban 2WD',
      'Suburban 4WD',
      'Suburban C10 2WD',
      'Suburban C1500 2WD',
      'Suburban K10 4WD',
      'Suburban K1500 4WD',
      'Suburban V1500 4WD',
      'T10 (S10) Blazer 4WD',
      'T10 (S10) Pickup 4WD',
      'Tahoe 1500 2WD',
      'Tahoe 1500 4WD',
      'Tahoe 1500 AWD',
      'Tahoe 1500 XFE 2WD',
      'Tahoe 2WD',
      'Tahoe 4WD',
      'Tahoe C10 2WD',
      'Tahoe C1500 2WD',
      'Tahoe Hybrid 2WD',
      'Tahoe Hybrid 4WD',
      'Tahoe K10 4WD',
      'Tahoe K1500 4WD',
      'Tracker 2WD Convertible',
      'Tracker 2WD Hardtop',
      'Tracker 4WD Convertible',
      'Tracker 4WD Hardtop',
      'Tracker LT 2WD',
      'Tracker LT 4WD',
      'Tracker ZR2 4WD',
      'Tracker ZR2 4WD Convertible',
      'TrailBlazer 2WD',
      'TrailBlazer 4WD',
      'TrailBlazer AWD',
      'TrailBlazer Ext 2WD',
      'TrailBlazer Ext 4WD',
      'Trailblazer AWD',
      'Trailblazer FWD',
      'Traverse AWD',
      'Traverse FWD',
      'Trax',
      'Trax AWD',
      'Trax FWD',
      'Turbo Sprint',
      'Twin-Turbo Corvette',
      'Uplander AWD',
      'Uplander FWD',
      'V10 (K10) Blazer 4WD',
      'V10 (K10) Pickup 4WD',
      'V10 (K10) Suburban 4WD',
      'V10 Blazer 4WD',
      'V10 Suburban 4WD',
      'V20 Pickup 4WD',
      'Van 15/25  2WD Conversion',
      'Van 15/25 2WD Conversion',
      'Van 1500 2WD Cargo',
      'Van 1500 2WD Conversion Cargo',
      'Van 1500 AWD Cargo',
      'Van 1500 AWD Conversion',
      'Van 1500 AWD Conversion Cargo',
      'Van 1500/2500 2WD',
      'Van 1500/2500 AWD',
      'Venture 2WD',
      'Venture AWD',
      'Venture FWD',
      'Volt',
    ],
  },
  {
    'brand': 'Chrysler',
    'models': [
      '200',
      '200 AWD',
      '200 Convertible',
      '300',
      '300 AWD',
      '300 M',
      '300 SRT8',
      '300/SRT-8',
      '300C AWD',
      '300C/SRT-8',
      'Aspen 2WD',
      'Aspen 4WD',
      'Aspen HEV',
      'Cirrus',
      'Concorde',
      'Concorde/LHS',
      'Conquest',
      'Crossfire',
      'Crossfire Coupe',
      'Crossfire Roadster',
      'E Class/New Yorker',
      'Executive Sedan/Limousine',
      'Fifth Avenue/Imperial',
      'Imperial/New Yorker Fifth Avenue',
      'JX/JXI/Limited Convertible',
      'LHS',
      'Laser',
      'Laser/Daytona',
      'LeBaron',
      'LeBaron Convertible',
      'LeBaron GTS',
      'LeBaron Landau',
      'Limousine',
      'New Yorker',
      'New Yorker Fifth Avenue/Imperial',
      'New Yorker Turbo',
      'New Yorker/5th Avenue',
      'New Yorker/LHS',
      'Newport/Fifth Avenue',
      'PT Cruiser',
      'PT Cruiser Convertible',
      'Pacifica',
      'Pacifica 2WD',
      'Pacifica AWD',
      'Pacifica FWD',
      'Pacifica Hybrid',
      'Prowler',
      'QC Car',
      'Sebring',
      'Sebring 4 Door',
      'Sebring AWD',
      'Sebring Convertible',
      'TC By',
      'TC By Convertible',
      'TC by Maserati',
      'Town and Country',
      'Town and Country 2WD',
      'Town and Country 4WD',
      'Town and Country AWD',
      'Town and Country Wagon',
      'Town and Country/Voyager/Grand Voy. 2WD',
      'Town and Country/Voyager/Grand Voy. AWD',
      'Voyager',
      'Voyager/Town and Country 2WD',
    ],
  },
  {
    'brand': 'Consulier Industries Inc',
    'models': [
      'Consulier GTP',
    ],
  },
  {
    'brand': 'Dabryan Coach Builders Inc',
    'models': [
      'WB',
    ],
  },
  {
    'brand': 'Dacia',
    'models': [
      'Coupe',
      'Sedan',
      'Station Wagon',
    ],
  },
  {
    'brand': 'Daewoo',
    'models': [
      'Kalos',
      'Lacetti',
      'Lanos',
      'Leganza',
      'Magnus',
      'Nubira',
      'Nubira Station Wagon',
      'Nubira Wagon',
    ],
  },
  {
    'brand': 'Daihatsu',
    'models': [
      'Charade',
      'Charade E',
      'Rocky 4WD',
    ],
  },
  {
    'brand': 'Dodge',
    'models': [
      '600',
      '600 Convertible',
      'AD100/AD150 Ramcharger 2WD',
      'AW100/AW150 Ramcharger 4WD',
      'Aries',
      'Aries Wagon',
      'Avenger',
      'Avenger AWD',
      'B150/B250 Van 2WD',
      'B150/B250 Wagon 2WD',
      'B1500 Van 2WD',
      'B1500 Wagon 2WD',
      'B1500/B2500 Van 2WD',
      'B1500/B2500 Wagon 2WD',
      'B2500 Van 2WD',
      'B2500 Wagon 2WD',
      'B350 Van 2WD',
      'B350 Wagon 2WD',
      'B3500 Van 2WD',
      'B3500 Wagon 2WD',
      'CSX',
      'Caliber',
      'Caliber AWD',
      'Caravan C/V/Grand Caravan 2WD',
      'Caravan C/V/Grand Caravan 4WD',
      'Caravan/Grand Caravan 2WD',
      'Caravan/Grand Caravan 4WD',
      'Caravan/Grand Caravan AWD',
      'Caravan/Grand Caravan FWD',
      'Caravan/Grand Caravan/Ram Van 2WD',
      'Caravan/Ram Van 2WD',
      'Challenger',
      'Challenger AWD',
      'Challenger GT',
      'Challenger SRT',
      'Challenger SRT Widebody',
      'Challenger SRT8',
      'Challenger Widebody',
      'Charger',
      'Charger AWD',
      'Charger GLH-S',
      'Charger SRT',
      'Charger SRT Widebody',
      'Charger SRT8',
      'Charger Widebody',
      'Colt',
      'Colt Vista',
      'Colt Vista 4WD',
      'Colt Wagon',
      'Colt Wagon 4WD',
      'Conquest',
      'D100/D150 Pickup 2WD',
      'D250 Cab Chassis 2WD',
      'D250 Pickup 2WD',
      'D250 Pickup Cab Chassis 2WD',
      'Dakota Cab Chassis 2WD',
      'Dakota Pickup 2WD',
      'Dakota Pickup 4WD',
      'Dart',
      'Dart Aero',
      'Dart GT',
      'Daytona',
      'Diplomat',
      'Durango 2WD',
      'Durango 4WD',
      'Durango AWD',
      'Durango HEV',
      'Durango RWD',
      'Durango SRT AWD',
      'Dynasty',
      'GLH-S',
      'Grand Caravan',
      'Intrepid',
      'Journey',
      'Journey  2WD',
      'Journey  4WD',
      'Journey  AWD',
      'Journey AWD',
      'Journey FWD',
      'Lancer',
      'Lancer Convertible',
      'Magnum',
      'Magnum 2WD',
      'Magnum AWD',
      'Monaco',
      'Neon',
      'Neon/SRT-4/SX 2.0',
      'Nitro 2WD',
      'Nitro 4WD',
      'Omni',
      'Power Ram 50 4WD',
      'Power Ram 50 Pickup 4WD',
      'Power Ram50 4WD',
      'Raider',
      'Raider 4WD',
      'Ram 1500 Pickup 2WD',
      'Ram 1500 Pickup 4WD',
      'Ram 2500 Pickup 2WD',
      'Ram 2500 Pickup 4WD',
      'Ram 50 Pickup 2WD',
      'Ram Van 1500 2WD',
      'Ram Van 2500 2WD',
      'Ram Van 2500 2WD CNG',
      'Ram Wagon 1500 2WD',
      'Ram Wagon 2500 2WD',
      'Ram Wagon 2500 2WD CNG',
      'Ramcharger 2WD',
      'Ramcharger 4WD',
      'Rampage Pickup 2WD',
      'Shadow',
      'Shadow Convertible',
      'Spirit',
      'Stealth',
      'Stratus',
      'Stratus 4 Door',
      'Viper',
      'Viper Convertible',
      'Viper Coupe',
      'Viper SRT',
      'W100/W150 Pickup 4WD',
      'W250 Pickup 4WD',
    ],
  },
  {
    'brand': 'E. P. Dutton, Inc.',
    'models': [
      'Funeral Coach',
    ],
  },
  {
    'brand': 'Eagle',
    'models': [
      'Medallion Sedan',
      'Medallion Wagon',
      'Premier',
      'Renault Medallion Sedan',
      'Renault Medallion Wagon',
      'Summit',
      'Summit Wagon',
      'Talon',
      'Vision',
      'Wagon 4WD',
    ],
  },
  {
    'brand': 'Environmental Rsch and Devp Corp',
    'models': [
      'ERD 1',
    ],
  },
  {
    'brand': 'Evans Automobiles',
    'models': [
      'Series 1',
      'Series I',
    ],
  },
  {
    'brand': 'Excalibur Autos',
    'models': [
      'Phaeton',
    ],
  },
  {
    'brand': 'Federal Coach',
    'models': [
      '24E',
      '85J',
      'Eagle',
      'Formal',
      'Heritage',
      'Lincoln 100J',
      'Lincoln 24E',
      'Lincoln 85J',
      'Lincoln Eagle/Windsor',
      'Lincoln Eaton',
      'Lincoln Stratford',
      'Renaissance',
      'Six Door',
      'Windsor',
    ],
  },
  {
    'brand': 'Ferrari',
    'models': [
      '3.2 Mondial/Cabriolet',
      '308',
      '328 GTS/GTB',
      '348 TB/TS',
      '360 Modena/Modena F1',
      '360 Modena/Spider',
      '360 Modena/Spider/Challenge',
      '456 MGT/MGA',
      '458 Italia',
      '458 Italia Coupe',
      '458 Italia Spider',
      '458 Speciale',
      '458 Speciale Spider',
      '458 Spider',
      '488 GTB',
      '488 Pista',
      '488 Pista Spider',
      '488 Spider',
      '550 Maranello',
      '550 Maranello/Barchetta',
      '575',
      '575 M Maranello',
      '575 M Maranello and Superamerica',
      '599 GTB',
      '599 GTB Fiorano',
      '599 GTO',
      '599 SA Aperta',
      '612 Scaglietti',
      '812 GTS',
      '812 Superfast',
      'California',
      'California T',
      'Enzo Ferrari',
      'F 40',
      'F12',
      'F12 Berlinetta',
      'F12 tdf',
      'F141',
      'F355/355 F1',
      'F40',
      'F430',
      'F60 America',
      'F8 Spider',
      'F8 Tributo',
      'FF',
      'Ferrari 348 TB/TS',
      'Ferrari 348 TB/TS/Spider',
      'Ferrari 456',
      'Ferrari 456 GT',
      'Ferrari 456 GT/GTA',
      'Ferrari 512 TR',
      'Ferrari 550 Maranello',
      'Ferrari F355',
      'Ferrari F355 Berlinetta/GTS',
      'Ferrari F355/355 F1',
      'Ferrari F50',
      'Ferrari F512M',
      'Ferrari Mondial T/Cabriolet',
      'GTC4Lusso',
      'GTC4Lusso T',
      'LaFerrari Aperta',
      'LaFerrari Hybrid',
      'Mondial',
      'Mondial T/Cabriolet',
      'Mondial/Cabriolet',
      'Portofino',
      'Portofino M',
      'Roma',
      'SF90 Stradale Coupe',
      'Testarossa',
    ],
  },
  {
    'brand': 'Fiat',
    'models': [
      '124 Spider',
      '500',
      '500 Abarth',
      '500 Cabrio',
      '500 L',
      '500 X',
      '500 X AWD',
      '500X AWD',
      '500e',
    ],
  },
  {
    'brand': 'Fisker',
    'models': [
      'Karma',
    ],
  },
  {
    'brand': 'Ford',
    'models': [
      'Aerostar Van',
      'Aerostar Van AWD',
      'Aerostar Wagon',
      'Aerostar Wagon AWD',
      'Aspire',
      'Bronco 4WD',
      'Bronco II 2WD',
      'Bronco II 4WD',
      'Bronco Sport 4WD',
      'C-MAX Energi Plug-In Hybrid',
      'C-MAX Energi Plug-in Hybrid',
      'C-MAX Hybrid FWD',
      'Contour',
      'Courier',
      'Courier Pickup 2WD',
      'Courier Pickup 4WD',
      'Crown Victoria',
      'Crown Victoria CNG',
      'Crown Victoria FFV',
      'Crown Victoria Police',
      'E150 Club Wagon',
      'E150 Club Wagon 2WD',
      'E150 Econoline 2WD',
      'E150 Van FFV',
      'E150 Wagon FFV',
      'E250 CNG',
      'E250 Econoline 2WD',
      'E250 Econoline 2WD CNG',
      'E250 Van FFV',
      'E350 Van',
      'E350 Van FFV',
      'E350 Wagon',
      'E350 Wagon FFV',
      'EXP',
      'EcoSport AWD',
      'EcoSport FWD',
      'Edge AWD',
      'Edge FWD',
      'Escape 2WD',
      'Escape 4WD',
      'Escape 4WD FFV',
      'Escape AWD',
      'Escape AWD FFV',
      'Escape AWD HEV',
      'Escape FWD',
      'Escape FWD FFV',
      'Escape FWD HEV',
      'Escape FWD PHEV',
      'Escape Hybrid 2WD',
      'Escape Hybrid 4WD',
      'Escape Hybrid AWD',
      'Escape Hybrid FWD',
      'Escort',
      'Escort FS',
      'Escort Wagon',
      'Escort ZX2',
      'Expedition 2WD',
      'Expedition 2WD FFV',
      'Expedition 4WD',
      'Expedition 4WD FFV',
      'Expedition EL 2WD',
      'Expedition EL 4WD',
      'Expedition Limo. 2WD FFV',
      'Expedition MAX 2WD',
      'Expedition MAX 4WD',
      'Explorer 2WD',
      'Explorer 2WD FFV',
      'Explorer 4WD',
      'Explorer 4WD FFV',
      'Explorer AWD',
      'Explorer AWD FFV',
      'Explorer FFV AWD',
      'Explorer FWD',
      'Explorer HEV AWD',
      'Explorer HEV RWD',
      'Explorer RWD',
      'Explorer Sport 2WD',
      'Explorer Sport 2WD FFV',
      'Explorer Sport 4WD',
      'Explorer Sport 4WD FFV',
      'Explorer Sport Trac 2WD',
      'Explorer Sport Trac 2WD FFV',
      'Explorer Sport Trac 4WD',
      'Explorer Sport Trac 4WD FFV',
      'Explorer USPS 2WD',
      'Explorer USPS 2WD FFV',
      'Explorer USPS 4WD',
      'Explorer USPS 4WD FFV',
      'Explorer USPS Electric',
      'F150 2.7L 2WD GVWR>6649 LBS',
      'F150 2.7L 2WD GVWR>6649 LBS PAYLOAD PACKAGE',
      'F150 2.7L 4WD GVWR>6799 LBS',
      'F150 2.7L 4WD GVWR>6799 LBS PAYLOAD PACKAGE',
      'F150 2WD BASE PAYLOAD LT TIRE',
      'F150 2WD FFV BASE PAYLOAD LT',
      'F150 2WD FFV BASE PAYLOAD LT TIRE',
      'F150 3.5L 2WD GVWR>7599 LBS',
      'F150 3.5L 2WD GVWR>7599 LBS PAYLOAD PACKAGE',
      'F150 3.5L 4WD GVWR>7599 LBS',
      'F150 3.5L 4WD GVWR>7599 LBS PAYLOAD PACKAGE',
      'F150 4WD BASE PAYLOAD LT TIRE',
      'F150 4WD FFV BASE PAYLOAD LT',
      'F150 4WD FFV BASE PAYLOAD LT TIRE',
      'F150 5.0L 2WD FFV GVWR>7599 LBS',
      'F150 5.0L 2WD FFV GVWR>7599 LBS PAYLOAD PACKAGE',
      'F150 5.0L 2WD GVWR>7599 LBS',
      'F150 5.0L 4WD FFV GVWR>7599 LBS',
      'F150 5.0L 4WD FFV GVWR>7599 LBS PAYLOAD PACKAGE',
      'F150 5.0L 4WD GVWR>7599 LBS',
      'F150 CNG',
      'F150 Dual-fuel 2WD (CNG)',
      'F150 Dual-fuel 2WD (LPG)',
      'F150 Dual-fuel 4WD (CNG)',
      'F150 Dual-fuel 4WD (LPG)',
      'F150 Pickup 2WD',
      'F150 Pickup 2WD CNG',
      'F150 Pickup 2WD FFV',
      'F150 Pickup 2WD HEV',
      'F150 Pickup 2WD Limited',
      'F150 Pickup 4WD',
      'F150 Pickup 4WD FFV',
      'F150 Pickup 4WD HEV',
      'F150 Pickup 4WD Limited',
      'F150 Pickup 4WD XL/XLT',
      'F150 RAPTOR 4WD',
      'F150 Raptor Pickup 4WD',
      'F150 SFE 2WD',
      'F150 STX SE 2WD',
      'F150 STX SE 2WD FFV',
      'F250',
      'F250 Pickup 2WD',
      'F250 Pickup 2WD CNG',
      'F250 Pickup 4WD',
      'F250 Pickup Cab Chassis 2WD',
      'Festiva',
      'Fiesta',
      'Fiesta FWD',
      'Fiesta SFE',
      'Fiesta SFE FWD',
      'Fiesta ST FWD',
      'Five Hundred AWD',
      'Five Hundred FWD',
      'Flex AWD',
      'Flex FWD',
      'Focus',
      'Focus Electric',
      'Focus FWD',
      'Focus FWD FFV',
      'Focus RS AWD',
      'Focus SFE FWD',
      'Focus SFE FWD FFV',
      'Focus ST FWD',
      'Focus Station Wagon',
      'Freestar Cargo Van FWD',
      'Freestar Wagon FWD',
      'Freestyle AWD',
      'Freestyle FWD',
      'Fusion',
      'Fusion AWD',
      'Fusion AWD FFV',
      'Fusion Energi Plug-in Hybrid',
      'Fusion FWD',
      'Fusion FWD FFV',
      'Fusion Hybrid FWD',
      'Fusion Hybrid Taxi',
      'Fusion S FWD',
      'Fusion Special Service PHEV',
      'Fusion Special Service Vehicle PHEV',
      'GT',
      'GT 2WD',
      'LTD',
      'LTD Crown Victoria',
      'LTD Crown Victoria Wagon',
      'LTD Crown Victoria/Country Squire Wagon',
      'LTD Wagon',
      'Laser',
      'Lightning F150 2WD',
      'Lightning Pickup 2WD',
      'Mustang',
      'Mustang Bullitt',
      'Mustang Convertible',
      'Mustang HO Convertible',
      'Mustang HO Coupe',
      'Mustang Mach 1',
      'Mustang Mach-E AWD',
      'Mustang Mach-E AWD Extended',
      'Mustang Mach-E RWD',
      'Mustang Mach-E RWD California Route 1',
      'Mustang Mach-E RWD Extended',
      'Mustang Performance Package',
      'Postal Vehicle',
      'Probe',
      'Ranger 2WD',
      'Ranger 2WD FFV',
      'Ranger 2WD Incomplete',
      'Ranger 4WD',
      'Ranger 4WD FFV',
      'Ranger Pickup 2WD',
      'Ranger Pickup 4WD',
      'Ranger Pickup Cab Chassis',
      'Ranger Pickup Cab Chassis 2WD',
      'Ranger Tremor 4WD',
      'Shelby GT350 Mustang',
      'Shelby GT500 Mustang',
      'Taurus',
      'Taurus AWD',
      'Taurus AWD FFV',
      'Taurus FFV',
      'Taurus FWD',
      'Taurus FWD FFV',
      'Taurus SHO',
      'Taurus Wagon',
      'Taurus Wagon 3.0 A/C',
      'Taurus Wagon FFV',
      'Taurus Wagon V6 A/C',
      'Taurus X AWD',
      'Taurus X FWD',
      'Tempo',
      'Tempo AWD',
      'Tempo FS',
      'Th!nk',
      'Thunderbird',
      'Transit Connect',
      'Transit Connect USPS',
      'Transit Connect Van',
      'Transit Connect Van 2WD',
      'Transit Connect Van FFV',
      'Transit Connect Van FWD',
      'Transit Connect Wagon FFV',
      'Transit Connect Wagon FWD',
      'Transit Connect Wagon LWB FFV',
      'Transit Connect Wagon LWB FWD',
      'Transit T150 Wagon',
      'Transit T150 Wagon 2WD FFV',
      'Transit T150 Wagon 4WD FFV',
      'Transit T150 Wagon FFV',
      'Windstar FWD Cargo Van',
      'Windstar FWD Van',
      'Windstar FWD Wagon',
    ],
  },
  {
    'brand': 'GMC',
    'models': [
      'Acadia AWD',
      'Acadia FWD',
      'Acadia Limited AWD',
      'Acadia Limited FWD',
      'C15 Pickup 2WD',
      'C15 Suburban 2WD',
      'C25 Pickup 2WD',
      'C2500 Sierra 2WD',
      'Caballero Pickup 2WD',
      'Canyon 2WD',
      'Canyon 4WD',
      'Canyon Cab Chassis 2WD',
      'Canyon Cab Chassis Inc 2WD',
      'Canyon Cab Chassis Inc 4WD',
      'Canyon Crew Cab 2WD',
      'Canyon Crew Cab 4WD',
      'EV1',
      'Envoy 2WD',
      'Envoy 4WD',
      'Envoy XL 2WD',
      'Envoy XL 4WD',
      'Envoy XUV 2WD',
      'Envoy XUV 4WD',
      'G15/25 Rally 2WD',
      'G15/25 Vandura 2WD',
      'G35 Rally 2WD',
      'G35 Vandura 2WD',
      'Jimmy 2WD',
      'Jimmy 4WD',
      'Jimmy AWD',
      'Jimmy Sonoma 2WD',
      'Jimmy Sonoma 4WD',
      'Jimmy V1500 4WD',
      'K15 Jimmy 4WD',
      'K15 Pickup 4WD',
      'K15 Suburban 4WD',
      'K25 Pickup 4WD',
      'K2500 Sierra 4WD',
      'R15 Pickup 2WD',
      'R15 Suburban 2WD',
      'R1500 Suburban 2WD',
      'R25 Pickup 2WD',
      'Rally G15/25 2WD',
      'Rally G15/25 2WD (passenger)',
      'Rally G35 2WD',
      'S15 Cab Chassis 2WD',
      'S15 Jimmy 2WD',
      'S15 Jimmy 4WD',
      'S15 Pickup 2WD',
      'S15 Pickup 4WD',
      'S15 Utility Body 2WD',
      'Safari 2WD (Passenger)',
      'Safari 2WD (cargo)',
      'Safari 2WD (passenger)',
      'Safari 2WD Conversion (cargo)',
      'Safari AWD (Passenger)',
      'Safari AWD (cargo)',
      'Safari AWD (passenger)',
      'Safari AWD Conversion (cargo)',
      'Savana (cargo) (Bi-fuel)',
      'Savana 15/25 2WD Conversion (cargo)',
      'Savana 15/25 AWD Conversion (cargo)',
      'Savana 1500  2WD Conversion (cargo)',
      'Savana 1500  AWD (cargo)',
      'Savana 1500  AWD Conversion (cargo)',
      'Savana 1500 2WD (Passenger)',
      'Savana 1500 2WD (cargo)',
      'Savana 1500 2WD Conversion (cargo)',
      'Savana 1500 AWD (Passenger)',
      'Savana 1500 AWD (cargo)',
      'Savana 1500 AWD Conversion (cargo)',
      'Savana 1500/2500  2WD (Passenger)',
      'Savana 1500/2500 2WD (Passenger)',
      'Savana 1500/2500 2WD (cargo)',
      'Savana 1500/2500 2WD (passenger)',
      'Savana 1500/2500 AWD (cargo)',
      'Savana 2500 2WD (Passenger)',
      'Savana 2500 2WD (cargo)',
      'Savana 2500 2WD Conversion (cargo)',
      'Savana 3500 2WD (Passenger)',
      'Savana 3500 2WD (cargo)',
      'Savana Cargo (dedicated CNG)',
      'Savana Passenger (Bi-fuel)',
      'Savana Passenger (dedicated CNG)',
      'Sierra 15 Hybrid 2WD',
      'Sierra 15 Hybrid 4WD',
      'Sierra 1500 2WD',
      'Sierra 1500 4WD',
      'Sierra 1500 AWD',
      'Sierra 2500 2WD',
      'Sierra 2500 4WD',
      'Sierra 2500 HD 2WD CNG',
      'Sierra 2500 HD 4WD CNG',
      'Sierra 2500 Hd 2WD',
      'Sierra 2WD',
      'Sierra 4WD',
      'Sierra 4WD AT4',
      'Sierra C10 2WD',
      'Sierra C10 Cab Chassis 2WD',
      'Sierra C10 XFE 2WD',
      'Sierra C15 2WD',
      'Sierra C15 Cab Chassis 2WD',
      'Sierra C15 XFE 2WD',
      'Sierra Cab Chassis 2WD',
      'Sierra Cab Chassis 4WD',
      'Sierra Classic 15 Hybrid 2WD',
      'Sierra Classic 15 Hybrid 4WD',
      'Sierra Classic 1500 2WD',
      'Sierra Classic 1500 4WD',
      'Sierra Classic 1500 AWD',
      'Sierra Denali 1500 AWD',
      'Sierra K10 4WD',
      'Sierra K10 4WD AT4',
      'Sierra K10 Cab Chassis 4WD',
      'Sierra K15 4WD',
      'Sierra K15 AWD',
      'Sierra K15 Cab Chassis 4WD',
      'Sierra LTD C15 2WD',
      'Sierra LTD K15 4WD',
      'Sonoma 2WD',
      'Sonoma 2WD (FFV)',
      'Sonoma 4WD',
      'Suburban 1500 2WD',
      'Suburban 1500 4WD',
      'Suburban V1500 4WD',
      'T15 (S15) Jimmy 4WD',
      'T15 (S15) Pickup 4WD',
      'Terrain AWD',
      'Terrain FWD',
      'V15 Jimmy 4WD',
      'V15 Pickup 4WD',
      'V15 Suburban 4WD',
      'V25 Pickup 4WD',
      'Vandura 2500 2WD',
      'Vandura G15/25 2WD',
      'Vandura G15/25 2WD (cargo)',
      'Vandura G35  2WD',
      'Vandura G35 2WD',
      'Vandura G35 2WD (cargo)',
      'Yukon 1500 2WD',
      'Yukon 1500 4WD',
      'Yukon 1500 AWD',
      'Yukon 1500 Hybrid 2WD',
      'Yukon 1500 Hybrid 4WD',
      'Yukon 1500 XFE 2WD',
      'Yukon 2WD',
      'Yukon 4WD',
      'Yukon C10 2WD',
      'Yukon C1500 2WD',
      'Yukon C1500 XL 2WD',
      'Yukon Denali 1500 AWD',
      'Yukon Denali 1500 Hybrid 4WD',
      'Yukon Denali K10 AWD',
      'Yukon Denali XL 1500 AWD',
      'Yukon K10 4WD',
      'Yukon K1500 4WD',
      'Yukon K1500 XL 4WD',
      'Yukon XL 1500 2WD',
      'Yukon XL 1500 4WD',
      'Yukon XL 1500 AWD',
      'Yukon XL 2500 2WD',
      'Yukon XL 2500 4WD',
      'Yukon XL 2WD',
      'Yukon XL 4WD',
      'Yukon XL C10 2WD',
      'Yukon XL K10 4WD',
      'Yukon XL K10 AWD',
    ],
  },
  {
    'brand': 'General Motors',
    'models': [
      'Coachbuilder Wagon',
    ],
  },
  {
    'brand': 'Genesis',
    'models': [
      'G70 AWD',
      'G70 RWD',
      'G80 AWD',
      'G80 RWD',
      'G90 AWD',
      'G90 RWD',
      'GV80 AWD',
      'GV80 RWD',
    ],
  },
  {
    'brand': 'Geo',
    'models': [
      'Metro',
      'Metro LSI',
      'Metro LSI Convertible',
      'Metro XFI',
      'Prizm',
      'Spectrum',
      'Storm',
      'Tracker 4WD',
      'Tracker Convertible 2WD',
      'Tracker Convertible 4WD',
      'Tracker Convertible 4x4',
      'Tracker Hardtop 4WD',
      'Tracker Van 2WD',
      'Tracker Van 4WD',
      'Tracker Van 4x4',
    ],
  },
  {
    'brand': 'Goldacre',
    'models': [
      'Goldacre Limited',
    ],
  },
  {
    'brand': 'Grumman Allied Industries',
    'models': [
      'LLV',
    ],
  },
  {
    'brand': 'Grumman Olson',
    'models': [
      'Kubvan',
    ],
  },
  {
    'brand': 'Honda',
    'models': [
      'Accord',
      'Accord 2.0T Sport/Touring',
      'Accord Coupe',
      'Accord Crosstour 2WD',
      'Accord Crosstour 4WD',
      'Accord Hybrid',
      'Accord Hybrid Sport/Touring',
      'Accord Plug-in Hybrid',
      'Accord Sport/Touring',
      'Accord Wagon',
      'CR-V 2WD',
      'CR-V 4WD',
      'CR-V AWD',
      'CR-V FWD',
      'CR-V Hybrid AWD',
      'CR-Z',
      'Civic',
      'Civic 2Dr',
      'Civic 4Dr',
      'Civic 5Dr',
      'Civic 5Dr - Type R',
      'Civic 5Dr Sport',
      'Civic CNG',
      'Civic CRX',
      'Civic CRX HF',
      'Civic Del Sol',
      'Civic HB VX',
      'Civic HF',
      'Civic HX',
      'Civic Hybrid',
      'Civic Natural Gas',
      'Civic Si 2Dr',
      'Civic Si 4Dr',
      'Civic Wagon',
      'Civic Wagon 4WD',
      'Clarity EV',
      'Clarity Plug-in Hybrid',
      'Crosstour 2WD',
      'Crosstour 4WD',
      'Del Sol',
      'EV Plus',
      'Element 2WD',
      'Element 4WD',
      'Fit',
      'Fit EV',
      'HR-V 2WD',
      'HR-V 4WD',
      'HR-V AWD',
      'HR-V FWD',
      'Insight',
      'Insight Touring',
      'Odyssey',
      'Passport 2WD',
      'Passport 4WD',
      'Passport AWD',
      'Passport FWD',
      'Pilot 2WD',
      'Pilot 4WD',
      'Pilot AWD',
      'Pilot FWD',
      'Prelude',
      'Ridgeline AWD',
      'Ridgeline FWD',
      'Ridgeline Truck 4WD',
      'S2000',
    ],
  },
  {
    'brand': 'Hummer',
    'models': [
      'H3 4WD',
      'H3T 4WD',
    ],
  },
  {
    'brand': 'Hyundai',
    'models': [
      'Accent',
      'Accent (SOHC)',
      'Accent (Sporty)',
      'Accent Blue',
      'Accent/Brio',
      'Azera',
      'Azera Limited',
      'Elantra',
      'Elantra Blue',
      'Elantra Coupe',
      'Elantra GT',
      'Elantra Hatchback',
      'Elantra Hybrid',
      'Elantra Hybrid Blue',
      'Elantra Limited',
      'Elantra SE',
      'Elantra Touring',
      'Elantra Wagon',
      'Entourage',
      'Equus',
      'Excel',
      'Genesis',
      'Genesis AWD',
      'Genesis Coupe',
      'Genesis R Spec',
      'Genesis R-Spec',
      'Genesis RWD',
      'Ioniq',
      'Ioniq Blue',
      'Ioniq Electric',
      'Ioniq Plug-in Hybrid',
      'J-Car/Elantra',
      'Kona AWD',
      'Kona Electric',
      'Kona FWD',
      'Palisade AWD',
      'Palisade FWD',
      'Pony Excel',
      'Precis',
      'Santa Fe 2WD',
      'Santa Fe 4WD',
      'Santa Fe AWD',
      'Santa Fe FWD',
      'Santa Fe Hybrid',
      'Santa Fe Hybrid Blue',
      'Santa Fe Sport 2WD',
      'Santa Fe Sport 4WD',
      'Santa Fe Sport AWD',
      'Santa Fe Sport FWD',
      'Santa Fe Sport Ultimate AWD',
      'Santa Fe Sport Ultimate FWD',
      'Santa Fe Ultimate 2WD',
      'Santa Fe Ultimate 4WD',
      'Santa Fe Ultimate AWD',
      'Santa Fe Ultimate FWD',
      'Santa Fe XL AWD',
      'Santa Fe XL FWD',
      'Santa Fe XL Ultimate AWD',
      'Santa Fe XL Ultimate FWD',
      'Scoupe',
      'Sonata',
      'Sonata (Y-3)',
      'Sonata Hybrid',
      'Sonata Hybrid Blue',
      'Sonata Hybrid Limited',
      'Sonata Hybrid SE',
      'Sonata Limited',
      'Sonata Plug-in Hybrid',
      'Sonata SE',
      'Sonata Sport Limited',
      'Tiburon',
      'Tiburon (Coupe)',
      'Tucson 2WD',
      'Tucson 4WD',
      'Tucson AWD',
      'Tucson Eco AWD',
      'Tucson Eco FWD',
      'Tucson FWD',
      'Veloster',
      'Veloster N',
      'Venue',
      'Veracruz 2WD',
      'Veracruz 4WD',
      'XG300',
      'XG350',
    ],
  },
  {
    'brand': 'Import Foreign Auto Sales Inc',
    'models': [
      '1fas 410',
    ],
  },
  {
    'brand': 'Import Trade Services',
    'models': [
      'BMW 325I',
      'BMW 325i',
      'ITS 190E',
      'ITS 190E 2.0',
      'ITS 190E 2.3',
      'ITS 200TE',
      'ITS 230CE',
      'ITS 230TE',
      'ITS 320',
      'ITS 520',
      'MB 300E',
      'MB 300SL',
    ],
  },
  {
    'brand': 'Infiniti',
    'models': [
      'EX35',
      'EX35 AWD',
      'EX37',
      'EX37 AWD',
      'FX35 2WD',
      'FX35 AWD',
      'FX35 RWD',
      'FX37 AWD',
      'FX37 RWD',
      'FX45 AWD',
      'FX50',
      'FX50 AWD',
      'G20',
      'G25',
      'G25x',
      'G35',
      'G35 Coupe',
      'G35x',
      'G37',
      'G37 Convertible',
      'G37 Coupe',
      'G37x',
      'G37x Coupe',
      'I30',
      'I35',
      'J30',
      'JX35 AWD',
      'JX35 FWD',
      'M30',
      'M35',
      'M35h',
      'M35x',
      'M37',
      'M37x',
      'M45',
      'M45x',
      'M56',
      'M56x',
      'Q40',
      'Q40 AWD',
      'Q45',
      'Q45 Full-Active Suspension',
      'Q45 Sport',
      'Q50',
      'Q50 AWD',
      'Q50 Hybrid',
      'Q50 Hybrid AWD',
      'Q50 Red Sport',
      'Q50 Red Sport  AWD',
      'Q50S',
      'Q50S AWD',
      'Q50S Hybrid',
      'Q50S Hybrid AWD',
      'Q50S Red Sport',
      'Q50S Red Sport  AWD',
      'Q50a',
      'Q50a AWD',
      'Q60',
      'Q60 AWD',
      'Q60 AWD Coupe',
      'Q60 Convertible',
      'Q60 Coupe',
      'Q60 Red Sport',
      'Q60 Red Sport AWD',
      'Q60S Red Sport',
      'Q60S Red Sport AWD',
      'Q70',
      'Q70 AWD',
      'Q70 Hybrid',
      'QX30',
      'QX30 AWD',
      'QX4 2WD',
      'QX4 4WD',
      'QX50',
      'QX50 AWD',
      'QX56 2WD',
      'QX56 4WD',
      'QX60 AWD',
      'QX60 FWD',
      'QX60 Hybrid AWD',
      'QX60 Hybrid FWD',
      'QX70 AWD',
      'QX70 RWD',
      'QX80 2WD',
      'QX80 4WD',
      'QX80 4WD (over 6000 lbs curb weight)',
    ],
  },
  {
    'brand': 'Isis Imports Ltd',
    'models': [
      'Morgan Plus 8',
    ],
  },
  {
    'brand': 'Isuzu',
    'models': [
      '750C/I-Mark',
      'Amigo 2WD',
      'Amigo 4WD',
      'Ascender 2WD',
      'Ascender 4WD',
      'Ascender 5-passenger 2WD',
      'Ascender 5-passenger 4WD',
      'Ascender 7-passenger 2WD',
      'Ascender 7-passenger 4WD',
      'Axiom 2WD',
      'Axiom 4WD',
      'Hombre Pickup 2WD',
      'Hombre Pickup 2WD (FFV)',
      'Hombre Pickup 4WD',
      'I-Mark',
      'Impulse',
      'Oasis',
      'Pickup 2WD',
      'Pickup 2WD 1-Ton',
      'Pickup 4WD',
      'Rodeo 2WD',
      'Rodeo 4WD',
      'Rodeo Sport 2WD',
      'Rodeo Sport 4WD',
      'Stylus',
      'Trooper',
      'Trooper 2WD',
      'Trooper 4WD',
      'Vehicross',
      'Vehicross 4WD',
      'i-280 Extended Cab 2WD',
      'i-290 Extended Cab 2WD',
      'i-350 Crew Cab 4WD',
      'i-370 Crew Cab 2WD',
      'i-370 Crew Cab 4WD',
      'i-370 Extended Cab 2WD',
    ],
  },
  {
    'brand': 'J.K. Motors',
    'models': [
      '190E 2.3 MERC BENZ',
      '230E MERC BENZ',
      '300CE MERC BENZ',
      '300E MERC BENZ',
      '300SL',
      '300SL MERC BENZ',
      '300TE MERC BENZ',
      'BMW535I',
      'BMW635CSI',
      'BMW635L6',
      'BMW735I',
      'BMW735IL',
      'MERC BENZ 300CE',
      'MERC BENZ 300E',
      'MERC BENZ 300SE',
      'MERC BENZ 300SEL',
      'MERC BENZ 300TE',
      'MERC.BENZ 260E',
      'MERC.BENZ.300SE',
      'PORSCHE 928 S4',
      'Testarossa',
    ],
  },
  {
    'brand': 'JBA Motorcars, Inc.',
    'models': [
      'W-126/4 Series',
    ],
  },
  {
    'brand': 'Jaguar',
    'models': [
      'E-Pace',
      'E-Pace (296 Hp)',
      'E-Pace MHEV',
      'E-Pace P250',
      'E-Pace P300',
      'F-Pace',
      'F-Pace (296 Hp)',
      'F-Pace 30t',
      'F-Pace P340 MHEV',
      'F-Pace P400 MHEV',
      'F-Pace SVR',
      'F-Type AWD Convertible',
      'F-Type AWD Coupe',
      'F-Type Convertible',
      'F-Type Coupe',
      'F-Type R AWD Convertible',
      'F-Type R AWD Coupe',
      'F-Type R Convertible',
      'F-Type R Coupe',
      'F-Type S AWD Convertible',
      'F-Type S AWD Coupe',
      'F-Type S Convertible',
      'F-Type S Coupe',
      'F-Type SVR AWD Convertible',
      'F-Type SVR AWD Convertible GEN2',
      'F-Type SVR AWD Coupe',
      'F-Type SVR AWD Coupe GEN2',
      'F-Type V8 S Convertible',
      'I-Pace',
      'S-Type',
      'S-Type 3.0 Litre',
      'S-Type 4.2 Litre',
      'S-Type R',
      'S-type (X200) V6',
      'S-type (X200) V8',
      'Super V8',
      'Vanden Plas',
      'Vanden Plas S.C.',
      'Vanden Plas S/C',
      'Vdp',
      'Vdp 4.2 Litre',
      'Vdp Lwb',
      'X-Type',
      'X-Type Sport Brake',
      'XE',
      'XE (296 Hp)',
      'XE 30t',
      'XE AWD',
      'XE AWD (296 Hp)',
      'XE AWD 30t',
      'XF',
      'XF (296 Hp)',
      'XF 30t',
      'XF AWD',
      'XF AWD (296 Hp)',
      'XF AWD 30t',
      'XF FFV',
      'XF Sportbrake AWD',
      'XF Supercharged',
      'XJ',
      'XJ AWD',
      'XJ FFV',
      'XJ LWB',
      'XJ LWB AWD',
      'XJ Sport',
      'XJ-S',
      'XJ-SC',
      'XJ12',
      'XJ6',
      'XJ6L',
      'XJ8',
      'XJ8 4.2 Litre',
      'XJ8L',
      'XJ8l',
      'XJL',
      'XJL AWD',
      'XJL FFV',
      'XJR',
      'XJR 4.2 Litre',
      'XJRS Convertble',
      'XJRS Coupe',
      'XJS',
      'XJS Convertible',
      'XJS Coupe',
      'XJS V12 Convertible',
      'XJS V12 Coupe',
      'XK',
      'XK Convertible',
      'XK8',
      'XK8 Convertible',
      'XKR',
      'XKR Convertible',
    ],
  },
  {
    'brand': 'Jeep',
    'models': [
      'CJ7',
      'CJ7 4WD',
      'CJ8 4WD',
      'Cherokee 2WD',
      'Cherokee 4WD',
      'Cherokee 4WD Active Drive II',
      'Cherokee FWD',
      'Cherokee Trailhawk 4WD',
      'Cherokee/Wagoneer',
      'Cherokee/Wagoneer 2WD',
      'Cherokee/Wagoneer 4WD',
      'Comanche 2WD',
      'Comanche 4WD',
      'Comanche Pickup 2WD',
      'Comanche Pickup 4WD',
      'Commander 2WD',
      'Commander 4WD',
      'Compass 2WD',
      'Compass 4WD',
      'Compass FWD',
      'Gladiator 4WD',
      'Gladiator EcoDiesel 4WD',
      'Gladiator Pickup 4WD',
      'Gladiator Rubicon EcoDiesel 4WD',
      'Grand Cherokee 2WD',
      'Grand Cherokee 4WD',
      'Grand Cherokee SRT 4WD',
      'Grand Cherokee SRT8',
      'Grand Cherokee SRT8 4WD',
      'Grand Cherokee SRT8 AWD',
      'Grand Cherokee TrackHawk 4WD',
      'Grand Cherokee Trackhawk 4WD',
      'Grand Wagoneer',
      'Grand Wagoneer 4WD',
      'J-10 Pickup 4WD',
      'J-10 Pickup Truck',
      'J-10 Pickup Truck 4WD',
      'J-20 Pickup Truck',
      'J-20 Pickup Truck 4WD',
      'Liberty 2WD',
      'Liberty 4WD',
      'Liberty/Cherokee 2WD',
      'Liberty/Cherokee 4WD',
      'New Compass 2WD',
      'New Compass 4WD',
      'New Wrangler 4WD',
      'New Wrangler Unlimited 4WD',
      'Patriot 2WD',
      'Patriot 4WD',
      'Patriot FWD',
      'Renegade 2WD',
      'Renegade 4WD',
      'Renegade Trailhawk 4WD',
      'Scrambler',
      'Scrambler Pickup 4WD',
      'Wagoneer 4WD',
      'Wagoneer Limited 4WD',
      'Wrangler 2WD',
      'Wrangler 2dr 4WD',
      'Wrangler 4WD',
      'Wrangler 4dr 4WD',
      'Wrangler 4dr EcoDiesel 4WD',
      'Wrangler Rubic 4dr EcoDiesel 4WD',
      'Wrangler Unlimited 4WD',
      'Wrangler/TJ 4WD',
    ],
  },
  {
    'brand': 'Kandi',
    'models': [
      'K27',
    ],
  },
  {
    'brand': 'Karma',
    'models': [
      'GS-6 (21-inch wheels)',
      'GS-6 (22-inch wheels)',
      'Revero',
      'Revero GT (21-inch wheels)',
      'Revero GT (22-inch wheels)',
    ],
  },
  {
    'brand': 'Kenyon Corporation Of America',
    'models': [
      'Kenyon 5.0 Cabrio',
      'Kenyon 5.0 Coupe',
      'Kenyon 5.0 Sedan',
      'Kenyon 5.0 Sp Sedan',
    ],
  },
  {
    'brand': 'Kia',
    'models': [
      'Amanti',
      'Borrego 2WD',
      'Borrego 4WD',
      'Cadenza',
      'Carnival',
      'Forte',
      'Forte 5',
      'Forte Eco',
      'Forte FE',
      'Forte Koup',
      'K5',
      'K5 AWD',
      'K900',
      'Niro',
      'Niro Electric',
      'Niro FE',
      'Niro Plug-in Hybrid',
      'Niro Touring',
      'Optima',
      'Optima (2006 New Model)',
      'Optima FE',
      'Optima Hybrid',
      'Optima Hybrid EX',
      'Optima Plug-in Hybrid',
      'Optima S',
      'Rio',
      'Rio Eco',
      'Rondo',
      'Sedona',
      'Sedona SX',
      'Sedona SXL',
      'Seltos AWD',
      'Seltos FWD',
      'Sephia',
      'Sephia/Spectra',
      'Sorento 2WD',
      'Sorento 4WD',
      'Sorento AWD',
      'Sorento FE AWD',
      'Sorento FWD',
      'Sorento Hybrid',
      'Soul',
      'Soul ECO dynamics',
      'Soul Eco',
      'Soul Eco dynamics',
      'Soul Electric',
      'Spectra',
      'Spectra 1.8L',
      'Spectra 2.0L',
      'Sportage 2WD',
      'Sportage 4WD',
      'Sportage AWD',
      'Sportage FE AWD',
      'Sportage FE FWD',
      'Sportage FWD',
      'Sportage SX AWD',
      'Sportage SX FWD',
      'Stinger AWD',
      'Stinger RWD',
      'Telluride AWD',
      'Telluride FWD',
    ],
  },
  {
    'brand': 'Koenigsegg',
    'models': [
      'Agera RS',
    ],
  },
  {
    'brand': 'Laforza Automobile Inc',
    'models': [
      'Laforza',
    ],
  },
  {
    'brand': 'Lambda Control Systems',
    'models': [
      '300E',
    ],
  },
  {
    'brand': 'Lamborghini',
    'models': [
      'Aventador Coupe',
      'Aventador Coupe LP740-4',
      'Aventador Roadster',
      'Aventador Roadster LP740-4',
      'Aventador S Coupe',
      'Aventador S Roadster',
      'Aventador Sian Coupe',
      'Aventador Sian Roadster',
      'Aventador Veneno Coupe',
      'Countach',
      'Countach Lp500s',
      'DB132/144 - Diablo',
      'DB132/144 Diablo',
      'DB132/Diablo',
      'Gallardo Coupe',
      'Gallardo Coupe SL',
      'Gallardo Spyder',
      'Huracan',
      'Huracan 2WD',
      'Huracan Spyder',
      'Huracan Spyder 2WD',
      'L-140/141 Gallardo',
      'L-140/715 Gallardo',
      'L-147 Murcielago',
      'L-147/148 Murcielago',
      'Murcielago',
      'Murcielago Reventon',
      'Murcielago Reventon Roadster',
      'Murcielago Roadster',
      'Urus',
      'Veneno Roadster',
    ],
  },
  {
    'brand': 'Land Rover',
    'models': [
      'Defender 110',
      'Defender 110 MHEV',
      'Defender 90',
      'Defender 90 MHEV',
      'Discovery',
      'Discovery MHEV',
      'Discovery Series II',
      'Discovery Sport',
      'Discovery Sport (286 Hp)',
      'Discovery Sport MHEV',
      'Discovery Sport Si4',
      'Evoque',
      'Evoque MHEV',
      'Freelander',
      'Freelander 3 Door',
      'Freelander 5 Door',
      'LR2',
      'LR3',
      'LR4',
      'Range Rover',
      'Range Rover 4.0',
      'Range Rover 4.6',
      'Range Rover County',
      'Range Rover County Classic',
      'Range Rover County LWB',
      'Range Rover Evoque',
      'Range Rover Evoque (286 Hp)',
      'Range Rover Evoque 237HP',
      'Range Rover Evoque 286HP',
      'Range Rover Evoque Cabriolet',
      'Range Rover Evoque Convertible',
      'Range Rover FFV',
      'Range Rover L FFV',
      'Range Rover LWB',
      'Range Rover LWB SVA',
      'Range Rover MHEV',
      'Range Rover PHEV',
      'Range Rover SV Coupe',
      'Range Rover SVA',
      'Range Rover Sport',
      'Range Rover Sport FFV',
      'Range Rover Sport MHEV',
      'Range Rover Sport PHEV',
      'Range Rover Sport SVR',
      'Range Rover Sport TDV6',
      'Range Rover TDV6',
      'Range Rover Velar',
      'Range Rover Velar P300',
      'Range Rover Velar P340 MHEV',
      'Range Rover Velar P380',
      'Range Rover Velar P400 MHEV',
    ],
  },
  {
    'brand': 'Lexus',
    'models': [
      'CT 200h',
      'ES 250',
      'ES 250 AWD',
      'ES 300',
      'ES 300h',
      'ES 330',
      'ES 350',
      'ES 350 F Sport',
      'GS 200t',
      'GS 200t F Sport',
      'GS 300',
      'GS 300 4WD',
      'GS 300 F Sport',
      'GS 300/GS 400',
      'GS 300/GS 430',
      'GS 350',
      'GS 350 AWD',
      'GS 350 F Sport',
      'GS 430',
      'GS 450h',
      'GS 460',
      'GS F',
      'GS300',
      'GX 460',
      'GX 470',
      'HS 250h',
      'IS 200t',
      'IS 250',
      'IS 250 AWD',
      'IS 250 C',
      'IS 250/IS 250C',
      'IS 300',
      'IS 300 AWD',
      'IS 350',
      'IS 350 AWD',
      'IS 350 C',
      'IS 350/IS 350C',
      'IS F',
      'LC 500',
      'LC 500 Convertible',
      'LC 500h',
      'LFA',
      'LS 400',
      'LS 430',
      'LS 460',
      'LS 460 AWD',
      'LS 460 L',
      'LS 460 L AWD',
      'LS 500',
      'LS 500 AWD',
      'LS 500h',
      'LS 500h AWD',
      'LS 600h L',
      'LX 450',
      'LX 470',
      'LX 570',
      'NX 200t',
      'NX 200t AWD',
      'NX 200t AWD F Sport',
      'NX 300',
      'NX 300 AWD',
      'NX 300 AWD F Sport',
      'NX 300h',
      'NX 300h AWD',
      'RC 200t',
      'RC 300',
      'RC 300 AWD',
      'RC 350',
      'RC 350 AWD',
      'RC F',
      'RX 300',
      'RX 300 4WD',
      'RX 330 2WD',
      'RX 330 4WD',
      'RX 350',
      'RX 350 2WD',
      'RX 350 4WD',
      'RX 350 AWD',
      'RX 350 L',
      'RX 350 L AWD',
      'RX 400h 2WD',
      'RX 400h 4WD',
      'RX 450h',
      'RX 450h AWD',
      'RX 450h L AWD',
      'SC',
      'SC 300/SC 400',
      'SC 300/SC 430',
      'SC 430',
      'UX 200',
      'UX 250h',
      'UX 250h AWD',
    ],
  },
  {
    'brand': 'Lincoln',
    'models': [
      'Aviator 2WD',
      'Aviator 4WD',
      'Aviator AWD',
      'Aviator PHEV AWD',
      'Aviator RWD',
      'Blackwood 2WD',
      'Continental',
      'Continental  AWD',
      'Continental  FWD',
      'Continental Coach',
      'Corsair AWD',
      'Corsair FWD',
      'LS',
      'MKC AWD',
      'MKC FWD',
      'MKS AWD',
      'MKS FWD',
      'MKT AWD',
      'MKT FWD',
      'MKT Hearse AWD',
      'MKT Limo AWD',
      'MKT Livery AWD',
      'MKT Livery FWD',
      'MKX AWD',
      'MKX FWD',
      'MKZ AWD',
      'MKZ FWD',
      'MKZ Hybrid FWD',
      'Mark LT',
      'Mark LT 4WD',
      'Mark VII',
      'Mark VIII',
      'Nautilus AWD',
      'Nautilus FWD',
      'Navigator 2WD',
      'Navigator 2WD FFV',
      'Navigator 4WD',
      'Navigator 4WD FFV',
      'Navigator L 2WD',
      'Navigator L 4WD',
      'Navigator Limo. 2WD FFV',
      'Town Car',
      'Town Car FFV',
      'Zephyr',
    ],
  },
  {
    'brand': 'London Coach Co Inc',
    'models': [
      'London Taxi',
    ],
  },
  {
    'brand': 'London Taxi',
    'models': [
      'London Taxi',
    ],
  },
  {
    'brand': 'Lotus',
    'models': [
      '98 Esprit V8',
      'Elan',
      'Elise/Exige',
      'Elise/exige',
      'Esprit',
      'Esprit Turbo',
      'Esprit Turbo HC PI',
      'Esprit V8',
      'Evora',
      'Evora GT',
    ],
  },
  {
    'brand': 'MINI',
    'models': [
      'Clubman',
      'Clubman S',
      'Cooper',
      'Cooper (3-doors)',
      'Cooper (5-doors)',
      'Cooper Clubman',
      'Cooper Clubman All4',
      'Cooper Clubvan',
      'Cooper Convertible',
      'Cooper Countryman',
      'Cooper Countryman All4',
      'Cooper Countryman Coupe',
      'Cooper Coupe',
      'Cooper Hardtop 2 door',
      'Cooper Hardtop 4 door',
      'Cooper Paceman',
      'Cooper Roadster',
      'Cooper S',
      'Cooper S (3-doors)',
      'Cooper S (5-doors)',
      'Cooper S Clubman',
      'Cooper S Clubman All4',
      'Cooper S Convertible',
      'Cooper S Countryman',
      'Cooper S Countryman All4',
      'Cooper S Countryman Coupe',
      'Cooper S Countryman Coupe All4',
      'Cooper S Coupe',
      'Cooper S Hardtop 2 door',
      'Cooper S Hardtop 4 door',
      'Cooper S JCWorks GP Kit',
      'Cooper S Paceman',
      'Cooper S Paceman All4',
      'Cooper S Roadster',
      'Cooper SE Countryman All4',
      'Cooper SE Hardtop 2 door',
      'JCW Countryman All4',
      'JCW Countryman Coupe All4',
      'JCW Paceman All4',
      'John Cooper Works',
      'John Cooper Works Clubman',
      'John Cooper Works Clubman All4',
      'John Cooper Works Convertible',
      'John Cooper Works Coupe',
      'John Cooper Works GP',
      'John Cooper Works GP-2',
      'John Cooper Works Hardtop',
      'John Cooper Works Roadster',
      'MiniE',
    ],
  },
  {
    'brand': 'Mahindra',
    'models': [
      'TR40',
    ],
  },
  {
    'brand': 'Maserati',
    'models': [
      '222E',
      '225',
      '228',
      '430',
      'Biturbo',
      'Biturbo 425',
      'Biturbo Spyder',
      'Coupe Cambiocorsa/Coupe GT',
      'Coupe Cambiocorsa/GT/G-Sport',
      'Coupe Cambiocorsa/coupe GT',
      'Coupe and Gransport',
      'Ghibli',
      'Ghibli  S RWD',
      'Ghibli  V6 RWD',
      'Ghibli S',
      'Ghibli S RWD',
      'Ghibli SQ4',
      'Ghibli Trofeo',
      'Ghibli V6',
      'Ghibli V6 AWD',
      'Ghibli V6 SQ4',
      'Ghibli V6 SQ4 AWD',
      'GranTurismo',
      'GranTurismo Convertible',
      'Karif',
      'Levante',
      'Levante GTS',
      'Levante S',
      'Levante Trofeo',
      'Q',
      'Quattroporte',
      'Quattroporte GTS',
      'Quattroporte S',
      'Quattroporte SQ4',
      'Quattroporte SQ4 V6',
      'Quattroporte Trofeo',
      'Quattroporte V8',
      'Quattroporte/QP Sport GT',
      'Spider Cambiocorsa/Spider GT',
      'Spider Cambiocorsa/spider GT',
      'Spyder',
      'Spyder Cambiocorsa/GT/90 ANV',
    ],
  },
  {
    'brand': 'Maybach',
    'models': [
      '57',
      '57 Zeppelin',
      '57S',
      '62',
      '62S',
      'Landaulet',
    ],
  },
  {
    'brand': 'Mazda',
    'models': [
      '2',
      '2500 2WD',
      '3',
      '3 4-Door',
      '3 4-Door 2WD',
      '3 4-Door 4WD',
      '3 5-Door',
      '3 5-Door 2WD',
      '3 5-Door 4WD',
      '3 DI 4-Door',
      '3 DI 5-Door',
      '323',
      '323 Protege 4x4',
      '323 Wagon',
      '323/323 Protege',
      '5',
      '6',
      '6 Sport Wagon',
      '626',
      '626/MX-6',
      '929',
      'B2000',
      'B2000/B2200 Pickup 2WD',
      'B2000/B2200/B2600',
      'B2200/B2600',
      'B2200/B2600i',
      'B2300 2WD',
      'B2300/B3000/B4000 Pickup 2WD',
      'B2300/B3000/B4000 Pickup 4WD',
      'B2500',
      'B2500 2WD',
      'B2500/B3000/B4000 2WD',
      'B2500/B3000/B4000 4WD',
      'B2600 4x4',
      'B2600i 4x4',
      'B3000',
      'B3000 (FFV) Ethanol 2WD',
      'B3000 2WD',
      'B3000 2WD FFV',
      'B3000 4WD',
      'B3000 FFV 2WD',
      'B3000 FFV 4WD',
      'B4000',
      'B4000 2WD',
      'B4000 4WD',
      'CX-3 2WD',
      'CX-3 4WD',
      'CX-30 2WD',
      'CX-30 4WD',
      'CX-5 2WD',
      'CX-5 4WD',
      'CX-7 2WD',
      'CX-7 4WD',
      'CX-9 2WD',
      'CX-9 4WD',
      'GLC',
      'GLC Wagon',
      'MPV',
      'MPV 4WD',
      'MPV 4x4',
      'MX-3',
      'MX-5',
      'MX-5 Miata',
      'MX-6',
      'Millenia',
      'Navajo',
      'Navajo 4x4',
      'Protege',
      'Protege/Protege 5',
      'Protege/Protege MPS',
      'RX-7',
      'RX-8',
      'Speed 3',
      'Tribute 2WD',
      'Tribute 4WD',
      'Tribute 4WD FFV',
      'Tribute FWD',
      'Tribute FWD FFV',
      'Tribute Hybrid 2WD',
      'Tribute Hybrid 4WD',
    ],
  },
  {
    'brand': 'McLaren Automotive',
    'models': [
      '540C Coupe',
      '570GT',
      '570S Coupe',
      '570S Spider',
      '600LT Coupe',
      '600LT Spider',
      '620R Coupe',
      '650S Can-Am',
      '650S Coupe',
      '650S Spider',
      '675LT Coupe',
      '675LT Spider',
      '720S Coupe',
      '720S Spider',
      'GT',
      'MP4-12C Coupe',
      'MP4-12C Spider',
      'MSO HS',
      'P1',
      'Senna',
      'Speedtail',
    ],
  },
  {
    'brand': 'Mcevoy Motors',
    'models': [
      '240 DL/240 GL Sedan',
      '240 DL/240 GL Wagon',
      '740 GLE Sedan',
      '740 GLE Wagon',
    ],
  },
  {
    'brand': 'Mercedes-Benz',
    'models': [
      '190',
      '190 D 2.2/190 E 2.3',
      '190D 2.5',
      '190D 2.5 Turbo',
      '190E',
      '190E 2.3',
      '190E 2.3-16',
      '190E 2.6',
      '200E',
      '230CE',
      '230E',
      '230TE',
      '260E',
      '300CE',
      '300D',
      '300D 2.5 Turbo',
      '300D/300CD',
      '300E',
      '300E 2.6',
      '300E 2.8',
      '300E 4Matic',
      '300SD',
      '300SD/380SE',
      '300SDL',
      '300SE',
      '300SEL',
      '300SL',
      '300TD',
      '300TE',
      '300TE 4Matic',
      '350SD Turbo',
      '350SDL Turbo',
      '380SE',
      '380SL',
      '400E',
      '400SE',
      '400SEL',
      '420 SE',
      '420SE',
      '420SEL',
      '500E',
      '500SE',
      '500SEC',
      '500SEL',
      '500SEL 5.0L',
      '500SEL 5.6L',
      '500SL',
      '560SE',
      '560SEC',
      '560SEL',
      '560SL',
      '600SEL',
      '600SEL/SEC',
      '600SL',
      'A220',
      'A220 4matic',
      'AMG A35 4matic',
      'AMG C43 4matic',
      'AMG C43 4matic Convertible',
      'AMG C43 4matic Coupe',
      'AMG C63',
      'AMG C63 Convertible',
      'AMG C63 Coupe',
      'AMG C63 S',
      'AMG C63 S Convertible',
      'AMG C63 S Coupe',
      'AMG CLA35 4matic',
      'AMG CLA45 4matic',
      'AMG CLS53 4matic Plus',
      'AMG CLS63 S 4matic',
      'AMG E43 4matic',
      'AMG E53 4matic Plus',
      'AMG E53 4matic Plus Convertible',
      'AMG E53 4matic Plus Coupe',
      'AMG E63 4matic',
      'AMG E63 S 4matic',
      'AMG E63 S 4matic (wagon)',
      'AMG E63 S 4matic Plus',
      'AMG E63 S 4matic Plus (SW)',
      'AMG E63 S 4matic Plus (wagon)',
      'AMG G63',
      'AMG G65',
      'AMG GL63',
      'AMG GLA35 4matic',
      'AMG GLA45 4matic',
      'AMG GLB35 4matic',
      'AMG GLC43 4matic',
      'AMG GLC43 4matic  Coupe',
      'AMG GLC43 4matic Coupe',
      'AMG GLC63 4matic',
      'AMG GLC63 4matic Coupe',
      'AMG GLC63 4matic Plus',
      'AMG GLC63 4matic Plus Coupe',
      'AMG GLC63 S 4matic Coupe',
      'AMG GLC63 S 4matic Plus Coupe',
      'AMG GLE43 4matic',
      'AMG GLE43 4matic Coupe',
      'AMG GLE53 4matic Plus',
      'AMG GLE53 4matic Plus Coupe',
      'AMG GLE63',
      'AMG GLE63 S',
      'AMG GLE63 S 4matic Plus',
      'AMG GLE63 S 4matic Plus Coupe',
      'AMG GLE63 S Coupe',
      'AMG GLS63',
      'AMG GLS63 4matic Plus',
      'AMG GT',
      'AMG GT 43 4matic Plus',
      'AMG GT 53 4matic Plus',
      'AMG GT 63 4matic Plus',
      'AMG GT 63 S 4matic Plus',
      'AMG GT Black Series',
      'AMG GT C Coupe',
      'AMG GT C Roadster',
      'AMG GT Coupe',
      'AMG GT R Coupe',
      'AMG GT R Roadster',
      'AMG GT Roadster',
      'AMG GT S',
      'AMG GT S Coupe',
      'AMG S63 4matic',
      'AMG S63 4matic Convertible',
      'AMG S63 4matic Coupe',
      'AMG S63 4matic Plus',
      'AMG S63 4matic Plus Convertible',
      'AMG S63 4matic Plus Coupe',
      'AMG S65',
      'AMG S65 Convertible',
      'AMG S65 Coupe',
      'AMG SL63',
      'AMG SL65',
      'AMG SLC43',
      'AMG SLK55',
      'B-Class Electric Drive',
      'B250e',
      'C220',
      'C230',
      'C230 Kompressor',
      'C230 Kompressor Sports Coupe',
      'C240',
      'C240 (Wagon)',
      'C240 4matic',
      'C240 4matic (Wagon)',
      'C240 FFV',
      'C240 FFV (Wagon)',
      'C250',
      'C250 Coupe',
      'C280',
      'C280 4matic',
      'C300',
      'C300 4matic',
      'C300 4matic Convertible',
      'C300 4matic Coupe',
      'C300 Convertible',
      'C300 Coupe',
      'C32 AMG',
      'C320',
      'C320 (Wagon)',
      'C320 4matic',
      'C320 4matic (Wagon)',
      'C320 4matic Sedan',
      'C320 FFV',
      'C320 FFV (Wagon)',
      'C320 Sports Coupe',
      'C320 Sports Coupe FFV',
      'C350',
      'C350 4matic',
      'C350 4matic Coupe',
      'C350 Coupe',
      'C350e',
      'C36',
      'C36 AMG',
      'C400 4matic',
      'C43',
      'C43 AMG',
      'C450 AMG',
      'C55 AMG',
      'C63 AMG',
      'C63 AMG Black Series Coupe',
      'C63 AMG Coupe',
      'CL500',
      'CL55 AMG',
      'CL550',
      'CL550 4matic',
      'CL600',
      'CL63 AMG',
      'CL65 AMG',
      'CLA250',
      'CLA250 4matic',
      'CLA45 AMG 4matic',
      'CLK320',
      'CLK320 (Cabriolet)',
      'CLK320 Cabriolet',
      'CLK350',
      'CLK350 (Cabriolet)',
      'CLK430',
      'CLK430 (Cabriolet)',
      'CLK500',
      'CLK500 (Cabriolet)',
      'CLK55 AMG',
      'CLK55 AMG (Cabriolet)',
      'CLK550',
      'CLK550 (Cabriolet)',
      'CLK63 AMG',
      'CLK63 AMG (Cabriolet)',
      'CLS400',
      'CLS400 4matic',
      'CLS450',
      'CLS450 4matic',
      'CLS500',
      'CLS55 AMG',
      'CLS550',
      'CLS550 4matic',
      'CLS63 AMG',
      'CLS63 AMG 4matic',
      'CLS63 AMG S',
      'CLS63 AMG S 4matic',
      'E300',
      'E300 4matic',
      'E300 Diesel',
      'E300 Turbodiesel',
      'E320',
      'E320 (Wagon)',
      'E320 4Matic',
      'E320 4Matic (Wagon)',
      'E320 4matic',
      'E320 4matic (Wagon)',
      'E320 Bluetec',
      'E320 Cdi',
      'E320 Convertible',
      'E320 Coupe',
      'E320 Sedan',
      'E320 Sedan 4Matic',
      'E320 Wagon',
      'E320 Wagon 4Matic',
      'E350',
      'E350 (wagon)',
      'E350 4Matic',
      'E350 4matic',
      'E350 4matic (wagon)',
      'E350 4matic Coupe',
      'E350 Convertible',
      'E350 Coupe',
      'E400',
      'E400 4matic',
      'E400 4matic (station wagon)',
      'E400 4matic Convertible',
      'E400 4matic Coupe',
      'E400 Convertible',
      'E400 Coupe',
      'E400 Hybrid',
      'E420',
      'E430',
      'E430 4Matic',
      'E450 4matic',
      'E450 4matic (station wagon)',
      'E450 4matic All-Terrain (wagon)',
      'E450 4matic Convertible',
      'E450 4matic Coupe',
      'E450 Convertible',
      'E450 Coupe',
      'E500',
      'E500 (Wagon)',
      'E500 4matic',
      'E500 4matic (Wagon)',
      'E500 4matic (wagon)',
      'E55 AMG',
      'E55 AMG (Wagon)',
      'E55 AMG (wagon)',
      'E550',
      'E550 4matic',
      'E550 Convertible',
      'E550 Coupe',
      'E63 AMG',
      'E63 AMG (wagon)',
      'E63 AMG 4matic',
      'E63 AMG 4matic (wagon)',
      'E63 AMG S',
      'E63 AMG S 4matic',
      'E63 AMG S 4matic (wagon)',
      'G500',
      'G55 AMG',
      'G550',
      'G550 4x4',
      'G550 4x4 (Special Off-Road Model)',
      'G63 AMG',
      'GL320 CDI 4matic',
      'GL450 4matic',
      'GL550 4matic',
      'GL63 AMG',
      'GLA250',
      'GLA250 4matic',
      'GLA45 AMG 4matic',
      'GLB250',
      'GLB250 4matic',
      'GLC300',
      'GLC300 4matic',
      'GLC300 4matic Coupe',
      'GLC350e 4matic',
      'GLE300 d 4matic',
      'GLE350',
      'GLE350 4matic',
      'GLE350 d 4matic',
      'GLE400 4matic',
      'GLE450 4matic',
      'GLE450 AMG Coupe',
      'GLE550e 4matic',
      'GLE580 4matic',
      'GLK250 Bluetec 4matic',
      'GLK350',
      'GLK350 4matic',
      'GLS450 4matic',
      'GLS550 4matic',
      'GLS580 4matic',
      'GLS600 4matic Maybach',
      'ML250 Bluetec 4matic',
      'ML320',
      'ML320 CDI 4matic',
      'ML350',
      'ML350 4matic',
      'ML400 4matic',
      'ML430',
      'ML450 Hybrid 4matic',
      'ML500',
      'ML500 4matic',
      'ML55',
      'ML55 AMG',
      'ML550 4matic',
      'ML63 AMG',
      'Maybach S 600',
      'Maybach S550 4matic',
      'Maybach S560 4matic',
      'Maybach S600',
      'Maybach S650',
      'Maybach S650 Convertible',
      'Metris (Cargo Van)',
      'Metris (Cargo Van, LWB)',
      'Metris (Passenger Van)',
      'R320 CDI 4matic',
      'R350',
      'R350 4matic',
      'R500',
      'R500 4matic',
      'R63 AMG',
      'S320',
      'S350',
      'S350D',
      'S400 Hybrid',
      'S420',
      'S430',
      'S430 4matic',
      'S450',
      'S450 4matic',
      'S500',
      'S500 4matic',
      'S500 Coupe',
      'S500 Sedan',
      'S55 AMG',
      'S550',
      'S550 4matic',
      'S550 4matic Coupe',
      'S550 Convertible',
      'S550e',
      'S560',
      'S560 4matic',
      'S560 4matic Coupe',
      'S560 4matic Maybach',
      'S560 Convertible',
      'S560e',
      'S600',
      'S600 Coupe',
      'S600 Sedan',
      'S63 AMG',
      'S63 AMG 4matic',
      'S63 AMG 4matic Coupe',
      'S65 AMG',
      'S65 AMG Convertible',
      'S65 AMG Coupe',
      'SL320',
      'SL400',
      'SL450',
      'SL500',
      'SL55 AMG',
      'SL550',
      'SL600',
      'SL63 AMG',
      'SL65 AMG',
      'SLC300',
      'SLK230',
      'SLK230 Kompressor',
      'SLK250',
      'SLK280',
      'SLK300',
      'SLK32 AMG',
      'SLK320',
      'SLK350',
      'SLK55 AMG',
      'SLR',
      'SLS AMG',
      'SLS AMG Black Series Coupe',
      'SLS AMG Coupe',
      'SLS AMG GT Coupe',
      'SLS AMG GT Roadster',
      'SLS AMG Roadster',
    ],
  },
  {
    'brand': 'Mercury',
    'models': [
      'Capri',
      'Cougar',
      'Grand Marquis',
      'Grand Marquis FFV',
      'Grand Marquis Wagon',
      'Lynx',
      'Lynx Wagon',
      'Marauder',
      'Mariner 2WD',
      'Mariner 4WD',
      'Mariner 4WD FFV',
      'Mariner FWD',
      'Mariner FWD FFV',
      'Mariner Hybrid 4WD',
      'Mariner Hybrid FWD',
      'Marquis',
      'Marquis Wagon',
      'Milan',
      'Milan AWD',
      'Milan AWD FFV',
      'Milan FFV',
      'Milan FWD',
      'Milan FWD FFV',
      'Milan Hybrid FWD',
      'Milan S',
      'Milan S FWD',
      'Montego AWD',
      'Montego FWD',
      'Monterey Wagon FWD',
      'Mountaineer 2WD',
      'Mountaineer 2WD FFV',
      'Mountaineer 4WD',
      'Mountaineer 4WD FFV',
      'Mountaineer AWD',
      'Mountaineer FFV 2WD',
      'Mountaineer FFV 4WD',
      'Mystique',
      'Sable',
      'Sable (FFV)',
      'Sable AWD',
      'Sable FWD',
      'Sable Wagon',
      'Sable Wagon (FFV)',
      'Sable Wagon 3.0 A/C',
      'Sable Wagon V6 A/C',
      'Topaz',
      'Topaz AWD',
      'Tracer',
      'Tracer Wagon',
      'Villager FWD Van',
      'Villager FWD Wagon',
    ],
  },
  {
    'brand': 'Merkur',
    'models': [
      'Scorpio',
      'XR4Ti',
    ],
  },
  {
    'brand': 'Mitsubishi',
    'models': [
      '3000 GT',
      '3000 GT Spyder',
      'Cordia',
      'Diamante',
      'Diamante Sedan',
      'Diamante Wagon',
      'Eclipse',
      'Eclipse Convertible',
      'Eclipse Cross 2WD',
      'Eclipse Cross 4WD',
      'Eclipse Cross ES 2WD',
      'Eclipse Cross ES 4WD',
      'Eclipse Spyder',
      'Endeavor',
      'Endeavor 2WD',
      'Endeavor 4WD',
      'Endeavor AWD',
      'Expo',
      'Expo.LRV',
      'Galant',
      'Galant Sigma',
      'Lancer',
      'Lancer 4WD',
      'Lancer AWD',
      'Lancer Evolution',
      'Lancer Sportback',
      'Mirage',
      'Mirage G4',
      'Mirage Wagon',
      'Montero',
      'Montero 4WD',
      'Montero Sport 2WD',
      'Montero Sport 4WD',
      'Nativa 2WD',
      'Nativa 2WD (Puerto Rico Only)',
      'Nativa 2WD(Puerto Rico Only)',
      'Nativa 4WD',
      'Nativa 4WD (Puerto Rico Only)',
      'Outlander 2WD',
      'Outlander 4WD',
      'Outlander PHEV',
      'Outlander Sport 2WD',
      'Outlander Sport 4WD',
      'Precis',
      'Raider Pickup 2WD',
      'Raider Pickup 4WD',
      'Sigma',
      'Space Wagon',
      'Space Wagon 4WD',
      'Starion',
      'Tredia',
      'Truck 2WD',
      'Truck 4WD',
      'Van',
      'Wagon',
      'i-MiEV',
    ],
  },
  {
    'brand': 'Mobility Ventures LLC',
    'models': [
      'MV-1',
      'MV-1 CNG',
    ],
  },
  {
    'brand': 'Morgan',
    'models': [
      'Plus Eight',
    ],
  },
  {
    'brand': 'Nissan',
    'models': [
      '200SX',
      '240SX',
      '300ZX',
      '300ZX 2x2',
      '350z',
      '350z Roadster',
      '370Z',
      '370Z Roadster',
      '370z',
      'Altima',
      'Altima / Stanza',
      'Altima AWD',
      'Altima AWD SR/Platinum',
      'Altima Coupe',
      'Altima Hybrid',
      'Altima SR',
      'Altima SR/Platinum',
      'Altima SV/SL',
      'Altra EV',
      'Armada 2WD',
      'Armada 2WD FFV',
      'Armada 4WD',
      'Armada 4WD FFV',
      'Axxess',
      'Axxess AWD',
      'Cube',
      'Frontier 2WD',
      'Frontier 2WD FFV',
      'Frontier 4WD',
      'Frontier 4WD FFV',
      'Frontier V6 2WD',
      'Frontier V6 4WD',
      'GT-R',
      'Hardbody 2WD',
      'Hardbody 4WD',
      'Hyper-Mini',
      'Juke',
      'Juke AWD',
      'Juke Nismo RS',
      'Juke Nismo RS AWD',
      'Kicks',
      'Leaf',
      'Leaf (24 kW-hr battery pack)',
      'Leaf (30 kW-hr battery pack)',
      'Leaf (40 kW-hr battery pack)',
      'Leaf (62 kW-hr battery pack)',
      'Leaf SV/SL (62 kW-hr battery pack)',
      'Maxima',
      'Maxima Wagon',
      'Murano 2WD',
      'Murano AWD',
      'Murano CrossCabriolet',
      'Murano FWD',
      'Murano Hybrid AWD',
      'Murano Hybrid FWD',
      'NV200 Cargo Van',
      'NV200 NYC Taxi',
      'NX',
      'NX Coupe',
      'Pathfinder 2WD',
      'Pathfinder 4WD',
      'Pathfinder 4WD Platinum',
      'Pathfinder Armada 2WD',
      'Pathfinder Armada 4WD',
      'Pathfinder FE 2WD',
      'Pathfinder Hybrid 2WD',
      'Pathfinder Hybrid 4WD',
      'Pathfinder Van (cargo)',
      'Pickup 2WD',
      'Pickup 4WD',
      'Pickup Cab Chassis',
      'Pulsar NX',
      'Pulsar/Pulsar-NX',
      'Quest',
      'Rogue AWD',
      'Rogue AWD  SV/SL/Platinum',
      'Rogue FWD',
      'Rogue FWD  SV/SL/Platinum',
      'Rogue Hybrid  AWD',
      'Rogue Hybrid  FWD',
      'Rogue Select AWD',
      'Rogue Select FWD',
      'Rogue Sport',
      'Rogue Sport AWD',
      'Rogue Sport FWD',
      'Sentra',
      'Sentra Classic',
      'Sentra Coupe',
      'Sentra FE',
      'Sentra Honeybee',
      'Sentra Nismo',
      'Sentra SR',
      'Sentra Wagon',
      'Sentra Wagon 4WD',
      'Sentra/200SX',
      'Stanza',
      'Stanza Wagon 2WD',
      'Stanza Wagon 4WD',
      'Titan 2WD',
      'Titan 2WD FFV',
      'Titan 4WD',
      'Titan 4WD  PRO4X',
      'Titan 4WD FFV',
      'Titan 4WD PRO-4X',
      'Titan 4WD PRO4X',
      'Titan FE 2WD',
      'Titan FE 2WD FFV',
      'Truck 2WD',
      'Truck 2WD (new Version)',
      'Truck 4WD',
      'Truck 4WD (new Version)',
      'Truck Cab Chassis 2WD',
      'Van (cargo)',
      'Van (passenger)',
      'Van(cargo)',
      'Versa',
      'Versa FE',
      'Xterra 2WD',
      'Xterra 4WD',
      'Xterra V6 2WD',
      'Xterra V6 4WD',
    ],
  },
  {
    'brand': 'Oldsmobile',
    'models': [
      'Achieva',
      'Alero',
      'Aurora',
      'Bravada 2WD',
      'Bravada AWD',
      'Calais',
      'Ciera SL',
      'Ciera SL Wagon',
      'Custom Cruiser',
      'Custom Cruiser Wagon',
      'Cutlass',
      'Cutlass Calais',
      'Cutlass Ciera',
      'Cutlass Cruiser',
      'Cutlass Cruiser Wagon',
      'Cutlass Supreme',
      'Cutlass Supreme Classic',
      'Delta 88',
      'Delta 88 Royale',
      'Eighty-Eight',
      'Eighty-Eight/Regency',
      'Eighty-Eight/Regency/LSS',
      'Firenza',
      'Firenza Cruiser',
      'Firenza Cruiser Wagon',
      'Intrigue',
      'Ninety-Eight',
      'Ninety-Eight II',
      'Ninety-Eight Regency',
      'Ninety-Eight/Touring',
      'Omega',
      'Silhouette 2WD',
      'Silhouette AWD',
      'Toronado',
      'Trofeo/Toronado',
    ],
  },
  {
    'brand': 'PAS Inc - GMC',
    'models': [
      'Pas-Syclone',
      'Pas-typhoon',
    ],
  },
  {
    'brand': 'PAS, Inc',
    'models': [
      'Pas-Syclone',
      'Pas-Typhoon',
    ],
  },
  {
    'brand': 'Pagani',
    'models': [
      'Huayra',
      'Huayra Coupe',
      'Huayra Roadster BC',
    ],
  },
  {
    'brand': 'Panos',
    'models': [
      'Roadster',
    ],
  },
  {
    'brand': 'Panoz Auto-Development',
    'models': [
      'Panoz Roadster',
    ],
  },
  {
    'brand': 'Panther Car Company Limited',
    'models': [
      'Kallista',
    ],
  },
  {
    'brand': 'Peugeot',
    'models': [
      '405 Sedan',
      '405 Sports Wagon',
      '405 Station Wagon',
      '505',
      '505 Sedan',
      '505 Station Wagon',
      '505 Wagon',
      '604 Sedan',
    ],
  },
  {
    'brand': 'Pininfarina',
    'models': [
      'Spider',
    ],
  },
  {
    'brand': 'Plymouth',
    'models': [
      'Acclaim',
      'Breeze',
      'Caravelle',
      'Colt',
      'Colt Vista',
      'Colt Vista 4WD',
      'Colt Wagon',
      'Colt Wagon 4WD',
      'Conquest',
      'Gran Fury',
      'Horizon',
      'Laser',
      'Neon',
      'Prowler',
      'Reliant',
      'Reliant Wagon',
      'Sundance',
      'Sundance Convertible',
      'Sundance/Duster',
      'Turismo',
      'Voyager 2WD',
      'Voyager/Grand Voyager 2WD',
      'Voyager/Grand Voyager 4WD',
    ],
  },
  {
    'brand': 'Polestar',
    'models': [
      '1',
      '2',
    ],
  },
  {
    'brand': 'Pontiac',
    'models': [
      '1000',
      '2000 Sunbird',
      '2000 Sunbird Convertible',
      '2000 Sunbird Wagon',
      '20th Anniversary Trans Am',
      '6000',
      '6000 Wagon',
      'Aztek AWD',
      'Aztek FWD',
      'Bonneville',
      'Fiero',
      'Firebird',
      'Firebird/Formula',
      'Firebird/Trans Am',
      'Firebird/Trans Am/Formula',
      'Firefly',
      'Firefly FE',
      'G3 (3-Door)',
      'G3 (5-Door)',
      'G3 Wave',
      'G3 Wave 5',
      'G5',
      'G5 GT',
      'G5 XFE',
      'G5/Pursuit',
      'G6',
      'G6 GT/GTP Convertible',
      'G8',
      'GTO',
      'Grand Am',
      'Grand Prix',
      'Grand Prix Ste Turbo',
      'Grand Prix Turbo',
      'Lemans',
      'Montana AWD',
      'Montana FWD',
      'Montana SV6 AWD',
      'Montana SV6 FWD',
      'Montana SVX AWD',
      'Montana SVX FWD',
      'Monterey Wagon FWD',
      'Parisienne',
      'Parisienne Wagon',
      'Phoenix',
      'Safari Wagon',
      'Solstice',
      'Sunbird',
      'Sunbird Convertible',
      'Sunbird Wagon',
      'Sunburst',
      'Sunfire',
      'Torrent AWD',
      'Torrent FWD',
      'Trans Sport 2WD',
      'Trans Sport/Montana 2WD',
      'Turbo Firefly',
      'Vibe',
      'Vibe AWD',
      'Wave',
      'Wave 5',
    ],
  },
  {
    'brand': 'Porsche',
    'models': [
      '718 Boxster',
      '718 Boxster GTS',
      '718 Boxster S',
      '718 Boxster T',
      '718 Cayman',
      '718 Cayman GT4',
      '718 Cayman GTS',
      '718 Cayman S',
      '718 Cayman T',
      '718 Spyder',
      '911',
      '911 C4 GTS',
      '911 C4 GTS Cabriolet',
      '911 Carrera',
      '911 Carrera 2/4',
      '911 Carrera 4',
      '911 Carrera 4 Cabriolet',
      '911 Carrera 4 GTS',
      '911 Carrera 4 GTS Cabriolet',
      '911 Carrera 4 Targa',
      '911 Carrera 4/2',
      '911 Carrera 4S',
      '911 Carrera 4S Cabriolet',
      '911 Carrera 4S Targa',
      '911 Carrera Cabriolet',
      '911 Carrera GTS',
      '911 Carrera GTS Cabriolet',
      '911 Carrera S',
      '911 Carrera S Cabriolet',
      '911 Carrera T',
      '911 GT2',
      '911 GT2 RS',
      '911 GT3',
      '911 GT3 RS',
      '911 GT3 Touring',
      '911 GTS',
      '911 GTS Cabriolet',
      '911 R',
      '911 SC',
      '911 Speedster',
      '911 Targa',
      '911 Targa 4',
      '911 Targa 4 GTS',
      '911 Targa 4S',
      '911 Turbo',
      '911 Turbo Cabriolet',
      '911 Turbo Coupe',
      '911 Turbo S',
      '911 Turbo S Cabriolet',
      '911 Turbo S Coupe',
      '911 Turbo S Exclusive',
      '911 Turbo S Exclusive Cabriolet',
      '918 Spyder',
      '924 S',
      '928 GTS',
      '928 S',
      '928 S4',
      '944',
      '944 S',
      '944 S2',
      '944 Turbo',
      '968',
      'Boxster',
      'Boxster GTS',
      'Boxster S',
      'Boxster Spyder',
      'Boxster T',
      'Carrera',
      'Carrera 2 911 GT3',
      'Carrera 2 Cabriolet',
      'Carrera 2 Cabriolet Kit',
      'Carrera 2 Coupe',
      'Carrera 2 Coupe Kit',
      'Carrera 2 S Cabriolet',
      'Carrera 2 S Coupe',
      'Carrera 4',
      'Carrera 4 Cabriolet',
      'Carrera 4 Cabriolet Kit',
      'Carrera 4 Coupe',
      'Carrera 4 S',
      'Carrera 4 S Cabriolet',
      'Carrera 4 S Cabriolet Kit',
      'Carrera 4 S Coupe',
      'Carrera 4 S Kit',
      'Carrera 4 S Targa',
      'Carrera 4 Targa',
      'Carrera GT',
      'Cayenne',
      'Cayenne Coupe',
      'Cayenne Diesel',
      'Cayenne GTS',
      'Cayenne GTS Coupe',
      'Cayenne Platinum',
      'Cayenne S',
      'Cayenne S Coupe',
      'Cayenne S Hybrid',
      'Cayenne S e-Hybrid',
      'Cayenne TransSiberia',
      'Cayenne Turbo',
      'Cayenne Turbo Coupe',
      'Cayenne Turbo Kit',
      'Cayenne Turbo S',
      'Cayenne Turbo S e-Hybrid',
      'Cayenne Turbo S e-Hybrid Coupe',
      'Cayenne e-Hybrid',
      'Cayenne e-Hybrid Coupe',
      'Cayman',
      'Cayman GT4',
      'Cayman GTS',
      'Cayman R',
      'Cayman S',
      'Cayman T',
      'Macan',
      'Macan GTS',
      'Macan S',
      'Macan Turbo',
      'Macan Turbo Kit',
      'New 911 Carrera',
      'New 911 Carrera Cabriolet',
      'New 911 Carrera S',
      'New 911 Carrera S Cabriolet',
      'Panamera',
      'Panamera 4',
      'Panamera 4 Executive',
      'Panamera 4 ST',
      'Panamera 4 e-Hybrid',
      'Panamera 4 e-Hybrid Executive',
      'Panamera 4 e-Hybrid ST',
      'Panamera 4S',
      'Panamera 4S Executive',
      'Panamera 4S ST',
      'Panamera GTS',
      'Panamera GTS ST',
      'Panamera S',
      'Panamera S E-Hybrid',
      'Panamera S Hybrid',
      'Panamera Turbo',
      'Panamera Turbo Exec',
      'Panamera Turbo Executive',
      'Panamera Turbo S',
      'Panamera Turbo S Executive',
      'Panamera Turbo S ST',
      'Panamera Turbo S e-Hybrid',
      'Panamera Turbo S e-Hybrid Executive',
      'Panamera Turbo S e-Hybrid ST',
      'Panamera Turbo ST',
      'Targa',
      'Targa Kit',
      'Taycan 4S Perf Battery',
      'Taycan 4S Perf Battery Plus',
      'Taycan Turbo',
      'Taycan Turbo S',
      'Turbo',
      'Turbo 2 911 GT2',
      'Turbo 2 911 Gt2',
      'Turbo 4 911',
      'Turbo 4 911 Cab',
      'Turbo 4 911 Cab Kit',
      'Turbo 4 911 Kit',
      'Turbo 4 911 S',
      'Turbo 4 911 Turbo',
      'Turbo 4 911 Turbo Cab S',
      'Turbo GT2',
      'Turbo Kit',
    ],
  },
  {
    'brand': 'Quantum Technologies',
    'models': [
      'Chevrolet Cavalier',
    ],
  },
  {
    'brand': 'Qvale',
    'models': [
      'Detomaso Mangusta',
    ],
  },
  {
    'brand': 'RUF Automobile',
    'models': [
      'RUF TURBO 12/CTR3',
      'RUFTURBO12/CTR3/CTR2017',
      'TURBO12/CTR3/CTR ANNIVERSARY',
    ],
  },
  {
    'brand': 'Ram',
    'models': [
      '1500 2WD',
      '1500 4WD',
      '1500 Classic 2WD',
      '1500 Classic 4WD',
      '1500 HFE 2WD',
      '1500 TRX 4WD',
      'C/V',
      'C/V Cargo Van',
      'C/V Tradesman',
      'Promaster City',
    ],
  },
  {
    'brand': 'Red Shift Ltd.',
    'models': [
      'Delta 204T',
    ],
  },
  {
    'brand': 'Renault',
    'models': [
      '18i',
      '18i 4DR Wagon',
      '18i Sportwagon',
      'Alliance',
      'Alliance Convertible',
      'Alliance/Encore',
      'Fuego',
      'GTA',
      'GTA Convertible',
      'Sportwagon',
    ],
  },
  {
    'brand': 'Rolls-Royce',
    'models': [
      'Azure',
      'Brooklands',
      'Brooklands and (LWB)',
      'Brooklands/Brklnds L',
      'Camargue',
      'Continental',
      'Continental (turbo)',
      'Continental R',
      'Continental T',
      'Corniche',
      'Corniche II',
      'Corniche II/Continental',
      'Corniche III',
      'Corniche IV',
      'Corniche S',
      'Corniche/Continental',
      'Cullinan',
      'Cullinan Black Badge',
      'Dawn',
      'Dawn Black Badge',
      'Eight/Mulsan',
      'Eight/Mulsanne S',
      'Eight/Mulsanne S and S',
      'Flying Spur',
      'Ghost',
      'Ghost EWB',
      'Limousine',
      'Park Ward',
      'Phantom',
      'Phantom Coupe',
      'Phantom Drophead Coupe',
      'Phantom EWB',
      'Silver Dawn',
      'Silver Seraph',
      'Silver Spirit II/Silver Spur',
      'Silver Spirit III/Spur III',
      'Silver Spirit/Silver Spur',
      'Silver Spirit/Spur/Mulsanne',
      'Silver Spur',
      'Silver Spur II Mpw Touring L',
      'Silver Spur III Limousine',
      'Silver Spur Limousine',
      'Silver Spur Park Ward',
      'Silver Spur/Silver Dawn',
      'Spirit III/Spur III/Dawn',
      'Turbo R',
      'Turbo R/Rl Bklds Turbo/LWB',
      'Turbo R/Turbo R(lwb)',
      'Turbo R/Turbo Rl',
      'Wraith',
      'Wraith Black Badge',
    ],
  },
  {
    'brand': 'Roush Performance',
    'models': [
      'Boss F150 Super Cab 2WD',
      'F150 Pickup 2WD',
      'F150 Pickup 4WD',
      'F150 Raptor Pickup 4WD',
      'Stage 3 F-150 2WD',
      'Stage 3 F-150 4WD',
      'Stage 3 F-150 Supercab 2WD',
      'Stage 3 F-150 Supercab 4WD',
      'Stage 3 F-150 Supercrew 2WD',
      'Stage 3 F-150 Supercrew 4WD',
      'Stage 3 F150 Regular Cab 2WD',
      'Stage 3 F150 Regular Cab 4WD',
      'Stage 3 F150 Super Cab 2WD',
      'Stage 3 F150 Super Cab 4WD',
      'Stage 3 F150 Super Crew 2WD',
      'Stage 3 F150 Super Crew 4WD',
      'Stage 3 Mustang',
      'Stage 3 Mustang Coupe',
    ],
  },
  {
    'brand': 'Ruf Automobile Gmbh',
    'models': [
      'Ruf',
    ],
  },
  {
    'brand': 'S and S Coach Company  E.p. Dutton',
    'models': [
      'Funeral Coach  2WD',
    ],
  },
  {
    'brand': 'SRT',
    'models': [
      'Viper',
    ],
  },
  {
    'brand': 'STI',
    'models': [
      'S209',
    ],
  },
  {
    'brand': 'Saab',
    'models': [
      '9-2x Wagon AWD',
      '9-3',
      '9-3 Aero Sedan AWD',
      '9-3 Aero SportCombi AWD',
      '9-3 Convertible',
      '9-3 Sedan AWD',
      '9-3 Sport Sedan',
      '9-3 SportCombi',
      '9-3 Viggen',
      '9-3 Viggen Convertible',
      '9-3X SportCombi AWD',
      '9-4X AWD',
      '9-4X FWD',
      '9-5',
      '9-5 Sedan',
      '9-5 Sedan AWD',
      '9-5 SportCombi',
      '9-5 Wagon',
      '9-7X AWD',
      '900',
      '900 Convertible',
      '9000',
      '900S',
      '900S Convertible',
      '900SE',
      '900SE Convertible',
      'Convertible',
    ],
  },
  {
    'brand': 'Saleen',
    'models': [
      'Mustang',
      'Mustang S351',
      'SSC',
    ],
  },
  {
    'brand': 'Saleen Performance',
    'models': [
      'F150 Supercharged',
      'S281 Family',
      'S331 Family',
    ],
  },
  {
    'brand': 'Saturn',
    'models': [
      'Astra 2DR Hatchback',
      'Astra 4DR Hatchback',
      'Aura',
      'Aura Hybrid',
      'Ion',
      'L100/200',
      'L200',
      'L300',
      'LS',
      'LW',
      'LW200',
      'LW300',
      'Outlook AWD',
      'Outlook FWD',
      'Relay AWD',
      'Relay FWD',
      'SC',
      'SKY',
      'SL',
      'SW',
      'Vue AWD',
      'Vue FWD',
      'Vue Hybrid',
    ],
  },
  {
    'brand': 'Scion',
    'models': [
      'FR-S',
      'iA',
      'iM',
      'iQ',
      'iQ EV',
      'tC',
      'xA',
      'xB',
      'xD',
    ],
  },
  {
    'brand': 'Shelby',
    'models': [
      'Mustang GT',
    ],
  },
  {
    'brand': 'Spyker',
    'models': [
      'C8',
      'C8 Aileron',
      'C8 Double 12 S',
      'C8 Double 12 Spyder',
      'C8 Laviolette',
      'C8 Spyder',
      'C8 Spyder Wide Body',
    ],
  },
  {
    'brand': 'Sterling',
    'models': [
      '825',
      '827',
    ],
  },
  {
    'brand': 'Subaru',
    'models': [
      '3 Door 4WD Turbo',
      'Ascent',
      'Ascent Limited/Touring',
      'B9 Tribeca AWD',
      'BRZ',
      'BRZ tS',
      'Baja AWD',
      'Brat 4WD',
      'Crosstrek AWD',
      'Crosstrek Hybrid AWD',
      'Forester AWD',
      'Hatchback',
      'Hatchback 4WD',
      'Impreza',
      'Impreza 4-Door',
      'Impreza 5-Door',
      'Impreza AWD',
      'Impreza Sport 4-Door',
      'Impreza Sport 5-Door',
      'Impreza Sport AWD',
      'Impreza Wagon',
      'Impreza Wagon AWD',
      'Impreza Wagon/Outback SPT AWD',
      'Impreza Wagon/Outback Sport',
      'Impreza Wagon/Outback Sport AWD',
      'Justy',
      'Justy 4WD',
      'Justy AWD',
      'Legacy',
      'Legacy 4WD',
      'Legacy 4WD Turbo',
      'Legacy AWD',
      'Legacy AWD (incl. Outback)',
      'Legacy AWD Turbo',
      'Legacy Turbo',
      'Legacy Wagon',
      'Legacy Wagon 4WD',
      'Legacy Wagon AWD',
      'Legacy Wagon AWD (incl. Outback)',
      'Legacy Wagon AWD Turbo',
      'Legacy/Outback AWD',
      'Legacy/Outback Wagon AWD',
      'Loyale',
      'Loyale 4WD',
      'Loyale 4WD Turbo',
      'Loyale AWD',
      'Loyale Wagon',
      'Loyale Wagon 4WD',
      'Loyale Wagon 4WD Turbo',
      'Loyale Wagon AWD',
      'Outback AWD',
      'Outback Wagon AWD',
      'RX Turbo',
      'SVX',
      'SVX AWD',
      'Sedan',
      'Sedan 4WD',
      'Sedan/3 Door',
      'Sedan/3 Door 4WD',
      'Sedan/3Door 4WD Turbo',
      'Tribeca AWD',
      'WRX',
      'WRX STI Type RA',
      'Wagon',
      'Wagon 4WD',
      'Wagon 4WD Turbo',
      'XT',
      'XT 4WD',
      'XT-DL',
      'XV Crosstrek AWD',
      'XV Crosstrek Hybrid AWD',
    ],
  },
  {
    'brand': 'Superior Coaches Div E.p. Dutton',
    'models': [
      'Funeral Coach  2WD',
    ],
  },
  {
    'brand': 'Suzuki',
    'models': [
      'Aerio',
      'Aerio 4WD',
      'Aerio AWD',
      'Aerio SX',
      'Aerio SX 4WD',
      'Aerio SX AWD',
      'Equator 2WD',
      'Equator 4WD',
      'Esteem',
      'Esteem Wagon',
      'Forenza',
      'Forenza Wagon',
      'Forsa',
      'Forsa Turbo',
      'Grand Vitara',
      'Grand Vitara 4Door 2WD',
      'Grand Vitara 4Door 4WD',
      'Grand Vitara 4WD',
      'Grand Vitara AWD',
      'Grand Vitara XL7',
      'Grand Vitara XL7 4WD',
      'Kizashi',
      'Kizashi AWD',
      'Kizashi S',
      'Kizashi S AWD',
      'Reno',
      'SA310',
      'SJ 410 4WD',
      'SJ 410V 4WD',
      'SJ410K P/U 4WD',
      'SW',
      'SX4',
      'SX4 AWD',
      'SX4 Sedan',
      'SX4 Sport',
      'SX4 Sport/Anniversary Edition',
      'Samurai',
      'Samurai 2WD',
      'Samurai Convertible',
      'Samurai Hardtop',
      'Samurai Hardtop 4WD',
      'Samurai Soft-top 4WD',
      'Sidekick 2Door 2WD',
      'Sidekick 2Door 4WD',
      'Sidekick 2Door RWD',
      'Sidekick 2WD',
      'Sidekick 4Door 2WD',
      'Sidekick 4Door 4WD',
      'Sidekick 4Door RWD',
      'Sidekick Convertible 4WD',
      'Sidekick Hardtop 2WD',
      'Sidekick Hardtop 4WD',
      'Sidekick Soft-top 4WD',
      'Sidekick Sport 2WD',
      'Sidekick Sport 4WD',
      'Sidekick Sport RWD',
      'Swift',
      'Swift GA',
      'Swift GT',
      'Swift GTI',
      'Swift x',
      'Verona',
      'Vitara 2 Door',
      'Vitara 2 Door 4WD',
      'Vitara 2Door',
      'Vitara 2Door 2WD',
      'Vitara 2Door 4WD',
      'Vitara 4 Door',
      'Vitara 4 Door 4WD',
      'Vitara 4Door',
      'Vitara 4Door 2WD',
      'Vitara 4Door 4WD',
      'X-90 2WD',
      'X-90 4WD',
      'X-90 RWD',
      'XL7 AWD',
      'XL7 FWD',
    ],
  },
  {
    'brand': 'TVR Engineering Ltd',
    'models': [
      'TVR 280i Convertible',
      'TVR 280i Coupe',
      'TVR 280i/350i Convertible',
      'TVR 280i/350i Coupe',
    ],
  },
  {
    'brand': 'Tecstar, LP',
    'models': [
      'Foose F150 Regular Cab 2WD',
      'Foose F150 Regular Cab 4WD',
      'Foose F150 Super Cab 2WD',
      'Foose F150 Super Cab 4WD',
      'Foose F150 Super Crew 2WD',
      'Foose F150 Super Crew 4WD',
    ],
  },
  {
    'brand': 'Tesla',
    'models': [
      'Model 3 Long Range',
      'Model 3 Long Range  AWD',
      'Model 3 Long Range  AWD Performance',
      'Model 3 Long Range AWD',
      'Model 3 Long Range AWD Performance',
      'Model 3 Long Range Performance AWD (18in)',
      'Model 3 Long Range Performance AWD (19in)',
      'Model 3 Long Range Performance AWD (20in)',
      'Model 3 Mid Range',
      'Model 3 Performance AWD',
      'Model 3 Standard Range',
      'Model 3 Standard Range Plus',
      'Model S',
      'Model S (40 kW-hr battery pack)',
      'Model S (60 kW-hr battery pack)',
      'Model S (70 kW-hr battery pack)',
      'Model S (75 kW-hr battery pack)',
      'Model S (85 kW-hr battery pack)',
      'Model S (90 kW-hr battery pack)',
      'Model S 100D',
      'Model S 75D',
      'Model S 75kWh',
      'Model S AWD (85 kW-hr battery pack)',
      'Model S AWD - 100D',
      'Model S AWD - 60D',
      'Model S AWD - 70D',
      'Model S AWD - 75D',
      'Model S AWD - 85D',
      'Model S AWD - 90D',
      'Model S AWD - P100D',
      'Model S AWD - P85D',
      'Model S AWD - P90D',
      'Model S Long Range',
      'Model S Long Range Plus',
      'Model S P100D',
      'Model S Performance (19in Wheels)',
      'Model S Performance (21in Wheels)',
      'Model S Standard Range',
      'Model X 100D',
      'Model X 75D',
      'Model X AWD - 100D',
      'Model X AWD - 60D',
      'Model X AWD - 75D',
      'Model X AWD - 90D',
      'Model X AWD - P100D',
      'Model X AWD - P90D',
      'Model X Long Range',
      'Model X Long Range Plus',
      'Model X P100D',
      'Model X Performance (20in Wheels)',
      'Model X Performance (22in Wheels)',
      'Model X Standard Range',
      'Model Y Long Range AWD',
      'Model Y Performance AWD',
      'Model Y Performance AWD (21in Wheels)',
    ],
  },
  {
    'brand': 'Texas Coach Company',
    'models': [
      '500 SEC',
      '500SE',
      '500SEL',
      '500SL',
    ],
  },
  {
    'brand': 'Toyota',
    'models': [
      '1-Ton Truck 2WD',
      '4Runner 2WD',
      '4Runner 4WD',
      '86',
      'Avalon',
      'Avalon AWD',
      'Avalon Hybrid',
      'Avalon Hybrid XLE',
      'Avalon TRD',
      'Avalon XLE',
      'C-HR',
      'Cab Chassis 2WD',
      'Cab/Chassis',
      'Cab/Chassis 2WD',
      'Camry',
      'Camry AWD LE/SE',
      'Camry AWD XLE/XSE',
      'Camry CNG',
      'Camry Hybrid',
      'Camry Hybrid LE',
      'Camry Hybrid SE/XLE/XSE',
      'Camry Hybrid XLE',
      'Camry Hybrid XLE/SE',
      'Camry LE/SE',
      'Camry Solara',
      'Camry Solara Convertible',
      'Camry TRD',
      'Camry Wagon',
      'Camry XLE/XSE',
      'Camry XSE',
      'Cargo Van 2WD',
      'Cargo Van 4WD',
      'Celica',
      'Celica Convertible',
      'Celica Supra',
      'Corolla',
      'Corolla APEX',
      'Corolla All-Trac Wagon',
      'Corolla FX',
      'Corolla Hatchback',
      'Corolla Hatchback XSE',
      'Corolla Hybrid',
      'Corolla LE Eco',
      'Corolla Sport',
      'Corolla Wagon',
      'Corolla XLE',
      'Corolla XSE',
      'Corolla iM',
      'Cressida',
      'Cressida Wagon',
      'Echo',
      'FJ Cruiser 2WD',
      'FJ Cruiser 4WD',
      'GR Supra',
      'Highlander',
      'Highlander 2WD',
      'Highlander 4WD',
      'Highlander AWD',
      'Highlander AWD LE',
      'Highlander Hybrid',
      'Highlander Hybrid 2WD',
      'Highlander Hybrid 4WD',
      'Highlander Hybrid AWD',
      'Highlander Hybrid AWD LE Plus',
      'Highlander Hybrid AWD LTD/PLAT',
      'Highlander LE/XLE/SE/LTD',
      'Land Cruiser Wagon 4WD',
      'MR2',
      'Matrix',
      'Matrix AWD',
      'Paseo',
      'Paseo Convertible',
      'Previa',
      'Previa All-Trac',
      'Prius',
      'Prius AWD',
      'Prius Eco',
      'Prius Plug-in Hybrid',
      'Prius Prime',
      'Prius c',
      'Prius v',
      'RAV4',
      'RAV4 2WD',
      'RAV4 4WD',
      'RAV4 AWD',
      'RAV4 AWD LE',
      'RAV4 AWD TRD OFFROAD',
      'RAV4 EV',
      'RAV4 Hybrid  AWD',
      'RAV4 Hybrid AWD',
      'RAV4 LE/XLE',
      'RAV4 Limited AWD',
      'RAV4 Limited AWD/SE AWD',
      'RAV4 Prime 4WD',
      'RAV4 Soft Top 2WD',
      'RAV4 Soft Top 4WD',
      'Sequoia 2WD',
      'Sequoia 4WD',
      'Sequoia 4WD FFV',
      'Sienna 2WD',
      'Sienna 4WD',
      'Sienna AWD',
      'Sienna Hybrid 2WD',
      'Sienna Hybrid AWD',
      'Starlet',
      'Supra',
      'T100 2WD',
      'T100 4WD',
      'Tacoma 2WD',
      'Tacoma 4WD',
      'Tacoma 4WD D-CAB MT TRD-ORP/PRO',
      'Tacoma 4WD Double Cab',
      'Tacoma 4WD Double Cab Off-Road',
      'Tacoma 4WD TRD PRO',
      'Tercel',
      'Tercel EZ',
      'Tercel Wagon',
      'Tercel Wagon 2WD',
      'Tercel Wagon 4WD',
      'Truck 2WD',
      'Truck 2WD/T100 2WD',
      'Truck 4WD',
      'Truck 4WD/T100 4WD',
      'Tundra 2WD',
      'Tundra 2WD FFV',
      'Tundra 4WD',
      'Tundra 4WD FFV',
      'Van 2WD',
      'Van 2WD (cargo)',
      'Van 2WD (passenger)',
      'Van 4WD',
      'Van 4WD (cargo)',
      'Van 4WD (passenger)',
      'Venza',
      'Venza 4WD',
      'Venza AWD',
      'Yaris',
      'Yaris iA',
    ],
  },
  {
    'brand': 'VPG',
    'models': [
      'MV-1',
      'MV-1 CNG',
    ],
  },
  {
    'brand': 'Vector',
    'models': [
      'Avtech SC / M12',
      'W8',
    ],
  },
  {
    'brand': 'Vixen Motor Company',
    'models': [
      '21 TD',
    ],
  },
  {
    'brand': 'Volga Associated Automobile',
    'models': [
      'Niva',
    ],
  },
  {
    'brand': 'Volkswagen',
    'models': [
      'Arteon',
      'Arteon 4motion',
      'Atlas',
      'Atlas 4motion',
      'Atlas Cross Sport',
      'Atlas Cross Sport 4motion',
      'Beetle',
      'Beetle Convertible',
      'Beetle Dune',
      'Beetle Dune Convertible',
      'CC',
      'CC 4motion',
      'Cabrio',
      'Cabriolet',
      'Corrado',
      'Corrado SLC',
      'Eos',
      'Eurovan',
      'Eurovan Camper',
      'Fox',
      'Fox GL Wagon',
      'Fox Wagon',
      'GTI',
      'GTI 16v',
      'GTI VR6',
      'GTI/Golf GT',
      'Golf',
      'Golf Alltrack',
      'Golf III',
      'Golf III / GTI',
      'Golf R',
      'Golf SportWagen',
      'Golf SportWagen 4motion',
      'Golf/GTI',
      'ID.4 1st',
      'ID.4 Pro S',
      'Jetta',
      'Jetta GLI',
      'Jetta GLI 16v',
      'Jetta GLI/Wolfsburg Edition',
      'Jetta GLX',
      'Jetta Hybrid',
      'Jetta III',
      'Jetta III GLX',
      'Jetta SportWagen',
      'Jetta Wagon',
      'New Beetle',
      'New Beetle Convertible',
      'New GTI',
      'New Golf',
      'New Jetta',
      'Passat',
      'Passat 4motion',
      'Passat Syncro',
      'Passat Wagon',
      'Passat Wagon 4motion',
      'Passat Wagon Syncro',
      'Phaeton',
      'Quantum',
      'Quantum Syncro Wagon',
      'Quantum Wagon',
      'R32',
      'Rabbit',
      'Rabbit Convertible',
      'Routan',
      'Routan FWD',
      'Scirocco',
      'Scirocco 16v',
      'Tiguan',
      'Tiguan 4motion',
      'Tiguan Limited',
      'Tiguan Limited 4motion',
      'Touareg',
      'Touareg Hybrid',
      'Vanagon 2WD',
      'Vanagon Syncro 4WD',
      'Vanagon/Camper 2WD',
      'e-Golf',
    ],
  },
  {
    'brand': 'Volvo',
    'models': [
      '240',
      '240 DL/240 GL',
      '240 DL/240 GL Wagon',
      '240 DL/GL/Turbo',
      '240 DL/GL/Turbo Wagon',
      '240 DL/GL/turbo',
      '240 DL/GL/turbo Wagon',
      '240 Wagon',
      '740',
      '740 16-Valve',
      '740 16-Valve Wagon',
      '740 Wagon',
      '740/760',
      '740/760 Wagon',
      '760',
      '760 GLE',
      '760 Wagon',
      '780',
      '850',
      '850 Wagon',
      '940',
      '940 GLE 16-Valve',
      '940 GLE 16-Valve Wagon',
      '940 SE',
      '940 SE Wagon',
      '940 Turbo',
      '940 Turbo Wagon',
      '940 Wagon',
      '960',
      '960 Wagon',
      '960 Wagon/V90',
      '960/S90',
      'C30 FWD',
      'C70',
      'C70 Convertible',
      'C70 Coupe',
      'C70 FWD',
      'Coupe',
      'New C70 Convertible',
      'S40 AWD',
      'S40 FWD',
      'S60',
      'S60 AWD',
      'S60 AWD PHEV',
      'S60 CC AWD',
      'S60 FWD',
      'S60 Inscription AWD',
      'S60 Inscription FWD',
      'S60 PoleStar AWD',
      'S60 Polestar AWD',
      'S60 R AWD',
      'S70',
      'S70 AWD',
      'S80',
      'S80 AWD',
      'S80 FWD',
      'S80/S80 Executive',
      'S80/S80 Premier',
      'S90',
      'S90 AWD',
      'S90 AWD PHEV',
      'S90 FWD',
      'V40',
      'V50 AWD',
      'V50 FWD',
      'V60 AWD',
      'V60 AWD PHEV',
      'V60 CC AWD',
      'V60 CC FWD',
      'V60 FWD',
      'V60 PoleStar AWD',
      'V60 Polestar AWD',
      'V70 AWD',
      'V70 FWD',
      'V70 R AWD',
      'V70 XC AWD',
      'V90',
      'V90 AWD',
      'V90 CC AWD',
      'V90 FWD',
      'XC40 AWD',
      'XC40 AWD BEV',
      'XC40 FWD',
      'XC60 AWD',
      'XC60 AWD PHEV',
      'XC60 FWD',
      'XC70 AWD',
      'XC70 FWD',
      'XC90 AWD',
      'XC90 AWD PHEV',
      'XC90 FWD',
    ],
  },
  {
    'brand': 'Wallace Environmental',
    'models': [
      'Wetl 190E',
      'Wetl 190E 2.3',
      'Wetl 300 CE',
      'Wetl 300 E',
      'Wetl 300 GE',
      'Wetl 300 SL',
      'Wetl 300 TE',
      'Wetl 412',
      'Wetl 500 SE',
      'Wetl 500 SEC',
      'Wetl 500 SEL',
      'Wetl 500 SL',
      'Wetl 560 SEC',
      'Wetl 560 SEL',
      'Wetl 560 SL',
      'Wetl 600 SEL',
      'Wetl 850I',
      'Wetl Testarossa',
    ],
  },
  {
    'brand': 'Yugo',
    'models': [
      'GV',
      'GV Plus/GV/Cabrio',
      'GV/GVX',
      'Gy/yugo GVX',
    ],
  },
  {
    'brand': 'smart',
    'models': [
      'EQ fortwo (convertible)',
      'EQ fortwo (coupe)',
      'fortwo cabriolet',
      'fortwo convertible',
      'fortwo coupe',
      'fortwo electric drive cabriolet',
      'fortwo electric drive convertible',
      'fortwo electric drive coupe',
    ],
  },
];
