import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Logo from 'components/Logo';

const Incentive = () => {
  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div>
          <Box marginBottom={4} marginTop={4}>
            <Logo width="100%" withETCoalition={true} />
          </Box>
          <Typography component="h5" variant="h5" color="primary" gutterBottom>
            Check if You Qualify for the MBUF Study Incentives
          </Typography>
          <p>
            By participating in MBUF Study, you may be qualified to earn up to $100. If you qualify,
            the incentive amounts will be offered via electronic gift card, usable at over 110
            retailers including Amazon, Uber Eats, Macys, H&amp;M, Foot Locker, BestBuy and more.
          </p>
          <p>
            You may qualify for the incentives if you meet the below criteria:
            <ol>
              <li>You are not employed by a local, state or federal government agency.</li>
              <li>You are not employed by Transurban or any of its partners and affiliates taking part in the MBUF Study.</li>
            </ol>
          </p>
          <p>
            If you meet the above criteria, you are eligible. The full $100 will be offered in two
            phases and participants must complete the requirements of each step, outlined below.
          </p>
          <p>
            The first $25 will be sent once you:
            <ul>
              <li>Complete the final enrollment form</li>
              <li>Plug-in the OBDII device (if provided) or provide your first odometer mileage reading (if device not provided)</li>
              <li>Complete a study entry survey</li>
            </ul>
          </p>
          <p>
            The second payment of $75 will be sent to you at the end of the study, once you:
            <ul>
              <li>Participate in the study for the full duration (concluding October 31, 2021)</li>
              <li>Send back the OBDII device (if provided) or provide your last odometer mileage reading (if device not provided)</li>
              <li>Complete the study exit survey</li>
            </ul>
          </p>
        </div>
      </Container>
    </>
  );
};

Incentive.propTypes = {};
export default Incentive;
