import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  listParticipants,
} from 'graphql/queries';
import {
  generateStatements,
} from 'graphql/mutations';
import { sortBy } from 'utilities/sorting';
import { asyncRetryMutation, asyncListAll } from 'utilities/graph';
import MultiSelect from 'components/MultiSelect';

const TIME_ZONE = 'America/New_York';
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export default function StatementRequest({ onUpdate }) {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [from, setFrom] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('week').format('YYYY-MM-DD'));
  const [note, setNote] = useState('');
  const [shouldSendEmailToParticipant, setShouldSendEmailToParticipant] = useState(false);

  useEffect(() => {
    (async () => {
      const allParticipants = (await asyncListAll(listParticipants))
        .filter(({ participantPilotProgramId }) => participantPilotProgramId)
        .sort(sortBy('lastName'))
        .sort(sortBy('firstName'))
        .map((item) => {
          item.fullName = `${item.firstName} ${item.lastName}`;
          item.displayName = `${item.fullName} (${item.email})`;
          return item;
        });

      setParticipants(allParticipants);
    })();
  }, []);

  const submit = async () => {
    try {
      setIsSubmitting(true);
      const payload = {
        input: {
          from: moment(from).tz(TIME_ZONE).startOf('day').toISOString(),
          to: moment(to).tz(TIME_ZONE).endOf('day').toISOString(),
          participantUsernames: selectedParticipants.map(({ username }) => username),
          note,
          shouldSendEmailToParticipant,
        },
      };
      console.log(payload);
      await asyncRetryMutation(generateStatements, payload);
      if (onUpdate) {
        onUpdate(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Generate Statements
          </Typography>
          <MultiSelect
            label="Participants"
            options={participants}
            optionsLabelKey="displayName"
            value={selectedParticipants}
            onChange={(e) => setSelectedParticipants(e.target.value)}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs = { 12 } sm = { 6 }>
          <TextField
            name="from"
            type="date"
            variant="outlined"
            fullWidth
            label="From"
            defaultValue={from}
            onChange={(e) => setFrom(e.target.value)}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs = { 12 } sm = { 6 } >
          <TextField
            name="to"
            type="date"
            variant="outlined"
            fullWidth
            label="To"
            defaultValue={to}
            onChange={(e) => setTo(e.target.value)}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="note"
            type="text"
            variant="outlined"
            fullWidth
            label="Note"
            defaultValue={note}
            onChange={(e) => setNote(e.target.value)}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox
                  checked={shouldSendEmailToParticipant}
                  onChange={(e) => setShouldSendEmailToParticipant(!shouldSendEmailToParticipant)}
                  name="shouldSendEmailToParticipant"
                  color="primary"
                />}
                label="Also send copies to participants."
              />
            </FormGroup>
          </FormControl>

        </Grid>
        <Grid container item xs = { 12 } alignItems = "center" justify = "center" spacing={2}>
          <Grid item xs={6} align="center">
            <Button
              type="submit"
              size="large"
              variant="outlined"
              disabled={isSubmitting}
              onClick={() => onUpdate(false)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} align="center">
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting }
              onClick={submit}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}


StatementRequest.propTypes = {
  onUpdate: PropTypes.func,
};
