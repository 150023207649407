import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { asyncRetryMutation } from 'utilities/graph';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import ControlledInput from 'components/Form/ControlledInput';

import {
  validateVehicleVin,
} from 'utilities/vehicle';
import {
  vehicleMakesAndModels,
  vehicleTypes,
  states,
} from 'utilities/constants';
import {
  shellVehicle,
} from 'utilities/constants/shellModels';
import { asyncGet } from 'utilities/graph';
import {
  getEPAVehiclesByMakeByYear,
} from 'graphql/queries';
import {
  updateParticipant,
} from 'graphql/mutations';

const RegisterVehicle = ({
  username,
  vehicle = shellVehicle,
  onCompleteStep,
  onPreviousStep,
  options = {},
}) => {
  const classes = useStyles();

  // registration & participant management screens
  const {
    submitCopy = 'Continue',
    previousStepCopy = 'Back',
  } = options;

  // form states
  const { control, errors, handleSubmit, formState, watch, setValue } = useForm();
  const { isSubmitting } = formState;
  const watchMake = watch('make');
  const watchYear = watch('year');
  const watchModel = watch('model');
  const watchType = watch('type');

  const [error, setError] = useState(false);
  const [eligibilityErrors, setEligibilityErrors] = useState([]);
  const [epaVehicles, setEpaVehicles] = useState([]);

  useEffect(() => {
    if (!watchMake || !watchYear || watchYear.length !== 4) {
      return;
    }
    (async () => {
      const {
        data: {
          getEPAVehiclesByMakeByYear: {
            items,
          },
        },
      } = await asyncGet(getEPAVehiclesByMakeByYear, {
        make: watchMake.toUpperCase(),
        year: {
          eq: watchYear,
        },
      }, { bypassCache: true });

      // unique models
      const distinct = [];
      const unique = [];
      for (let i = 0; i < items.length; i++) {
        if (!unique[items[i].model]) {
          distinct.push(items[i]);
          unique[items[i].model] = true;
        }
      }

      setEpaVehicles(distinct);
    })();
  }, [watchMake, watchYear]);

  let modelLabel = 'Model';
  if (!watchYear && !watchMake) {
    modelLabel = 'Please enter the year and make of the vehicle';
  } else if (!watchYear) {
    modelLabel = 'Please enter the year of the vehicle';
  } else if (!watchMake) {
    modelLabel = 'Please enter the make of the vehicle';
  }

  const inputs = [{
    type: 'number',
    name: 'year',
    defaultValue: '', // user must always re-do make year model
    label: 'Year',
    required: true,
    invalidText: 'Vehicle year is required',
  }, {
    type: 'select',
    name: 'make',
    defaultValue: '', // user must always re-do make year model
    label: 'Make',
    required: true,
    options: vehicleMakesAndModels.map((manufacturer) => {
      return {
        value: manufacturer.brand,
        label: manufacturer.brand,
      };
    }),
    invalidText: 'Vehicle make is required',
  }, {
    type: 'select',
    name: 'model',
    defaultValue: '', // user must always re-do make year model
    label: modelLabel,
    required: true,
    disabled: !watchYear || !watchMake || epaVehicles.length === 0,
    errors: (watchYear && watchMake && epaVehicles.length === 0) ? { model: true } : {},
    helperText: `No models found for a ${watchYear} ${watchMake}`,
    options: epaVehicles.map((vehicle) => {
      return {
        value: vehicle,
        label: `${vehicle.model}`,
        testValue: vehicle.id,
      };
    }).sort((a, b) => a.label > b.label ? 1 : -1),
    invalidText: (watchYear && watchMake && epaVehicles.length === 0) ? `No models found for a ${watchYear} ${watchMake}` : 'Vehicle model is required',
  }, {
    type: 'select',
    name: 'type',
    defaultValue: vehicle.type.replace(/\b\w/g, (c) => {
      return c.toUpperCase();
    }),
    label: 'Vehicle Type',
    required: true,
    options: vehicleTypes.map((type) => {
      return {
        value: type,
        label: type,
      };
    }),
    invalidText: 'Vehicle type is required',
  }, {
    type: 'select',
    name: 'registrationState',
    defaultValue: vehicle.registrationState,
    label: 'Registered State',
    required: true,
    options: Object.keys(states).map((state) => {
      return {
        value: state,
        label: states[state],
      };
    }),
    invalidText: 'State of registration is required',
  }, {
    type: 'text',
    name: 'licensePlate',
    defaultValue: vehicle.licensePlate,
    label: 'License Plate No.',
    required: true,
    invalidText: 'License plate is required',
    setValue,
  }];

  if (watchYear >= 1996 && watchYear <= 2006) {
    inputs.push({
      type: 'text',
      name: 'vin',
      label: 'Vehicle VIN',
      required: true,
      validate: validateVehicleVin,
      invalidText: 'Please provide a valid Vehicle VIN',
      setValue,
    });
  }

  function validateEligibility() {
    const errors = [];
    if (watchYear < 1996) {
      errors.push('The vehicle was manufactured out of the eligible date range');
    }

    if (watchType === 'Motorcycle') {
      errors.push('Motorcycles are not eligible to participate');
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  }

  async function handleRegisterVehicle({
    make,
    model,
    year,
    type,
    licensePlate,
    registrationState,
    vin,
  }) {
    const { isValid, errors } = validateEligibility();
    if (!isValid) {
      setEligibilityErrors(errors);
      return;
    }

    const epaVehicle = Object.assign({}, watchModel);

    try {
      let vehicleId;

      if (vehicle.id) {
        const updateInput = {
          username,
          id: vehicle.id,
          vin,
          make: epaVehicle.make,
          model: epaVehicle.model,
          year: epaVehicle.year,
          type: type.toLowerCase(),
          licensePlate,
          registrationState,
          isPrimary: true,
          beginningOdometerReading: vehicle.beginningOdometerReading,
          currentOdometerReading: vehicle.currentOdometerReading,
          mileage: vehicle.mileage,
          gotoll: vehicle.gotoll,
          fuelTaxCreditCents: vehicle.fuelTaxCreditCents,
          mileageUserFeeCents: vehicle.mileageUserFeeCents,
          mroId: vehicle.mroId || 'N/A',
          epaVehicleId: epaVehicle.id,
          epaVehicleCombinedKpl: epaVehicle.mpgCombined,
          epaVehicleCombinedMpg: epaVehicle.kplCombined,
          logs: vehicle.logs,
          reports: vehicle.reports,
          locations: vehicle.locations,
          createdBy: vehicle.createdBy,
          updatedBy: vehicle.updatedBy,
        };
        const response = await asyncRetryMutation( /* GraphQL */ `
          mutation UpdateVehicle(
            $input: UpdateVehicleInput!
            $condition: ModelVehicleConditionInput
          ) {
            updateVehicle(input: $input, condition: $condition) {
              username
              id
            }
          }
        `, {
          input: updateInput,
        });
        vehicleId = response.data.updateVehicle.id;

        await asyncRetryMutation(updateParticipant, {
          input: {
            username,
            mroDevicePreference: null,
            updatedBy: localStorage.getItem('ruc:username'),
          },
        });
      } else {
        const response = await asyncRetryMutation( /* GraphQL */ `
          mutation CreateVehicle(
            $input: CreateVehicleInput!
            $condition: ModelVehicleConditionInput
          ) {
            createVehicle(input: $input, condition: $condition) {
              username
              id
            }
          }
        `, {
          input: {
            username,
            make: epaVehicle.make,
            model: epaVehicle.model,
            year: epaVehicle.year,
            type: type.toLowerCase(),
            licensePlate,
            registrationState,
            vin,
            mroId: 'N/A',
            isPrimary: true,
            epaVehicleId: epaVehicle.id,
            epaVehicleCombinedMpg: epaVehicle.mpgCombined,
            epaVehicleCombinedKpl: epaVehicle.kplCombined,
            createdBy: localStorage.getItem('ruc:username'),
            updatedBy: localStorage.getItem('ruc:username'),
          },
        });
        vehicleId = response.data.createVehicle.id;
      }

      onCompleteStep({
        id: vehicleId,
        make,
        model,
        year,
        type: type.toLowerCase(),
        licensePlate,
        registrationState,
      });
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">Vehicle Information</Typography>
      {eligibilityErrors.length === 0 ? (
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleRegisterVehicle)}
          noValidate
        >
          <Grid container spacing={2}>
            {inputs.splice(0, 2).map(((input, index) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            }))}
            {inputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6}>
              <Button
                type="button"
                size="large"
                fullWidth
                variant="contained"
                color="inherit"
                className={classes.secondaryAction}
                onClick={() => {
                  onPreviousStep();
                }}
              >
                {previousStepCopy}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {submitCopy}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <div className={classes.paper}>
          <strong id="eligibility-failure">Unfortunately your vehicle does not meet the eligibility requirements of this MBUF Study:</strong>
          <ul>
            {eligibilityErrors.map((error, index) => {
              return (<li id={`eligibility-reason-${index}`} key={index}>{error}</li>);
            })}
          </ul>
          <Button
            type="button"
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={() => {
              setEligibilityErrors([]);
            }}
          >
            Update Vehicle Info
          </Button>
        </div>
      )
      }
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterVehicle.propTypes = {
  username: PropTypes.string,
  vehicle: PropTypes.object,
  onCompleteStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  options: PropTypes.object,
};

export default RegisterVehicle;
