import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StopIcon from '@material-ui/icons/Stop';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const CORDON_COLORS = [
  '#21618C',
  '#CD6155',
  '#16A085',
  '#AF7AC5',
  '#F5B041',
  '#B03A2E',
  '#1D8348',
  '#515A5A',
  '#884EA0',
  '#16A085',
];

export default function CordonVisualizationList({ zones = [], onUpdate }) {
  const classes = useStyles();

  const [list, setList] = useState({});
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (!zones || zones.length === 0) return;
    console.log(zones);
    setList(zones.reduce((list, zone, index) => {
      const key = 'zone';
      list[key] = list[key] || [];
      list[key].push(zone);
      return list;
    }, {}));
    setChecked(zones.map(({ name }) => name));
  }, [zones]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    if (onUpdate) {
      onUpdate(newChecked);
    }
  };

  // const handleToggleAll = () => {
  //   const newChecked = checked.length > 0 ? [] : zones.map(({ name }) => name);
  //   console.log(newChecked);
  //   setChecked(newChecked);
  //   if (onUpdate) {
  //     onUpdate(newChecked);
  //   }
  // };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {/* <ListItem
        dense
        button
        onClick={handleToggleAll}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.length > 0}
            indeterminate={checked.length > 0 && checked.length !== zones.length}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText
          primary={`Select All`}
        />
      </ListItem> */}
      {Object.keys(list).map((key) => (
        <Fragment key={key}>
          <ListSubheader>{key}</ListSubheader>
          {list[key].map(({ name, description, color }, index) => {
            return (
              <ListItem
                key={index}
                role={undefined}
                // dense
                // button
                onClick={handleToggle(name)}
              >
                {/* <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(name) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon> */}
                <ListItemText
                  primary={`zone ${index+1}`}
                  secondary={description}
                />
                <ListItemSecondaryAction>
                  <StopIcon style={{ color: color || CORDON_COLORS[index] }} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </Fragment>
      ))}
    </List>
  );
}

CordonVisualizationList.propTypes = {
  zones: PropTypes.array,
  onUpdate: PropTypes.func,
};
