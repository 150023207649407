import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStyles } from './styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

import ControlledInput from 'components/Form/ControlledInput';
import {
  bulkImportMroDevices,
} from 'graphql/mutations';
import {
  asyncRetryMutation,
} from 'utilities/graph';

const BulkImportMRODevices = () => {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [result, setResult] = useState(false);

  // form states
  const { control, errors, handleSubmit, formState, register, reset } = useForm();
  const { isSubmitting } = formState;

  const inputs = [{
    type: 'file',
    accept: '.csv,.txt',
    name: 'shippingManifest',
    label: 'Manifest File',
    required: true,
    invalidText: 'A valid shipping manifest is required.',
    register,
    inputProps: {
      onChange: () => {
        setResult(false);
      },
    },
  }];

  const handleCreateMRO = async ({
    shippingManifest,
  }) => {
    const manifest = await toBase64String(shippingManifest[0]);
    const {
      data: {
        bulkImportMroDevices: {
          duplicates,
          registrations,
          notifications,
          errors,
        },
      },
    } = await asyncRetryMutation(bulkImportMroDevices, {
      input: {
        manifest,
        authorUsername: localStorage.getItem('ruc:username'),
      },
    });

    setResult({
      duplicates,
      registrations,
      notifications,
      errors,
    });

    reset({
      shippingManifest: null,
    });
  };

  function handleCloseError() {
    setError(false);
  }

  function toBase64String(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Bulk Associate MRO Devices
        </Typography>
        <p>Upload a shipment manifest in the appropriate CSV format.</p>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleCreateMRO)}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {inputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            Complete
          </Button>
        </form>
        {result && (
          <>
            <Alert severity="success" className={classes.alert}>
              There were {result.registrations} new devices registered.
            </Alert>
            <Alert severity="info" className={classes.alert}>
              <AlertTitle>Notifications</AlertTitle>
              <ul>
                <li>There were {result.duplicates} devices from the manifest that are already registered in the system.</li>
                {
                  result.notifications.map((notification, index) => {
                    return (
                      <li key={index}>
                        {notification.message}
                        {notification.username !== '' ? `, participant: ${notification.username}` : ''}
                        {notification.mroSerialNumber !== '' ? `, mro serial: ${notification.mroSerialNumber}` : ''}
                      </li>
                    );
                  })
                }
              </ul>
            </Alert>
            {result.errors.length > 0 && (
              <Alert severity="error" className={classes.alert}>
                <AlertTitle>Errors</AlertTitle>
                <ul>
                  {
                    result.errors.map((error, index) => {
                      return (
                        <li key={index}>
                          {error.message}
                          {error.username !== '' ? `, participant: ${error.username}` : ''}
                          {error.mroSerialNumber !== '' ? `, mro serial: ${error.mroSerialNumber}` : ''}
                          {error.vehicleId !== '' ? `, vehicle: ${error.vehicleId}` : ''}
                        </li>
                      );
                    })
                  }
                </ul>
              </Alert>
            )}
          </>
        )}
        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container >
  );
};

export default BulkImportMRODevices;
