import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Auth } from 'aws-amplify';

import { useStyles } from './commonStyles';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';

import ControlledInput from 'components/Form/ControlledInput';

const VerificationCode = ({ email, onVerifyEmail }) => {
  const classes = useStyles();

  // form states
  const { control, errors, handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);
  const [error, setError] = useState(false);

  const inputs = [{
    type: 'number',
    name: 'code',
    label: 'Verification Code',
    autoFocus: true,
    required: true,
    minLength: 6,
    maxLength: 6,
    invalidText: 'The six digit code sent to your email address',
  }];

  async function handleConfirmSignUp({ code }) {
    setIsSubmitting(true);
    try {
      const normalized = email.trim().toLowerCase();
      const response = await Auth.confirmSignUp(normalized, code);
      onVerifyEmail(response);
    } catch (e) {
      setError(e.message);
      setIsSubmitting(false);
    }
  }

  async function handleResendCode() {
    try {
      const normalized = email.trim().toLowerCase();
      await Auth.resendSignUp(normalized);
      setShowResendMessage(true);
    } catch (e) {
      setError(e.message);
    }
  }

  function handleCloseResendMessage() {
    setShowResendMessage(false);
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.centered}>
      <p>
        Please verify your email address with the code sent to:
      </p>
      <strong>{email}</strong>
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleConfirmSignUp)}
        noValidate
      >
        <Grid container spacing={2}>
          {inputs.map((input, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}
        </Grid>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          Confirm Email
        </Button>
        <Button
          type="button"
          size="large"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.secondaryAction}
          disabled={isSubmitting}
          onClick={(e) => {
            handleResendCode();
          }}
        >
          Send Code Again
        </Button>
      </form>
      <Snackbar
        open={showResendMessage}
        autoHideDuration={6000}
        onClose={handleCloseResendMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={handleCloseResendMessage}>
          Code Sent!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== false}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

VerificationCode.propTypes = {
  email: PropTypes.string,
  onVerifyEmail: PropTypes.func,
};

export default VerificationCode;
