import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import SignalCellularAltOutlinedIcon from '@material-ui/icons/SignalCellularAltOutlined';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import {
  listMileageReportingOptions,
  getParticipant,
} from 'graphql/queries';
import { asyncListAll, asyncGet } from 'utilities/graph';
import { mroDeviceFriendlyName } from 'utilities/constants/mroDevicePreferences';

import { useStyles } from './styles';

const MROInfo = ({ user }) => {
  const classes = useStyles();

  const [mro, setMro] = useState(null);
  const [participant, setParticipant] = useState({});
  const { username } = user;

  useEffect(() => {
    (async () => {
      try {
        const [mroRecords, participantRecord] = await Promise.all([
          asyncListAll(listMileageReportingOptions, {
            username,
          }),
          asyncGet(getParticipant, {
            username,
          }),
        ]);

        if (mroRecords && mroRecords[0]) {
          setMro(mroRecords[0]);
        }

        setParticipant(participantRecord.data.getParticipant);
      } catch (e) {
        // @todo surface error messaging
      }
    })();
  }, [username]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <SignalCellularAltOutlinedIcon color="inherit" />
        </Avatar>
        {mro ? (
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              My Device
            </Typography>
            <form className={classes.form} onSubmit={(e) => {
              e.preventDefault();
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="deviceSerialNumber"
                    name="deviceSerialNumber"
                    variant="outlined"
                    fullWidth
                    label="Device Serial Number"
                    defaultValue={mro.deviceSerialNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="model"
                    name="model"
                    variant="outlined"
                    fullWidth
                    label="Device Model"
                    defaultValue={mro.model}
                  />
                </Grid>
                {participant && (
                  <Grid item xs={12}>
                    <TextField
                      id="mroDevicePreference"
                      name="mroDevicePreference"
                      variant="outlined"
                      fullWidth
                      label="Device Type Preference"
                      value={mroDeviceFriendlyName(participant.mroDevicePreference)}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
        ) : (
          <div className={classes.registeredDevices}>
            <Typography component="h1" variant="h5">
              Device Details
            </Typography>
            <Card className={classes.notFound}>
              <p>It looks like you have no registered MRO.<br /><br />This may be due to the following reasons:</p>
              <ul>
                <li>Manual odometer capture (manual option) being the only compatible technology for your vehicle type.</li>
                <li>Your device is in the process of registering with the MBUF Study system.  Please try logging back in to your account in 15 minutes.</li>
              </ul>
              <p>If the error persists, please contact customer service for assistance.</p>
            </Card>
          </div>
        )}
      </div>
    </Container >
  );
};

MROInfo.propTypes = {
  user: PropTypes.object,
};

export default MROInfo;
