import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NotificationsTable from 'pages/Admin/components/NotificationsTable';

import { asyncListAll } from 'utilities/graph';
// import {
//   listNotifications,
// } from 'graphql/queries';
import { sortBy } from 'utilities/sorting';
import DateRangeSelector from 'pages/Admin/components/DateRangeSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const onSubmit = async ({ from, to }) => {
    setIsLoading(true);
    const notifications = await asyncListAll(
      /* GraphQL */
      `
      query ListNotifications(
        $filter: ModelNotificationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            username
            type
            templateName
            email
            subject
            text
            html
            shouldBeSentAt
            retries
            status
            statusMsg
            sentAt
            createdBy
            createdAt
            logs
            updatedAt
            participant {
              # firstName
              # lastName
              email
            }
          }
          nextToken
        }
      }
    `, {
        filter: {
          createdAt: {
            between: [from, to],
          },
        },
      }, { bypassCache: true },
    );

    setNotifications(notifications.sort(sortBy('createdAt', true)));
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <DateRangeSelector
        unit='day'
        onSubmit={onSubmit}
        submitOnLoad={true}
        disabled={isLoading}
      />

      <NotificationsTable data={notifications} />
    </div>
  );
}
