import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

import Table from 'components/Table';

const title = 'EPA Vehicles';
const description = '';

const columns = [
  {
    name: 'id',
    label: 'EPA ID',
    options: {
      filter: false,
      sort: true,
      customBodyRender(value) {
        const id = value.replace('epa_', '');
        const url = `https://www.fueleconomy.gov/ws/rest/vehicle/${id}`;
        return (
          <Link
            variant="body1"
            color="primary"
            target="_blank"
            href={url}
          >
            {id}
          </Link>);
      },
    },
  },
  {
    name: 'make',
    label: 'Make',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'year',
    label: 'Year',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'model',
    label: 'Model',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'trany',
    label: 'Trany',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'cylinders',
    label: 'Cylinders',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fuelType',
    label: 'Fuel Type',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fuelType1',
    label: 'Fuel Type 1',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fuelType 2',
    label: 'Fuel Type 2',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'mpgCombined',
    label: 'MPG (Combined)',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'mpgCity',
    label: 'MPG (City)',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'mpgHighway',
    label: 'MPG (Highway)',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'kplCombined',
    label: 'KPL (Combined)',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'kplCity',
    label: 'KPL (City)',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'kplHighway',
    label: 'KPL (Highway)',
    options: {
      filter: false,
      sort: true,
    },
  },
];

function EpaVehiclesTable({ data }) {
  const options = {};

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

export default EpaVehiclesTable;

EpaVehiclesTable.propTypes = {
  data: PropTypes.array,
};
