import React, { useEffect, useState } from 'react';

import Table from 'components/Table';

import {
  listUsersInGroup,
  removeUserFromGroup,
  addUserToGroup,
  disableUser,
  enableUser,
} from 'utilities/cognito';
import { sortBy } from 'utilities/sorting';

const title = 'Cognito Users';
const description = '';

const columns = [
  {
    name: 'username',
    label: 'ID',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'email_verified',
    label: 'Email Verified',
    type: 'checkbox',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'enabled',
    label: 'Enabled',
    type: 'checkbox',
    edit: {
      type: 'checkbox',
    },
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'groups',
    label: 'Groups',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'role',
    label: 'Role',
    edit: {
      type: 'select',
      menu: ['Admins', 'Users'],
    },
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: 'Created At',
    type: 'datetime',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: 'Updated At',
    type: 'datetime',
    options: {
      filter: false,
      sort: true,
    },
  },
];

function CognitoUsersTable() {
  const [data, setData] = useState([]);
  const options = {};

  const onUpate = async (item, dataIndex) => {
    const input = {};
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });

    const { username, role, enabled } = data[dataIndex];

    if (role !== input.role) {
      await removeUserFromGroup(username, role);
      await addUserToGroup(username, input.role);
    }

    if (enabled !== input.enabled) {
      if (input.enabled === true) {
        await enableUser(username);
      } else {
        await disableUser(username);
      }
    }

    Object.assign(data[dataIndex], {
      enabled: input.enabled,
      role: input.role,
    });

    setData([...data]);
  };

  useEffect(() => {
    (async () => {
      try {
        const [
          { data: admins },
          { data: users },
        ] = await Promise.all([
          listUsersInGroup('Admins', true),
          listUsersInGroup('Users', true),
        ]);

        const allUsers = [
          ...admins.map((user) => {
            user.role = 'Admins';
            return user;
          }),
          ...users.map((user) => {
            user.role = 'Users';
            return user;
          }),
        ];

        setData(allUsers.sort(sortBy('email')).sort(sortBy('role')));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

export default CognitoUsersTable;
