/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import TransactionsTable from 'pages/Admin/components/TransactionsTable';

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ApexCharts from 'apexcharts';
import moment from 'moment';

import { asyncListAll } from 'utilities/graph';
import { getTransactionsByUsernameByCreatedAt } from './queries';

import { useStyles } from './styles';

const ParticipantDashboard = ({ user }) => {
  const classes = useStyles();
  const { username } = user;

  const chartRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [activeMonthIndex, setActiveMonthIndex] = useState(10);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const transactions = await asyncListAll(getTransactionsByUsernameByCreatedAt, {
          username,
          createdAt: {
            between: [
              moment().subtract(11, 'months').startOf('month'),
              moment().subtract(1, 'months').endOf('month'),
            ],
          },
          sortDirection: 'DESC',
        }, {
          bypassCache: true,
        });
        setTransactions(transactions);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      // group transactions by month
      const data = {};
      for (let i = 11, j = 0; i > 0; i -= 1, j += 1) {
        const month = moment().subtract(i, 'months');
        const inMonthTransactions = transactions.filter((t) => {
          return (
            moment(t.createdAt).isAfter(month.startOf('month')) &&
            moment(t.createdAt).isBefore(month.endOf('month'))
          );
        });

        // @todo convert units to imperial
        data[j] = {
          name: month.format('MMM \'YY'),
          data: inMonthTransactions.map((t) => {
            return t.amountCents / 100;
          }).reduce((acc, next) => {
            return acc + next;
          }, 0),
          extra: {
            // total segments with mileage fees (not toll)
            trips: inMonthTransactions.flatMap((t) => t.tripSegments.items).filter((s) => {
              return s.mileageFeeCents > 0;
            }).length,
            // reduced mileage across all segments
            mileage: inMonthTransactions.flatMap((t) => t.tripSegments.items).map((s) => {
              return s.mileage;
            }).reduce((acc, next) => {
              return acc + next;
            }, 0),
            // reduced fuel consumption across all segments
            fuel: inMonthTransactions.flatMap((t) => t.tripSegments.items).map((s) => {
              return s.fuel;
            }).reduce((acc, next) => {
              return acc + next;
            }, 0),
          },
        };
      }

      setChartData(data);
    }
  }, [transactions]);

  useEffect(() => {
    if (chartRef.current && chartData) {
      // configure chart
      const chart = new ApexCharts(chartRef.current, {
        chart: {
          type: 'bar',
          events: {
            dataPointSelection: (e, chart, opts) => {
              const { dataPointIndex } = opts;
              setActiveMonthIndex(dataPointIndex);
            },
          },
        },
        series: [{
          name: 'Balance',
          data: Object.values(chartData).map((month) => month.data),
        }],
        xaxis: {
          categories: Object.values(chartData).map((month) => month.name),
          labels: {
            style: {
              colors: Array(11).fill('#0A4842'),
              fontSize: '15px',
              fontWeight: 600,
            },
            offsetY: 5,
            minHeight: 40,
          },
        },
        yaxis: {
          title: {
            text: 'Dollars',
          },
        },
        dataLabels: {
          formatter: (val, opt) => {
            return `$${(val).toFixed(2)}`;
          },
        },
        tooltip: {
          custom: ({ dataPointIndex }) => {
            const {
              extra: {
                trips,
                mileage,
                fuel,
              },
            } = chartData[dataPointIndex];
            return (
              `<div class="MuiPaper-root MuiAlert-root MuiAlert-standardInfo MuiPaper-elevation0" role="alert">
                <div class="MuiAlert-icon">
                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path>
                  </svg>
                </div>
                <div class="MuiAlert-message">
                  <div>Total trips: ${trips}</div>
                  <div>Total mileage: ${mileage.toFixed(1)}</div>
                  <div>Total fuel consumed: ${fuel.toFixed(1)}</div>
                </div>
              </div>`
            );
          },
          fixed: {
            position: 'topRight',
          },
        },
        fill: {
          colors: ['#00AC6F'],
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.3,
            },
            active: {
              type: 'darken',
              value: 0.5,
            },
          },
        },
        title: {
          text: 'Last 12 Months',
          align: 'center',
          style: {
            fontSize: '18px',
          },
        },
      });

      chart.render();
    }
  }, [chartData]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  if (transactions.length < 1) {
    return (
      <div className={classes.notFound}>
        <Typography component="h1" variant="h5">
          No Activity.
        </Typography>
        <p>It looks like you do not yet have any activity.  Please wait until the second day of the month following your MBUF Study start date.</p>
      </div>
    );
  }

  return (
    <Container component="main">
      <Alert style={{ display: 'none' }}></Alert>
      <div ref={chartRef}></div>
      {activeMonthIndex !== null && (
        <Grid container spacing={4}>
          <Grid item>
            <TransactionsTable
              title={`${chartData[activeMonthIndex].name} Transactions`}
              data={[...transactions].filter((t) => {
                const month = moment().subtract(11 - activeMonthIndex, 'months');
                return (
                  moment(t.createdAt).isAfter(month.startOf('month')) &&
                  moment(t.createdAt).isBefore(month.endOf('month'))
                );
              })}
              viewer="participant"
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

ParticipantDashboard.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
    }),
  }),
};

export default ParticipantDashboard;
