import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import ControlledInput from 'components/Form/ControlledInput';

import { listPaymentCards } from 'graphql/queries';
import { updatePaymentCard } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';

import { useStyles } from './styles';

const BillingInfo = ({ user }) => {
  const classes = useStyles();

  // form state
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  // billing state
  const [defaultPaymentCard, setDefaultPaymentCard] = useState(null);
  const [error, setError] = useState(false);
  const { username } = user;

  useEffect(() => {
    (async () => {
      try {
        const paymentCards = await asyncListAll(listPaymentCards, {
          username,
        });

        const defaultCard = paymentCards.find(({ isDefault }) => isDefault) || paymentCards[0];
        setDefaultPaymentCard(defaultCard);
      } catch (e) {
        console.warn(e);
        setError(e.message);
      }
    })();
  }, [username]);

  if (!defaultPaymentCard) {
    return <></>;
  }

  const cardInputs = [{
    type: 'text',
    name: 'cardNumber',
    defaultValue: `**** **** **** ${defaultPaymentCard.last4}`,
    label: 'Card Number',
    required: true,
    disabled: true,
    invalidText: 'Card number is required',
  }, {
    type: 'text',
    name: 'nameOnCard',
    defaultValue: `${defaultPaymentCard.nameOnCard}`,
    label: 'Name On Card',
    required: true,
    invalidText: 'Name on card is required',
  }, {
    type: 'select',
    name: 'expirationMonth',
    defaultValue: `${defaultPaymentCard.expirationMonth}`,
    label: 'Exp Month',
    required: true,
    invalidText: 'Expiration month is required',
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
      return {
        value: month,
        label: month,
      };
    }),
  }, {
    type: 'select',
    name: 'expirationYear',
    defaultValue: `${defaultPaymentCard.expirationYear}`,
    label: 'Exp Year',
    required: true,
    invalidText: 'Expiration year is required',
    options: getExpirationYears().map((year) => {
      return {
        value: year,
        label: year,
      };
    }),
  }, {
    type: 'checkbox',
    name: 'isDefault',
    label: 'Set as default payment method',
    defaultChecked: defaultPaymentCard.isDefault,
    disabled: true,
    setValue,
  }];

  function getExpirationYears() {
    const now = new Date();
    const thisYear = now.getFullYear();
    const options = [];
    for (let i = thisYear; i < thisYear + 10; i++) {
      options.push(i);
    }
    return options;
  }

  async function handleSavePaymentCard({
    expirationMonth,
    expirationYear,
    isDefault,
    nameOnCard,
  }) {
    const now = new Date();
    const expiration = new Date(expirationYear, expirationMonth, 0);
    if (now.getTime() > expiration.getTime()) {
      setError('The card has expired');
      return;
    }

    console.log(defaultPaymentCard);

    await asyncRetryMutation(updatePaymentCard, {
      input: {
        expirationMonth: parseInt(expirationMonth, 10),
        expirationYear: parseInt(expirationYear, 10),
        id: defaultPaymentCard.id,
        isDefault,
        nameOnCard,
        updatedBy: localStorage.getItem('ruc:username'),
        username,
      },
    });
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Billing Info
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleSavePaymentCard)}
          noValidate
        >
          <Grid container spacing={2}>
            {cardInputs.splice(0, 2).map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}

            {cardInputs.splice(0, 2).map((input, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}

            {cardInputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

BillingInfo.propTypes = {
  user: PropTypes.object,
};

export default BillingInfo;
