import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  panels: {
    width: '100%',
    paddingLeft: theme.spacing(2),
  },
  dialog: {
    width: '100%',
  },
}));
