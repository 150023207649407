import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Link as RouteLink,
  useHistory,
} from 'react-router-dom';

import { Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import Logo from 'components/Logo';
import ControlledInput from 'components/Form/ControlledInput';

// import { APP } from 'utilities/constants';

import VerificationCode from './components/VerificationCode';
import { useStyles } from './components/commonStyles';

const SignIn = () => {
  const classes = useStyles();
  const history = useHistory();

  // form states
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  const inputs = [{
    type: 'text',
    name: 'email',
    label: 'Email Address',
    autoFocus: true,
    required: true,
    invalidText: 'A correctly formatted email is required',
    setValue,
  }, {
    type: 'password',
    name: 'password',
    label: 'Password',
    required: true,
    invalidText: 'A password is required',
  }];

  async function handleSignIn({ email, password }) {
    try {
      const normalized = email.trim().toLowerCase();
      await Auth.signIn(normalized, password);
      // history.push('/register');
      history.push('/dashboard');
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        setEmail(email);
        setPassword(password);
        setIsRegistered(true);
      } else {
        setIsRegistered(false);
        setError(e.message);
      }
    }
  }

  async function handleVerifyEmail(response) {
    try {
      if (response === 'SUCCESS') {
        setMessage('Your email has been validated.');
        handleSignIn({
          email,
          password,
        });
      }
    } catch (e) {
      setError(e.message);
    }
  }

  function handleCloseMessage() {
    setMessage(false);
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo width={250} margin={16} fullColor />
        {/* <Typography component="h1" variant="h5">
          {APP.NAME}
        </Typography> */}
        {!isRegistered ? (
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleSignIn)}
            noValidate
          >
            <Grid container spacing={2}>
              {inputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/forgot-password" href="#" variant="body2" component={RouteLink}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        ) : (
          <VerificationCode
            email={email}
            onVerifyEmail={handleVerifyEmail}
          />
        )}
        <Snackbar
          open={message !== false}
          autoHideDuration={10000}
          onClose={handleCloseMessage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="success"
            variant="filled"
            onClose={handleCloseMessage}>
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          data-error-id="signin-error"
          open={error !== false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default SignIn;
