import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import moment from 'moment-timezone';
import deepcopy from 'deepcopy';

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Checkbox from '@material-ui/core/Checkbox';
import {
  formatFuel,
  formatFuelRate,
  formatMileage,
  formatMileageRate,
} from 'utilities/format';

import Footer from './Footer';
import EditField from './EditField';

const useStyles = makeStyles((theme) => ({
  number: {
    textAlign: 'right',
  },
}));

const theme = (props = {}) => {
  const { cell = {} } = props;
  const cellStyle = Object.assign({

  }, cell);

  return createMuiTheme({
    overrides: {
      MUIDataTable: {},
      MUIDataTableHeadCell: {
        root: {
          ...cellStyle,
          fontWeight: 'bold',
        },
      },
      MUIDataTableBodyCell: {
        root: cellStyle,
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
      MUIDataTableFilter: {
        root: {
          minWidth: 400,
        },
      },
    },
  });
};

const NON_EDITABLE_FIELDS = ['actions', 'createdAt', 'updatedAt', 'username'];

function Table({
  title,
  description,
  data,
  columns,
  options,
  themeProps,
  onHandleCreateItem,
  onHandleRemoveItem,
  onUpdateItem,
  onChangeRowsPerPage,
}) {
  const classes = useStyles();

  const [updatedColumns, setUpdatedColumns] = useState(columns);
  const [editDataIndex, setEditDataIndex] = useState(-1);
  const [editRowIndex, setEditRowIndex] = useState(-1);
  const [editItem, setEditItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState();

  const cachedVisibleColumnKey = `ruc:table:${title}${description}:visibleColumns`;

  // overwrite options
  const updatedOptions = Object.assign({
    enableNestedDataAccess: '.',
    pagination: true,
    responsive: 'standard',
    rowsPerPageOptions: [10, 20, 100],
    rowsPerPage,
    filterType: 'checkbox',
    fixedHeader: true,
    resizableColumns: false,
    selectableRows: 'none',
    print: true,
    download: true,
    downloadOptions: {
      filename: `${title}.csv`,
      separator: ',',
    },
    expandableRows: false,
    isRowExpandable: () => false,
    isRowSelectable: () => false,
    onRowClick: (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    },
    customToolbar: () => {
      return (
        <>
          {onHandleCreateItem && (
            <IconButton
              data-test-id={`${title.replace(/[\s\']/g, '')}-create-record`}
              onClick={() => {
                onHandleCreateItem();
              }}
            >
              <AddCircleIcon color="inherit" />
            </IconButton>
          )}
        </>
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <Footer
          description={description}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },
    onColumnViewChange: (changedColumn, action) => {
      const cacheVisiableColumns = (localStorage.getItem(cachedVisibleColumnKey) || '').split(',');
      const index = cacheVisiableColumns.indexOf(changedColumn);
      if (action === 'add' && index === -1) {
        cacheVisiableColumns.push(changedColumn);
      } else if (action === 'remove' && index !== -1) {
        cacheVisiableColumns.splice(index, 1);
      }

      localStorage.setItem(cachedVisibleColumnKey, cacheVisiableColumns.join(','));
    },
    onFilterChange: (...args) => {
      setEditRowIndex(-1);
      setEditDataIndex(-1);
      setEditItem(null);
    },
    onSearchChange: (...args) => {
      setEditRowIndex(-1);
      setEditDataIndex(-1);
      setEditItem(null);
    },
    onChangeRowsPerPage: (args) => {
      setRowsPerPage(args);
      if (onChangeRowsPerPage) {
        onChangeRowsPerPage(args);
      }
    },
  }, options);

  useEffect(() => {
    const newColumns = deepcopy(columns);
    const hasEditColumn = newColumns.some(({ edit }) => edit);
    const canRemoveRecords = onHandleRemoveItem || false;
    if (
      (canRemoveRecords && !newColumns.find(({ name }) => name === 'actions')) ||
      (hasEditColumn && !newColumns.find(({ name }) => name === 'actions'))
    ) {
      newColumns.push({
        name: 'actions',
        label: ' ',
        type: 'actions',
        options: {
          display: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const isEditing = rowIndex === editRowIndex;
            const color = isEditing ? 'primary' : 'inherit';
            return (
              <div>
                {hasEditColumn && (
                  <IconButton
                    data-test-id={`${title.replace(/[\s\']/g, '')}-edit-record`}
                    aria-label="edit"
                    size="small"
                    color={color}
                    onClick={() => {
                      if (isEditing) {
                        // TODO: update, cancel, alert
                        if (onUpdateItem) {
                          onUpdateItem(editItem, editDataIndex);
                        }
                        setEditRowIndex(-1);
                        setEditDataIndex(-1);
                        setEditItem(null);
                      } else {
                        setEditRowIndex(rowIndex);
                        setEditDataIndex(dataIndex);
                        setEditItem(JSON.parse(JSON.stringify(data[dataIndex])));
                      }
                    }}
                  >
                    {isEditing ? <SaveIcon /> : <EditIcon />}
                  </IconButton>
                )}
                {canRemoveRecords && !isEditing && (
                  <IconButton
                    data-test-id={`${title.replace(/[\s\']/g, '')}-remove-record`}
                    aria-label="remove"
                    size="small"
                    color="inherit"
                    onClick={() => {
                      onHandleRemoveItem(data[dataIndex], dataIndex);
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                )
                }
              </div>
            );
          },
        },
      });
    }

    const cacheVisiableColumns = localStorage.getItem(cachedVisibleColumnKey) ? localStorage.getItem(cachedVisibleColumnKey).split(',') : undefined;
    const visibleColumns = [];

    newColumns.map((column) => {
      if (!Object.prototype.hasOwnProperty.call(column, 'options')) {
        column.options = {};
      }
      return column;
    }).forEach((column) => {
      const { name, edit, type, options = {} } = column;
      const mileageUnit = localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
      const fuelUnit = localStorage.getItem('ruc:configuration:FUEL_UNIT') || 'l';

      switch (type) {
        case 'actions':
          break;
        case 'date':
          options.customBodyRender = (value) => value ? moment(value).format('YYYY/MM/DD') : '';
          break;
        case 'datetime':
          options.customBodyRender = (value) => value ? moment(value).format('YYYY/MM/DD h:mm a') : '';
          break;
        case 'datetime-est':
          options.customBodyRender = (value) => value ? moment(value).tz('America/New_York').format('YYYY/MM/DD h:mm a') + ' (EST)' : '';
          break;
        case 'checkbox':
          options.customBodyRender = (value) => {
            const isChecked = (value == 'true' || value === 'yes' || value === true || value === 1) ? true : false;
            return (<Checkbox checked={isChecked} />);
          };
          break;
        case 'number':
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {val !== void (0) ? new Intl.NumberFormat().format(val) : 'N/A'}
            </div>
          );
          break;
        case 'fuel':
          column.label += ` (${fuelUnit})`;
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {val !== void (0) ? new Intl.NumberFormat().format(formatFuel(val, fuelUnit)) : 'N/A'}
            </div>
          );
          break;
        case 'fuelRate':
          column.label += ` (${fuelUnit})`;
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {val !== void (0) ? new Intl.NumberFormat().format(formatFuelRate(val, fuelUnit)) : 'N/A'}
            </div>
          );
          break;
        case 'mileage':
        case 'distance':
          column.label += ` (${mileageUnit})`;
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {val !== void (0) ? new Intl.NumberFormat().format(formatMileage(val, mileageUnit)) : 'N/A'}
            </div>
          );
          break;
        case 'mileageRate':
          column.label += ` (${mileageUnit})`;
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {val !== void (0) ? new Intl.NumberFormat().format(formatMileageRate(val, mileageUnit)) : 'N/A'}
            </div>
          );
          break;
        case 'currency':
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val / 100)}
            </div>
          );
          break;
        case 'currency-negative':
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(-val / 100)}
            </div>
          );
          break;
        case 'dollar':
          options.customBodyRender = (val) => (
            <div className={classes.number}>
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val)}
            </div>
          );
          break;
        case 'boolean':
          options.customBodyRender = (val) => val ? 'yes' : 'no';
          break;
        default:
          options.customBodyRender = options.customBodyRender || ((val) => val ? val : null);
          break;
      }

      if (editRowIndex !== -1 && edit && !NON_EDITABLE_FIELDS.includes(name)) {
        // options.display = true;

        if (options.filter !== false && edit) {
          options.filterOptions = Object.assign((options.filterOptions || {}), {
            logic: (value, filteredValues) => {
              if (filteredValues.length) {
                if (edit.type === 'select') {
                  const label = (edit.menu.find((x) => x.value === value) || {}).label || edit.menu.find((x) => x === value);
                  const display = filteredValues.includes(label);
                  return !display;
                }

                if (typeof value === 'string') {
                  const display = filteredValues.map((x) => (x || '').toLowerCase()).includes((value || '').toLowerCase());
                  return !display;
                }

                return !filteredValues.includes(value);
              }
              return false;
            },
          });
        }

        options.customBodyRenderLite = (dataIndex, rowIndex) => {
          const defaultValue = data[dataIndex][name];
          const currentValue = editItem[name];

          return (rowIndex === editRowIndex) ?
            <EditField
              name={name}
              data={editItem}
              value={currentValue}
              editIndex={dataIndex}
              {...edit}
              onUpdate={(value) => {
                editItem[name] = value;
                if (edit.rerenderAfterSelect) {
                  // only reset the editItem if needed
                  setEditItem({ ...editItem });
                } else {
                  setEditItem(editItem);
                }
              }} /> :
            options.customBodyRender ? options.customBodyRender(defaultValue) : defaultValue;
        };
      }

      if (cacheVisiableColumns) {
        options.display = cacheVisiableColumns.includes(name);
      }

      if (options.display !== false) {
        visibleColumns.push(name);
      }
    });

    setUpdatedColumns(newColumns);
    localStorage.setItem(cachedVisibleColumnKey, visibleColumns.join(','));
  }, [columns, editRowIndex, editDataIndex, data, editItem, onUpdateItem, classes.number, onHandleRemoveItem, title, cachedVisibleColumnKey]);

  return (
    <MuiThemeProvider theme={theme(themeProps)}>
      <MUIDataTable
        title={title}
        data={data}
        columns={updatedColumns}
        options={updatedOptions}
      />
    </MuiThemeProvider>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.object,
  nested: PropTypes.bool,
  themeProps: PropTypes.object,
  maxHeight: PropTypes.string,
  onHandleCreateItem: PropTypes.func,
  onHandleRemoveItem: PropTypes.func,
  onUpdateItem: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};

export default Table;
