import React from 'react';
import ReactDOM from 'react-dom';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Leaflet from 'leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import './index.css';
import App from './App';
import Footer from './components/Footer';
import * as serviceWorker from './serviceWorker';
import * as themes from './themes';

Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

export const flavor = 'connecticut';
export const theme = createMuiTheme(themes[flavor]);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <div style={{ width: '100%', height: 'calc(100vh - 32px)', overflow: 'auto' }}>
        <App />
      </div>
      <Footer />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
