import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';
import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import StatementRequest from './StatementRequest';

const CreateStatementDialog = ({ isOpen, onClose, onUpdate }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="create-trip-adjustment-dialog"
        open={isOpen}
        onClose={() => onClose()}
        disableBackdropClick={true}
      >
        <DialogContent>
          <StatementRequest onUpdate={(hasUpdate)=>{
            if (hasUpdate) {
              onUpdate();
            } else {
              onClose();
            }
          }}/>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

CreateStatementDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default CreateStatementDialog;
