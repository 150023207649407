import React, { useEffect, useState } from 'react';

import Table from 'components/Table';
import { listMRORates } from 'graphql/queries';
import { updateMRORate } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import {
  mroDevicePreferences,
  states,
} from 'utilities/constants';

const title = 'MRO Rates';
const description = '';

function MRORatesTable() {
  const [data, setData] = useState([]);
  const options = {
    hideCreatedAt: true,
    hideUpdatedAt: false,
  };

  const columns = [
    {
      name: 'mroType',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender(item) {
          const { label } = mroDevicePreferences.find(({ value }) => value === item) || {};
          return label;
        },
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        customBodyRender(item) {
          const fullName = states[item];
          return fullName ? `${fullName} (${item})` : '';
        },
      },
    },
    {
      name: 'centsPerFuelUnit',
      label: 'Cents per Fuel Unit',
      type: 'fuelRate',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'centsPerMileageUnit',
      label: 'Cents per Mileage Unit',
      type: 'mileageRate',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  const onUpate = async (item, dataIndex) => {
    const input = {
      mroType: item.mroType,
      state: item.state,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateMRORate, { input }, { clearCacheKeys: ['ListMroRates'] });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  useEffect(() => {
    (async () => {
      try {
        const records = (await asyncListAll(listMRORates));
        setData(records);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

export default MRORatesTable;
