import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import LinkButton from 'components/Table/LinkButton';
import { updateMROEvent } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import {
  listMroEvents,
  getMroEventsByMroDeviceByDateTime,
  getMroEventsByUsernameByFlag,
} from '../MroEvents/queries';

const title = 'MRO Events';
const description = '';

function MroEventsTable({ data: inData, deviceSerialNumber, username }) {
  const [data, setData] = useState([]);

  const options = {};

  const columns = [
    {
      name: 'flagged',
      label: 'Flagged',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'isProcessed',
      label: 'Processed',
      type: 'checkbox',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mroDeviceSerialNumber',
      label: 'MRO ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'code',
      label: 'Code',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'odometerReading',
      label: 'Odometer Reading',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mileage',
      label: 'Mileage',
      type: 'mileage',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dateTime',
      label: 'Date Time',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'source',
      label: 'Source',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'participant.fullName',
      label: 'Participant Name',
    },
    {
      name: 'username',
      label: 'User',
      options: {
        filter: false,
        sort: false,
        customBodyRender(username) {
          return username ? (
            <LinkButton
              path={`/participant/${username}`}
              label="View participant details"
            />
          ) : '';
        },
      },
    },
    {
      name: 'tripId',
      label: 'Trip',
      options: {
        filter: false,
        sort: false,
        customBodyRender(tripId) {
          return tripId ? (
            <LinkButton
              path={`/trip/${tripId}`}
              label="View trip details"
            />
          ) : '';
        },
      },
    },
  ];

  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });

    input.flagged = input.flagged ? 1 : 0;

    await asyncRetryMutation(updateMROEvent, { input }, { clearCacheKeys: ['ListMroEvents'] });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  useEffect(() => {
    (async () => {
      try {
        const records = inData ? inData :
          deviceSerialNumber ? (await asyncListAll(getMroEventsByMroDeviceByDateTime, { mroDeviceSerialNumber: deviceSerialNumber }, { bypassCache: true })) :
            username ? (await asyncListAll(getMroEventsByUsernameByFlag, { username }, { bypassCache: true })) :
              (await asyncListAll(listMroEvents, undefined, { bypassCache: true }));

        const populatedRecords = records.map((event) => {
          event.participant = event.participant || {};
          event.participant.fullName = `${event.participant.firstName || ''} ${event.participant.lastName || ''}`;
          return event;
        });
        setData(populatedRecords.sort(sortBy('dateTime', true)));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [inData, deviceSerialNumber, username]);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

MroEventsTable.propTypes = {
  data: PropTypes.array,
  deviceSerialNumber: PropTypes.string,
  username: PropTypes.string,
};

export default MroEventsTable;
