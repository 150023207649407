/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Logo from 'components/Logo';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
    marginTop: '1.5em',
  },
});

const Invitation = () => {
  const classes = useStyles();

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div>
          <Box marginBottom={4} marginTop={4}>
            <Logo width="100%" withETCoalition={true} />
          </Box>
          <Typography component="h5" variant="h5" color="primary" gutterBottom>
            Enrollment Is Now Closed
          </Typography>
          <p>
            Transurban, alongside The Eastern Transportation Coalition, has <strong>closed</strong> enrollment
            for our MBUF Study study.
          </p>
          <p>
            We’re sorry we missed you, if you would like to be kept in the loop on any upcoming studies related
            to understanding new, sustainable and fair ways to fund roadway improvements then please join our
            email list <Link
              href="mailto:connecticut@mbufstudy.org"
              target="_blank"
              rel="noopener"
              variant="body2"
            >here</Link>.
          </p>
          <Typography component="h5" variant="h5" color="primary" gutterBottom>
            Learn More About Us
          </Typography>
          <p>
            <Typography component="span" variant="body2" color="primary" className={classes.bold}>
              Transurban
            </Typography> is one of the world’s largest toll-road operators and developers, working to get people
            where they want to go, as quickly and safely as possible. We are pioneering travel solutions like the
            dynamically tolled 495, 95 and 395 Express Lanes in the Greater Washington Area and we are partnering
            with government to think about the policies, technology, and infrastructure that will get you home
            today and ten years from now.
          </p>
          <p>
            For more information visit us at&nbsp;
            <Link
              href="https://www.transurban.com/"
              target="_blank"
              rel="noopener"
              variant="body2"
            >
              Transurban.com
            </Link>&nbsp;
            or at&nbsp;
            <Link
              href="https://expresslanes.com/"
              target="_blank"
              rel="noopener"
              variant="body2"
            >
              Expresslanes.com
            </Link>
          </p>
          <p>
            <Typography component="span" variant="body2" color="primary" className={classes.bold}>
              The Eastern Transportation Coalition
            </Typography> is a partnership of 17 states and Washington, D.C. focused on bringing public and private
            stakeholders together to solve transportation problems. As a nonpartisan independent partnership, the
            Coalition is dedicated to advancing the national conversation around MBUF through real-world studies,
            education, and outreach. The Coalition is neutral regarding MBUF as the ultimate solution for
            transportation funding but wants to ensure the Eastern Seaboard is part of the national discussion.
          </p>
          <p>
            To learn more about Coalition’s work please go to&nbsp;
            <Link
              href="https://tetcoalitionmbuf.org/"
              target="_blank"
              rel="noopener"
              variant="body2"
            >
              tetcoalitionmbuf.org
            </Link>
          </p>
        </div>
      </Container>
    </>
  );
};

Invitation.propTypes = {};
export default Invitation;
