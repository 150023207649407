export const listParticipantInquirys = /* GraphQL */ `
  query ListParticipantInquirys(
    $filter: ModelParticipantInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantInquirys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        assignee
        category
        subject
        body
        status
      }
      nextToken
    }
  }
`;
