import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import RateReviewIcon from '@material-ui/icons/RateReview';

import { Dialog, DialogTitle, DialogContent } from 'components/CustomDialog';
import TripReview from 'pages/Admin/Trip/components/TripReview';

export default function TripReprocessButton({ trip = {}, onUpdate }) {
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  return (
    <React.Fragment>
      <Tooltip
        title={'Re-process this trip'}
        placement="bottom"
      >
        <IconButton
          aria-label={'re-process'}
          size={'small'}
          disabled={openAlertDialog}
          onClick={() => setOpenAlertDialog(true)}
        >
          <RateReviewIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={openAlertDialog}
        onClose={(e, reason) => {}}
        fullWidth
        maxWidth={'lg'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={() => setOpenAlertDialog(false)}>
          Trip Review
        </DialogTitle>
        <DialogContent>
          <TripReview
            trip={trip}
            onUpdate={(data) => {
              setOpenAlertDialog(false);
              if (onUpdate) {
                onUpdate(data);
              }
            }}/>
        </DialogContent>
      </Dialog>

    </React.Fragment>
  );
}

TripReprocessButton.propTypes = {
  trip: PropTypes.object,
  onUpdate: PropTypes.func,
};
