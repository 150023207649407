export const shellParticipant = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  address: {
    address1: '',
    address2: '',
    postalCode: '',
    city: '',
    state: '',
  },
  gender: '',
  ageGroup: '',
};

export const shellVehicle = {
  make: '',
  model: '',
  year: '',
  type: '',
  registrationState: '',
  licensePlate: '',
};
