/* eslint-disable max-len */
import React from 'react';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function QandA() {
  const renderLink = (text, url, target = '_blank') =>(
    <Link
      href={url}
      target={target}
      rel="noopener"
      variant="body2"
    >
      {text}
    </Link>
  );

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div>
        <Typography component="h5" variant="h5" color="primary" gutterBottom>
          Frequently Asked Questions
        </Typography>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          Who is running this MBUF Study?
        </Typography>
        <p>
          Together, Transurban and The Eastern Transportation Coalition are conducting a mileage-based user fee (MBUF) Study that studies a different way of funding infrastructure. That means instead of being charged a tax at the gas pump, drivers are charged by the miles they drive.
        </p>
        <p>
          {renderLink('Transurban', 'https://www.transurban.com/')} is one of the world’s largest toll-road operators and developers, working to get people where they want to go, as quickly and safely as possible. By embracing collaboration with government, our public-private partnerships are delivering transformative infrastructure solutions, ensuring they’ll deliver real and lasting benefits to cities and their communities.
        </p>
        <p>
          {renderLink('The Eastern Transportation Coalition', 'https://tetcoalition.org/')} is a partnership of 17 states and Washington, D.C. focused on bringing public and private stakeholders together to solve transportation problems. As a nonpartisan independent partnership, the Coalition is dedicated to advancing the national conversation around MBUF through real-world studies, education, and outreach. The Coalition is neutral regarding MBUF as the ultimate solution for transportation funding but wants to ensure the Eastern Seaboard is part of the national discussion.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          What do Transurban and The Easter Coalition hope to learn from this MBUF Study?
        </Typography>
        <p>
          The findings of this MBUF Study will build on years of research by Transurban and The Eastern
          Transportation Coalition on MBUF.  This research informs policy discussions about transportation
          funding in the region and around the country.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          What is a mileage-based user fee (MBUF) program and why is it important?
        </Typography>
        <p>A mileage-based user fee (MBUF):</p>
        <ul>
          <li>
            Charges motorists for the number of miles traveled, not how much fuel is consumed
          </li>
          <li>
            Creates a link between how much you pay and how much you drive instead of basing it on your vehicle’s fuel efficiency
          </li>
          <li>
            Is currently being explored as a potential alternative to the gas tax you pay at the pump
          </li>
        </ul>
        <p>
          An MBUF system would ensure we have sustainable, equitable ways of investing in roads and funding public transportation improvements and maintenance.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          Will I have to pay money as part of this MBUF Study?
        </Typography>
        <p>
          No. No real money is exchanged as part of this MBUF Study, and participation is voluntary. You will receive mock invoices each month detailing what you would pay under a real-world rollout of an MBUF system, but these are just examples.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          What do Transurban and The Eastern Transportation Coalition hope to learn from this MBUF Study?
        </Typography>
        <p>
          The findings of this MBUF Study will build on years of research by Transurban and The Eastern Transportation Coalition on MBUF. This research informs policy discussions about transportation funding in the region and around the country, studying whether a pay-as-you-drive system is more sustainable and more equitable.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          How does a mileage-based user fee (MBUF) system work?
        </Typography>
        <p>
          The mileage-based user fee (MBUF) calculation is:
        </p>
        <ul>
          <li>
            MBUF Charge (net difference) = (miles driven x MBUF rate per mile) – state fuel tax paid
          </li>
        </ul>
        <p>
          For some participants, additional features (such as rush hour pricing) tested during this study are added to the above and made clear on your simulated statement.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          What is my role as a participant?
        </Typography>
        <p>
          You just need to drive as you normally do. The study will run from June to the end of October 2021. You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax.
        </p>
        <p>
          You will be asked for voluntary feedback along the way.
        </p>
        <p>
          <i>
            *No real money payment will be exchanged as part of this MBUF Study.
          </i>
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          Do I have to use the Express Lanes or travel on toll roads to participate?
        </Typography>
        <p>
          No, this study does not require you to travel on any toll roads or use the Transurban Express Lanes in order to participate.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          What is the plug-in device that was shipped to me?
        </Typography>
        <p>
          If during the enrollment process you chose a MBUF Study device you will receive it alongside instructions on how to plug it into your car’s OBDII port.
        </p>
        <p>
          <strong>Device without GPS</strong> – The device provides information on miles driven and fuel use needed for estimating the mileage-based fee shown on your mock statements*.
        </p>
        <p>
          <strong>Device with GPS</strong> – In addition to features of devices without GPS, this device allows you to see exactly where you’ve driven on your MBUF Study account, and provides a more accurate representation of how your payments under an  MBUF system compared to your current state and federal gas taxes.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          How is my privacy protected?
        </Typography>
        <p>
          Your personal information will never be shared with third parties and is anonymized at the end of the study. All personally identifiable information collected to set up and manage study participants will be destroyed within 30 days of the completion of the MBUF Study. Non-personal information (i.e. total mileage, vehicle make and model, etc.) may be retained indefinitely and used for other MBUF research purposes. All data is securely stored, and only individuals involved in the research study will have access to the data.
        </p>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          Are there incentives for participating in this MBUF Study?
        </Typography>
        <p>You may qualify for MBUF Study incentives if you meet the below criteria:</p>
        <ol>
          <li>
            You are not employed by a state or federal government agency.
          </li>
          <li>
            You are not employed by Transurban or any of its partners and affiliates taking part in the MBUF Study.
          </li>
        </ol>
        <p>If you meet the above criteria, you are eligible to receive the MBUF Study incentive upon completion of the below.</p>
        <p>At the beginning of the study:</p>
        <ul>
          <li>Complete the final enrollment form</li>
          <li>Plug-in the OBDII device (if provided) or provide your first odometer mileage reading (if device not provided)</li>
          <li>Complete a study entry survey</li>
        </ul>
        <p>At the end of the study:</p>
        <ul>
          <li>Participate in the study for the full duration (approximately 4 months)</li>
          <li>Send back the OBDII device (if provided) or provide your last odometer mileage reading (if device not provided)</li>
          <li>Complete the study exit survey</li>
        </ul>

        <Typography component="h7" variant="h7" color="primary" style={{ fontWeight: 'bold' }}>
          Other general questions about MBUF?
        </Typography>
        <p>
          For more general and policy related information and questions please see The Eastern Transportation Coalition FAQ at {renderLink('tetcoalitionmbuf.org/faqs/', 'https://tetcoalitionmbuf.org/faqs/')}
        </p>
        <p>
          If you have an inquiry that is not answered above please submit it to the {renderLink('Support', '/support')} page.
        </p>
      </div>
    </Container>
  );
}

QandA.propTypes = {};
