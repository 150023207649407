import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TripLogs({ data = [] }) {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {data.map((log, index) => (
        <ListItem
          key={index}
          dense
        >
          <ListItemText
            primary={log}
          />
        </ListItem>
      ))}
    </List>
  );
}

TripLogs.propTypes = {
  data: PropTypes.array,
};
