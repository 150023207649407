import React from 'react';
import { Route } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';

import PilotClosed from 'pages/Public/PilotClosed';
import EligibilitySurvey from 'pages/Public/EligibilitySurvey';
import Invitation from 'pages/Public/Invitation';
import Incentive from 'pages/Public/Incentive';

import SignUp from 'pages/Auth/SignUp';
import SignIn from 'pages/Auth/SignIn';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import Oops from 'pages/Auth/Oops';
import Terms from 'pages/Auth/Terms';

import Participants from 'pages/Admin/Participants';
import Participant from 'pages/Admin/Participant';
import MRODevices from 'pages/Admin/MRODevices';
import CreateMRODevice from 'pages/Admin/MRODevices/CreateMRODevice';
import BulkImportMRODevices from 'pages/Admin/MRODevices/BulkImportMRODevices';

import MyAccount from 'pages/Participant/Account';
import CustomerSupport from 'pages/Participant/Account/components/ContactUs';
import QandA from 'pages/Participant/Account/components/QandA';

import Rates from 'pages/Admin/Rates';
import PilotProgram from 'pages/Admin/PilotProgram';
import Permission from 'pages/Admin/Permission';
import Trips from 'pages/Admin/Trips';
import Trip from 'pages/Admin/Trip';
import Transactions from 'pages/Admin/Transactions';
import Transaction from 'pages/Admin/Transaction';
import Statements from 'pages/Admin/Statements';
import Notifications from 'pages/Admin/Notifications';
import Inquiries from 'pages/Admin/Inquiries';
import Inquiry from 'pages/Admin/Inquiry';
import MroEvents from 'pages/Admin/MroEvents';
import Report from 'pages/Admin/Report';
import Events from 'pages/Admin/Events';
import Cordon from 'pages/Admin/Cordon';
import Configurations from 'pages/Admin/Configurations';
import TripsReview from 'pages/Admin/TripsReview';

export const auth = [
  {
    title: 'Register',
    paths: [
      { path: '/register' },
    ],
    component: SignUp,
  },
  {
    title: 'ForgotPassword',
    paths: [{ path: '/forgot-password' }],
    component: ForgotPassword,
  },
  {
    title: 'Sign In',
    paths: [
      { path: '/login' },
      { path: '/signin' },
    ],
    component: SignIn,
  },
  {
    title: 'Oops',
    paths: [{ path: '/oops' }],
    component: Oops,
  },
  {
    title: 'The Eastern Transportation Coalition Participant Agreement',
    paths: [{ path: '/tetc' }],
    component: () => <Terms filename="TETC_MBUF_Flyer_CT Participant Agreement.pdf" iframe={true} />,
    // component: () => <Terms filename="TETC Participant Terms SATv.pdf" />,
  },
  {
    title: 'The Transurban Terms and Conditions and Privacy Policy',
    paths: [{ path: '/tutc' }],
    component: () => <Terms filename="TETC_MBUF_Flyer_CT Participant Agreement.pdf" iframe={true} />,
    // component: () => <Terms filename="Transurban RUC Pilot Participant Usage Terms FINAL 5272021.pdf" />,
  },
  {
    title: 'MBUF Study Eligibility Survey',
    paths: [
      { path: '/survey' },
    ],
    component: EligibilitySurvey,
  },
  {
    title: 'MBUF Study Invitation',
    paths: [
      { path: '/invitation' },
    ],
    component: Invitation,
  },
  {
    title: 'MBUF Study Incentive',
    paths: [
      { path: '/incentive' },
    ],
    component: Incentive,
  },
  {
    title: 'Pilot Program Closed to Enrollment',
    paths: [
      { path: '/closed' },
    ],
    component: PilotClosed,
  },
].map((item) => {
  item.route = item.route || Route;
  return item;
});

export const user = [
  {
    title: 'My Account',
    paths: [
      { path: '/my-account', exact: false },
    ],
    component: MyAccount,
  },
  {
    title: 'Q&A',
    paths: [
      { path: '/q+and+a', exact: true },
    ],
    component: QandA,
    hideFromMenu: true,
  },
  {
    title: 'Support',
    paths: [
      { path: '/support', exact: true },
    ],
    component: CustomerSupport,
  },
  {
    title: 'Dashboard',
    paths: [
      { path: '/dashboard', exact: true },
    ],
    component: MyAccount,
    hideFromMenu: true,
  },
].map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['N/A', 'Users'];
  return item;
});

export const admin = [
  {
    title: 'Dashboard',
    paths: [{ path: '/dashboard', exact: true }],
    component: Participants,
    hideFromMenu: true,
  },
  {
    title: 'Participants',
    paths: [{ path: '/participants', exact: true }],
    component: Participants,
  },
  {
    title: 'Participant',
    paths: [{ path: '/participant/:username', exact: true }],
    component: Participant,
    hideFromMenu: true,
  },
  {
    title: 'Programs & Rates',
    paths: [{ path: '/pilotProgramsAndRates', exact: true }],
    component: Rates,
    pilotSettings: true,
  },
  {
    title: 'Program',
    paths: [{ path: '/pilotProgram/:id', exact: true }],
    component: PilotProgram,
    hideFromMenu: true,
  },
  {
    title: 'Trips',
    paths: [{ path: '/trips', exact: true }],
    component: Trips,
  },
  {
    title: 'Trip',
    paths: [{ path: '/trip/:id', exact: true }],
    component: Trip,
    hideFromMenu: true,
  },
  {
    title: 'Transactions',
    paths: [{ path: '/transactions', exact: true }],
    component: Transactions,
  },
  {
    title: 'Transaction',
    paths: [{ path: '/transaction/:id', exact: true }],
    component: Transaction,
    hideFromMenu: true,
  },
  {
    title: 'Devices',
    paths: [{ path: '/mro-devices', exact: true }],
    component: MRODevices,
  },
  {
    title: 'Add MRO',
    paths: [{ path: '/mro-devices/register', exact: true }],
    component: CreateMRODevice,
    hideFromMenu: true,
  },
  {
    title: 'Bulk Add MRO',
    paths: [{ path: '/mro-devices/bulk-register', exact: true }],
    component: BulkImportMRODevices,
    hideFromMenu: true,
  },
  {
    title: 'Trips Review',
    paths: [{ path: '/tripsReview', exact: true }],
    component: TripsReview,
  },
  {
    title: 'Statements',
    paths: [{ path: '/statements', exact: true }],
    component: Statements,
  },
  {
    title: 'Notifications',
    paths: [{ path: '/notifications', exact: true }],
    component: Notifications,
  },
  {
    title: 'Inquiries',
    paths: [{ path: '/inquiries', exact: true }],
    component: Inquiries,
  },
  {
    title: 'Inquiry',
    paths: [{ path: '/inquiry/:id', exact: true }],
    component: Inquiry,
    hideFromMenu: true,
  },
  {
    title: 'MRO Events',
    paths: [{ path: '/mroEvents', exact: true }],
    component: MroEvents,
  },
  {
    title: 'Reports',
    paths: [{ path: '/report', exact: true }],
    component: Report,
  },
  {
    title: 'User Permission',
    paths: [{ path: '/permission', exact: true }],
    component: Permission,
    pilotSettings: true,
  },
  {
    title: 'Audit Trail',
    paths: [{ path: '/events', exact: true }],
    component: Events,
    pilotSettings: true,
  },
  {
    title: 'Cordon',
    paths: [{ path: '/cordon/:id', exact: true }],
    component: Cordon,
    hideFromMenu: true,
    pilotSettings: true,
  },
  {
    title: 'System Configuration',
    paths: [{ path: '/configurations', exact: true }],
    component: Configurations,
    pilotSettings: true,
  },
].map((item) => {
  item.route = item.route || ProtectedRoute;
  item.roles = ['Admins'];
  return item;
});

export const authRoutes = [
  ...auth,
].reduce((all, item) => {
  item.paths.forEach(({ path, exact = true }) => {
    all.push(Object.assign({ path, exact }, item));
  });
  return all;
}, []);

export const routes = [
  ...user, ...admin,
].reduce((all, item) => {
  item.paths.forEach(({ path, exact = true }) => {
    all.push(Object.assign({ path, exact }, item));
  });
  return all;
}, []);
