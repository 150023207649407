import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Table from 'components/Table';
import NestedTableContainer from 'components/Table/NestedTableContainer';

import Transaction from 'pages/Admin/Transaction';

import { sortBy } from 'utilities/sorting';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginTop: theme.spacing(20),
  },
}));

function TransactionsTable({
  data: inData,
  title = 'Transactions',
  description = '',
  viewer = 'admin',
  nested = false,
}) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [{
    name: 'id',
    label: 'Transaction ID',
    options: {
      filter: false,
    },
  }, {
    name: 'username',
    label: 'Username',
    options: {
      display: false,
      filter: false,
    },
  }, {
    name: 'type',
    label: 'Transaction Type',
    options: {
      sort: true,
    },
  }, {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
    },
  }, {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
    },
  }, {
    name: 'subtotalCents',
    label: 'Subtotal',
    type: 'currency',
    options: {
      sort: true,
      filter: false,
    },
  }, {
    name: 'discountCents',
    label: 'Discounts',
    type: 'currency',
    options: {
      sort: true,
      filter: false,
    },
  }, {
    name: 'amountCents',
    label: 'Total',
    type: 'currency',
    options: {
      sort: true,
      filter: false,
    },
  }, {
    name: 'createdAt',
    label: 'Created At',
    type: 'datetime',
    options: {
      filter: false,
      sort: true,
    },
  }];

  if (viewer === 'admin') {
    columns.unshift({
      name: 'participant.fullName',
      label: 'Participant Name',
    });
  }

  const options = {
    expandableRows: true,
    isRowExpandable: () => nested ? false : true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <Transaction id={id} viewer={viewer} />
        </NestedTableContainer>
      );
    },
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const transactions = inData;
        setData(transactions.sort(sortBy('createdAt', true)));
        setIsLoading(false);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [inData]);

  if (isLoading) {
    return (
      <Grid container className={classes.spinner} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

TransactionsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  nested: PropTypes.bool,
};

export default TransactionsTable;
