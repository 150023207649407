import Smartcar from '@smartcar/auth';

export const connect = async (scopeByVehicleClassification, additionalOptions) => {
  return new Promise((resolve, reject) => {
    const smartcar = new Smartcar({
      clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
      redirectUri: process.env.REACT_APP_SMARTCAR_OAUTH_REDIRECT_URL,
      scope: scopeByVehicleClassification.split(','),
      testMode: process.env.REACT_APP_SMARTCAR_TEST_MODE === 'true' ? true : false,
      onComplete: (err, code) => {
        return (err) ? reject(err) : resolve(code);
      },
    });

    smartcar.openDialog({
      forcePrompt: true,
      singleSelect: true,
      ...additionalOptions,
    });
  });
};

export const rucScope = 'required:read_location,required:read_odometer,required:read_vin';

// https://smartcar.com/product/compatible-vehicles/
export const vehicleIsSmartcarCompatible = (make, year) => {
  /* eslint-disable */
  switch (make) {
    case 'BUICK':
    case 'CADILLAC':
    case 'CHEVROLET':
    case 'GMC':
    case 'NISSAN':
      return year >= 2011;
    case 'HYUNDAI':
    case 'TESLA':
    case 'VOLVO':
      return year >= 2012;
    case 'DODGE':
    case 'FORD':
    case 'RAM':
      return year >= 2013;
    case 'ACURA':
    case 'BMW':
    case 'JEEP':
    case 'VOLKSWAGEN':
      return year >= 2014;
    case 'CHRYSLER':
    case 'LEXUS':
    case 'LINCOLN':
      return year >= 2015;
    case 'JAGUAR':
    case 'LAND ROVER':
      return year >= 2016;
    case 'AUDI':
    case 'TOYOTA':
      return year >= 2017;
    case 'MERCEDES-BENZ':
    case 'MINI':
      return year >= 2018;
  }
  /* eslint-enable */

  return false;
};
