export const formatAddress = (inAddressObject) => {
  if (!inAddressObject) return '';
  const {
    address1,
    address2,
    city,
    state,
    postalCode,
    extendedPostalCode,
  } = inAddressObject;

  return [
    `${address1 ? address1 : ''}`,
    `${address2 ? ` ${address2}` : ''}`,
    `${city ? `, ${city}` : ''}`,
    `${state ? `, ${state}` : ''}`,
    `${postalCode ? ` ${postalCode}` : ''}`,
    `${extendedPostalCode ? `-${extendedPostalCode}` : ''}`,
  ].join('');
};

export const formatFuel = (value, unit = 'l') => {
  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return convertFuel(value, 'gal');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const convertFuel = (value, targetUnit) => {
  switch ((targetUnit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return parseFloat((value * 0.264172).toFixed(2));
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return parseFloat((value * 3.78541).toFixed(2));
  }
};

export const formatFuelRate = (value, unit = 'l') => {
  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return convertFuelRate(value, 'gal');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const convertFuelRate = (value, targetUnit) => {
  switch ((targetUnit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return parseFloat((value * 3.78541).toFixed(2));
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return parseFloat((value * 0.264172).toFixed(2));
  }
};

export const formatMileage = (value, unit = 'km') => {
  switch ((unit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return convertMileage(value, 'mi');
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return value;
  }
};

export const convertMileage = (value, targetUnit = 'km') => {
  switch ((targetUnit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return parseFloat((value * 0.621371).toFixed(2));
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return parseFloat((value * 1.60934).toFixed(2));
  }
};

export const formatMileageRate = (value, unit = 'km') => {
  switch ((unit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return convertMileageRate(value, 'mi');
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return value;
  }
};

export const convertMileageRate = (value, targetUnit = 'km') => {
  switch ((targetUnit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return parseFloat((value * 1.60934).toFixed(2));
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return parseFloat((value * 0.621371).toFixed(2));
  }
};
