export const COLLECT_BILLING_INFORMATION = false;

export const tripPaymentStatus = (tripSegments) => {
  const segmentStatuses = tripSegments.map(({ paymentStatus }) => paymentStatus);
  if (segmentStatuses.includes('failed')) {
    return 'failed';
  }

  const paidSegments = segmentStatuses.filter((status) => status === 'paid');
  if (paidSegments.length === tripSegments.length) {
    return 'paid in full';
  }

  // If there is only one trip segment then it is binary and cannot be partial.
  if (tripSegments.length > 1 && paidSegments.length < tripSegments.length) {
    return 'paid in part';
  }

  return 'pending';
};
