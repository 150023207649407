import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { listParticipants } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const title = 'Events';
const description = '';

const ignoreDiffKey = [
  'createdAt', 'updatedAt', 'updatedBy', 'createdBy',
];

export default function EventTable({ username }) {
  const [data, setData] = useState([]);
  const [participants, setParticipants] = useState([]);

  const options = {};

  const matchParticipant = (inUsername) => {
    const matched = participants.find(({ username }) => username === inUsername);
    return matched ? `${matched.firstName || ''} ${matched.lastName || ''} (${matched.email})` : inUsername;
  };

  const columns = [
    {
      name: 'timestamp',
      label: 'Timestamp',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'table',
      label: 'Table',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'partitionKey',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'sortKey',
      label: 'Sort Key',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'eventName',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: 'Updated By',
      options: {
        filter: true,
        sort: true,
        customBodyRender: matchParticipant,
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        filter: false,
        sort: true,
        customBodyRender: matchParticipant,
      },
    },
    {
      name: 'diff',
      label: 'Diff',
      options: {
        filter: false,
        sort: false,
        customBodyRender(value = []) {
          const diffs = value.filter(({ key }) => !ignoreDiffKey.includes(key));

          if (diffs.length === 0) return null;

          return (<table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th width="30%">Field</th>
                <th width="35%">Old</th>
                <th width="35%">New</th>
              </tr>
            </thead>
            <tbody>
              {diffs.map((diff, index)=>(
                <tr key={index}>
                  <td>{diff.key}</td>
                  <td>{(diff.old || '').replace(/"/g, '')}</td>
                  <td>{(diff.new || '').replace(/"/g, '')}</td>
                </tr>
              ))}
            </tbody>
          </table>);
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const allParticipants = await asyncListAll(listParticipants);
        let records;
        if (username) {
          records = await asyncListAll( /* GraphQL */ `
          query GetEventsByUserByTimestamp(
            $username: String
            $timestamp: ModelStringKeyConditionInput
            $sortDirection: ModelSortDirection
            $filter: ModelEventFilterInput
            $limit: Int
            $nextToken: String
          ) {
            getEventsByUserByTimestamp(
              username: $username
              timestamp: $timestamp
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
            ) {
              items {
                key
                timestamp
                username
                updatedBy
                eventId
                eventName
                diff {
                  key
                  old
                  new
                }
                note
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `, {
            username,
            sortDirection: 'DESC',
          });
        } else {
          records = await asyncListAll( /* GraphQL */ `
          query ListEvents(
            $key: String
            $timestamp: ModelStringKeyConditionInput
            $filter: ModelEventFilterInput
            $limit: Int
            $nextToken: String
            $sortDirection: ModelSortDirection
          ) {
            listEvents(
              key: $key
              timestamp: $timestamp
              filter: $filter
              limit: $limit
              nextToken: $nextToken
              sortDirection: $sortDirection
            ) {
              items {
                key
                timestamp
                username
                updatedBy
                eventId
                eventName
                diff {
                  key
                  old
                  new
                }
                note
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        `);
        }

        setParticipants(allParticipants);
        setData(records
          .sort(sortBy('timestamp', true))
          .map((item) => {
            const [table, partitionKey, sortKey] = item.key.split('__');
            item.table = table.split('-')[0];
            item.partitionKey = partitionKey;
            item.sortKey = sortKey;
            return item;
          }));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [username]);

  return (
    <React.Fragment>
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}

EventTable.propTypes = {
  username: PropTypes.string,
};
