import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import VerticalTabs from 'components/Tab/VerticalTabs';

import InquiryOverview from './components/InquiryOverview';
import InquiryComments from './components/InquiryComments';

import {
  asyncGet,
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  listParticipantInquiryComments,
} from 'graphql/queries';
import {
  updateParticipantInquiry,
  createParticipantInquiryComment,
} from 'graphql/mutations';
import {
  getParticipantInquiry,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  activity: {
    marginTop: theme.spacing(4),
  },
  backButton: {
    marginBottom: theme.spacing(1),
  },
}));

export default function Inquiry({ id: inId, computedMatch, viewer = 'admin' }) {
  const classes = useStyles();
  const history = useHistory();

  const [id, setId] = useState();
  const [isNested, setIsNested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inquiry, setInquiry] = useState(null);
  const [comments, setComments] = useState(null);

  useEffect(() => {
    if (inId) {
      setId(inId);
      setIsNested(true);
    } else if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
      setIsNested(false);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        try {
          const [
            inquiryResponse,
            comments,
          ] = await Promise.all([
            asyncGet(getParticipantInquiry, { id }),
            asyncListAll(listParticipantInquiryComments, { inquiryId: id }),
          ]);

          const {
            data: {
              getParticipantInquiry: inquiry,
            },
          } = inquiryResponse;

          setInquiry(inquiry);
          setComments(comments);
        } catch (e) {
          console.warn(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  if (!id || !inquiry) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  async function handleCreateComment({ message, isNote }) {
    setIsLoading(true);
    const { name, email, subject, emailOriginalMessageId } = inquiry;
    const toCreateComment = {
      inquiryId: inquiry.id,
      username: inquiry.username,
      author: viewer === 'admin' ? 'admin' : 'participant',
      body: message,
      type: isNote ? 'note' : 'message',
      status: 'unread',
      emailPayload: isNote ? undefined : {
        to: `${name} <${email}>`,
        subject: `Re: ${subject}`,
        text: message,
        inReplyTo: emailOriginalMessageId,
        html: [
          `<p>${message}</p>`,
        ].join(''),
      },
    };

    try {
      const {
        data: {
          createParticipantInquiryComment: comment,
        },
      } = await asyncRetryMutation(createParticipantInquiryComment, {
        input: toCreateComment,
      });
      comments.push(comment);
      setComments(comments);

      // auto update inquiry status
      if (!isNote) {
        const newInquiryStatus = viewer === 'admin' ? 'awaitingCustomerResponse' :
          viewer === 'participant' ? 'responseReceived' : inquiry.status;

        if (newInquiryStatus !== inquiry.status) {
          await asyncRetryMutation(updateParticipantInquiry, {
            input: {
              id: inquiry.id,
              status: newInquiryStatus,
              updatedBy: localStorage.getItem('ruc:username'),
            },
          });
        }
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {viewer === 'admin' ? (
        <>
          <Grid container>
            <Grid xs={12}>
              <Button variant="contained" color="primary" className={classes.backButton} onClick={
                () => {
                  history.goBack();
                }
              }
              >Back</Button>
              <Divider orientation="horizontal" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InquiryOverview
                inquiry={inquiry}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.activity}>Activity</Typography>
              </Grid>
              <Grid item xs={12}>
                <InquiryComments
                  inquiry={inquiry}
                  comments={comments}
                  viewer={viewer}
                  handleCreateComment={handleCreateComment}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <VerticalTabs
          tabs={[{
            label: 'Activity',
            component: <InquiryComments
              inquiry={inquiry}
              comments={comments}
              viewer={viewer}
              handleCreateComment={handleCreateComment}
            />,
          }]}
          isNested={isNested}
        />
      )}
    </>
  );
}

Inquiry.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  viewer: PropTypes.string,
};
