import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import Logo from 'components/Logo';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    marginTop: -40,
    paddingTop: 40,
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pdfDocumentContainer: {
    marginTop: 50,
    marginLeft: -20,
  },
  // pdfDocumentPage: {
  //   marginTop: -150,
  //   marginBottom: -150,
  //   marginLeft: -20,
  //   clipPath: 'inset(7% 0% 7% 0%)',
  // },
  pdfIFrameContainer: {
    marginTop: 50,
  },
  pdfIFrame: {
    height: '80vh',
    width: '100%',
  },
}));

let container = {};
const TermsRendered = ({ filename }) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="md" ref={(el) => container= el}>
        <CssBaseline />
        <div className={classes.paper}>
          <Logo width={150} fullColor/>
        </div>
        <div className={classes.pdfDocumentContainer}>
          <Document
            className={classes.pdfDocument}
            file={`/documents/${filename}`}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    className={classes.pdfDocumentPage}
                    width={container.offsetWidth}
                    // https://github.com/wojtekmaj/react-pdf/issues/695#issuecomment-768922757
                    renderMode={'svg'}
                    scale={1}
                  />
                ),
              )
            }
          </Document>
        </div>
      </Container>
    </div>
  );
};

const TermsIFrame = ({ filename }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="md" ref={(el) => container= el}>
        <CssBaseline />
        <div className={classes.paper}>
          <Logo width={150} fullColor/>
        </div>
        <div className={classes.pdfIFrameContainer}>
          <iframe className={classes.pdfIFrame} src={`/documents/${filename}`} />
        </div>
      </Container>
    </div>
  );
};

export default function Terms({ filename, iframe = false }) {
  if (iframe) {
    return (<TermsIFrame filename={filename} />);
  }
  return (<TermsRendered filename={filename} />);
}

Terms.propTypes = {
  filename: PropTypes.string.isRequired,
  iframe: PropTypes.bool.isRequired,
};
TermsIFrame.propTypes = {
  filename: PropTypes.string.isRequired,
};
TermsRendered.propTypes = {
  filename: PropTypes.string.isRequired,
};
