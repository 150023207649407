import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import InputMask from 'react-input-mask';

import {
  getParticipant,
} from 'graphql/queries';
import { updateParticipant } from 'graphql/mutations';
import { states } from 'utilities/constants';
import {
  asyncGet,
  asyncRetryMutation,
} from 'utilities/graph';
import AlertDialog from 'components/AlertDialog';

import { useStyles } from './styles';

const BasicInfo = ({ user }) => {
  const classes = useStyles();

  const [participant, setParticipant] = useState(null);
  const [error, setError] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const { username } = user;
  const canEdit = participant ? participant.status !== 'closed' : false;

  useEffect(() => {
    (async () => {
      try {
        const [participantRecord] = await Promise.all([
          asyncGet(getParticipant, {
            username,
          }),
        ]);

        setParticipant(participantRecord.data.getParticipant);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [username]);

  async function handleUpdateParticipant() {
    try {
      await asyncRetryMutation(updateParticipant, {
        input: {
          username: participant.username,
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          phoneNumber: participant.phoneNumber,
          address: {
            address1: participant.address.address1,
            address2: participant.address.address2,
            city: participant.address.city,
            state: participant.address.state,
            postalCode: participant.address.postalCode,
          },
          preferredContactType: participant.address.preferredContactType,
          governmentAffiliation: participant.governmentAffiliation,
          updatedBy: localStorage.getItem('ruc:username'),
        },
      }, {
        clearCacheKeys: [`${username}.GetParticipant`],
      });

      setOpenAlertDialog(true);
    } catch (e) {
      setError(e.message);
    }
  }

  function handleCloseError() {
    setError(false);
  }

  if (!participant) {
    return <div />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountBoxIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Update Profile
        </Typography>
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateParticipant();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                id="firstName"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                label="First Name"
                autoFocus
                defaultValue={participant.firstName}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, { firstName: e.target.value }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                  minLength: 2,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                defaultValue={participant.lastName}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, { lastName: e.target.value }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                  minLength: 2,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                disabled
                type="email"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                defaultValue={participant.email}
                inputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="(999) 999-9999"
                defaultValue={participant.phoneNumber}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, { phoneNumber: e.target.value }));
                }}
              >
                {() => {
                  return (
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="phone"
                      label="Phone Number"
                      id="phone"
                      autoComplete="phone"
                      inputProps={{
                        readOnly: !canEdit,
                      }}
                    />
                  );
                }}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="address1"
                name="address1"
                label="Address line 1"
                required
                fullWidth
                autoComplete="shipping address-line1"
                defaultValue={participant.address.address1}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, {
                    address: {
                      ...participant.address,
                      address1: e.target.value,
                    },
                  }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="address2"
                name="address2"
                label="Address line 2"
                fullWidth
                autoComplete="shipping address-line2"
                defaultValue={participant.address.address2}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, {
                    address: {
                      ...participant.address,
                      address2: e.target.value,
                    },
                  }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="city"
                name="city"
                label="City"
                required
                fullWidth
                autoComplete="shipping address-level2"
                defaultValue={participant.address.city}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, {
                    address: {
                      ...participant.address,
                      city: e.target.value,
                    },
                  }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="zip"
                name="zip"
                label="Zip / Postal code"
                fullWidth
                autoComplete="shipping postal-code"
                defaultValue={participant.address.postalCode}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, {
                    address: {
                      ...participant.address,
                      postalCode: e.target.value,
                    },
                  }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                variant="outlined"
                id="state"
                name="state"
                label="State/Province/Region"
                required
                fullWidth
                value={participant.address.state || 'select'}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, {
                    address: {
                      ...participant.address,
                      state: e.target.value,
                    },
                  }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                }}
              >
                {!participant.address.state && (
                  <MenuItem value="select">Select</MenuItem>
                )}
                {Object.keys(states).map((code, index) => {
                  return (<MenuItem key={index} value={code}>{states[code]}</MenuItem>);
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="governmentAffiliation"
                label="Government agency or company name"
                name="governmentAffiliation"
                defaultValue={participant.governmentAffiliation}
                onChange={(e) => {
                  setParticipant(Object.assign(participant, { governmentAffiliation: e.target.value }));
                }}
                inputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">How should we contact you?</FormLabel>
                <RadioGroup
                  aria-label="contact-method"
                  name="contact-method"
                  defaultValue="email"
                  onChange={(e) => {
                    setParticipant(Object.assign(participant, { preferredContactType: e.target.value }));
                  }}>
                  <FormControlLabel value="email" control={<Radio />} label="Email" disabled={!canEdit} />
                  <FormControlLabel value="phone" control={<Radio />} label="Phone" disabled={!canEdit} />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {!canEdit && (
            <Box marginTop={2}>
              <Alert severity="info">Changes to your profile are disabled until you have been fully onboarded.</Alert>
            </Box>
          )}
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!canEdit}
          >
            Update
          </Button>
        </form>
        <Snackbar
          open={error !== false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
        <AlertDialog
          open={openAlertDialog}
          onClose={() => setOpenAlertDialog(false)}
          title="Success!"
          text="Your account address / phone has been updated."
        />
      </div>
    </Container>
  );
};

BasicInfo.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
    }),
  }),
};

export default BasicInfo;
