import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import BillingExplanation from './BillingExplanation';

import ControlledInput from 'components/Form/ControlledInput';

import {
  createPaymentCard,
} from 'graphql/mutations';
import {
  asyncRetryMutation,
} from 'utilities/graph';

const RegisterBilling = ({
  participant,
  username,
  onPreviousStep,
  onCompleteStep,
}) => {
  const now = new Date();
  const classes = useStyles();

  // preference form state
  const { control, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  // overall state
  const [error, setError] = useState(false);

  const cardInputs = [{
    type: 'text',
    name: 'cardNumber',
    defaultValue: '4111111111111111',
    label: 'Card Number',
    required: true,
    disabled: true,
    invalidText: 'Card number is required',
  }, {
    type: 'text',
    name: 'nameOnCard',
    defaultValue: `${participant.firstName} ${participant.lastName}`,
    label: 'Name On Card',
    required: true,
    disabled: true,
    invalidText: 'Name on card is required',
  }, {
    type: 'number',
    name: 'expirationMonth',
    defaultValue: now.getMonth() + 1,
    label: 'Exp Month',
    required: true,
    disabled: true,
    invalidText: 'Expiration month is required',
  }, {
    type: 'number',
    name: 'expirationYear',
    defaultValue: now.getFullYear() + 1, // no expired cards
    label: 'Exp Year',
    required: true,
    disabled: true,
    invalidText: 'Expiration year is required',
  }, {
    type: 'text',
    name: 'cvc',
    defaultValue: '000',
    label: 'CVC',
    required: true,
    disabled: true,
    invalidText: 'CVC is required',
  }];

  async function handleBilling({
    expirationMonth,
    expirationYear,
    cardNumber,
    nameOnCard,
  }) {
    try {
      await Promise.all([
        asyncRetryMutation(createPaymentCard, {
          input: {
            username,
            brand: 'visa',
            expirationMonth,
            expirationYear,
            externalId: '1',
            isDefault: true,
            last4: cardNumber.substring(cardNumber.length - 4),
            nameOnCard,
            updatedBy: localStorage.getItem('ruc:username'),
          },
        }),
      ]);

      onCompleteStep();
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">Billing Information</Typography>
      <BillingExplanation />
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleBilling)}
        noValidate
      >
        <Grid container spacing={2}>
          {cardInputs.splice(0, 2).map((input, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}

          {cardInputs.map((input, index) => {
            return (
              <Grid item xs={4} key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...input}
                />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              onClick={() => {
                onPreviousStep();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterBilling.propTypes = {
  participant: PropTypes.object,
  username: PropTypes.string,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
};

export default RegisterBilling;
