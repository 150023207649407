import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';

import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { deleteVehicle } from 'graphql/mutations';
import { asyncRetryMutation } from 'utilities/graph';

const RemoveVehicleDialog = ({
  isOpen,
  onClose,
  vehicle,
}) => {
  const handleRemoveVehicle = async () => {
    const { id, username } = vehicle;
    await asyncRetryMutation(deleteVehicle, {
      input: {
        id,
        username,
      },
    });

    onClose(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="remove-vehicle-dialog"
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
      >
        <DialogTitle>Remove Vehicle</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this vehicle association?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-test-id="remove-vehicle-cancel"
            color="inherit"
            variant="contained"
            onClick={() => {
              onClose();
            }}
          >Cancel</Button>
          <Button
            data-test-id="remove-vehicle-confirm"
            color="primary"
            variant="contained"
            onClick={handleRemoveVehicle}
          >Confirm Remove</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider >
  );
};

RemoveVehicleDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  vehicle: PropTypes.object,
};

export default RemoveVehicleDialog;
