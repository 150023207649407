/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default function RoleExplanation({ pilot }) {
  return (
    <Box marginTop={2}>
      <Typography component="h6" variant="h6" gutterBottom color="primary">
        Complete Your Enrollment in the CT Mileage-Based User Fee (MBUF) Study
      </Typography>
      <Typography component="p" variant="body1" gutterBottom>
        Thank you for your interest in participating in the study, powered by Transurban. Let’s get started.
      </Typography>
      <Typography component="p" variant="body1" gutterBottom color="primary">
        <b>What’s your role?</b>
      </Typography>
      {
        pilot === 'MBUF' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2023. You will be asked for voluntary feedback along the way in two surveys (incentive eligibility is based on the completion of both surveys).
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You can chose a device or embedded telematics. If you choose a device your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process in complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You can choose between devices with or without GPS. A GPS device enables you to see exactly where you drove on a map.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                No real money will be exchanged as part of this Study, but you will recieve mock statements* via email and on your online Study account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax.
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom>
            <i>
              *No real money payment will be exchanged as part of this Study.
            </i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            Start enrolling by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'MBUF+TOD' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process is complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You can choose between devices with or without GPS. A GPS device enables you to see exactly where you drove on a map.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                An additional mock charge* of 5 cents will be applied to each mile driven during the following
                <Typography component="span" variant="body1" color="primary"><b> weekday rush hours</b></Typography>:
                Monday – Friday: 6 – 10 AM and 3 – 7 PM
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Any additional rush hour charges will be displayed on your mock statement* alongside the standard MBUF charge.
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom>
            <i>
              *No real money payment will be exchanged as part of this MBUF Study.
            </i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'MBUF+C' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process is complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                An additional mock charge* of $5.00 dollars per day will be applied to your account if you enter the Tysons Corner zone during weekday business hours, along with an additional 5 cents per mile for travel within the zone during
                <Typography component="span" variant="body1" color="primary">
                  <b> weekday business hours.  Weekday Tysons Corner zone business hours are: </b>
                </Typography>
                Monday – Friday: 6:00 AM to 7:00 PM
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom>
            <i>*No real money payment will be exchanged as part of this MBUF Study.</i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>For your reference, here is a map of the Tysons Corner zone:</b>
          </Typography>
          <img
            src={'/tyson-cordon.png'}
            width="100%"
            alt="tyson-cordon"
          />
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'MBUF+TF' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally do. The Study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <ul>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                Your mileage will be collected using an OBDII device that plugs into your car. The device will be mailed to you once the enrollment process is complete.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body1" gutterBottom>
                You will receive mock statements* via email that will include your standard mileage-based user fee (MBUF) along with any tolls you’ve been charged during the study.
              </Typography>
            </li>
          </ul>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>Toll roads part of our program:</b>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            The following toll roads in the Greater Washington Area are participating in our MBUF program.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            <ul>
              <li>I-95, I-395 and I-495 Express Lanes</li>
              <li>I-66 Inside the Beltway</li>
              <li>Dulles Toll Road</li>
            </ul>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            <i>*Please note, while the MBUF Study statements and the associated MBUF charges are for testing purposes only and no real payment will be exchanged, you will still need to pay tolls as you normally would through the Transurban GoToll app.</i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
      {
        pilot === 'OB-MBUF' &&
        <>
          <Typography component="p" variant="body1" gutterBottom>
            You just need to drive as you normally would and self-report your mileage once per month on your MBUF Study online account. Reminders will be sent via email each month. The study will run from June to the end of October 2021. You will be asked for voluntary feedback along the way.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom color="primary">
            <b>How does it work?</b>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            You will receive mock statements* via email and on your MBUF Study online account that will include a summary of your mileage-based user fee (MBUF) compared to the current state and federal gas tax based on the total miles you’ve driven.
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            <i>*No real money payment will be exchanged as part of this MBUF Study.</i>
          </Typography>
          <Typography component="p" variant="body1" gutterBottom style={{ marginTop: 16 }}>
            To enroll start by entering your email and new password below.
          </Typography>
        </>
      }
    </Box>
  );
}

RoleExplanation.propTypes = {
  pilot: PropTypes.string,
};
