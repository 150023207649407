/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Link as RouteLink,
  useHistory,
  useLocation,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import RegisterAuth from './components/RegisterAuth';
import RegisterParticipant from './components/RegisterParticipant';
import RegisterVehicle from './components/RegisterVehicle';
import RegisterReportingOptions from './components/RegisterReportingOptions';
import RegisterBilling from './components/RegisterBilling';
import RegisterAgreements from './components/RegisterAgreements';

import { useStyles } from './components/commonStyles';
import { Auth } from 'aws-amplify';

import { COLLECT_BILLING_INFORMATION } from 'utilities/constants/paymentStatus';
import { asyncGet, asyncListAll } from 'utilities/graph';
import {
  shellParticipant,
} from 'utilities/constants/shellModels';
import {
  getParticipant,
  listVehicles,
} from 'graphql/queries';

const ALLOWED_PILOTS = [
  'MBUF',
  'MBUF+ToD',
  'MBUF+C',
  'MBUF+TF',
  'OB-MBUF',
];

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cognitoUser, setCognitoUser] = useState({});
  const [participant, setParticipant] = useState(shellParticipant);
  const [pilotProgram, setPilotProgram] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [error, setError] = useState(false);

  // pilot program
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pilot = query.get('pilot') || 'MBUF';

  history.push('closed');

  useEffect(() => {
    if (!pilot || !ALLOWED_PILOTS.some((x) => x.toUpperCase() === pilot.toUpperCase())) {
      return history.push('/oops');
    }
  }, [pilot, history]);

  useEffect(() => {
    (async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const { username } = cognitoUser;
        setCognitoUser(cognitoUser);
        const [
          { data: { getParticipant: restoredParticipant } },
          vehicles,
        ] = await Promise.all([
          asyncGet(getParticipant, { username }, { bypassCache: true }),
          asyncListAll(listVehicles, { username }, { bypassCache: true }),
        ]);

        if (restoredParticipant.pilotProgram) {
          setParticipant(restoredParticipant);
          setPilotProgram(restoredParticipant.pilotProgram);
        }

        if (vehicles && vehicles.length > 0) {
          setVehicles([vehicles[0]]);
        }
      } catch (e) {
        if (e === 'not authenticated') {
          setActiveStep(0);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(cognitoUser).length < 1) {
      return;
    }

    const {
      signInUserSession: { accessToken: { payload } },
    } = cognitoUser;

    // admins go straight to dashboard
    const groups = payload['cognito:groups'];
    if (groups && groups[0] === 'Admins') {
      return history.push('/participants');
    }

    if (!participant.firstName || participant.firstName === '') {
      const idx = steps.findIndex((x) => x.title === 'Your Information');
      return setActiveStep(idx);
    }

    if (vehicles.length === 0) {
      const idx = steps.findIndex((x) => x.title === 'Vehicle Information');
      return setActiveStep(idx);
    }

    if (
      !participant.mroDevicePreference &&
      (participant.pilotProgram || {}).shortName !== 'OB-MBUF'
    ) {
      const idx = steps.findIndex((x) => x.title === 'Reporting Options');
      return setActiveStep(idx);
    }

    if (participant.status === 'created') {
      const title = COLLECT_BILLING_INFORMATION ? 'Billing Information' : 'Agreements';
      const idx = steps.findIndex((x) => x.title === title);
      return setActiveStep(idx);
    }

    history.push('/dashboard');
  }, [cognitoUser, participant, vehicles]); // exclude active step

  const steps = [
    {
      title: 'Create Account',
      component: <RegisterAuth
        key="register-auth"
        onCompleteStep={(newParticipant, cognitoUser) => {
          setParticipant(Object.assign({}, shellParticipant, newParticipant));
          setCognitoUser(cognitoUser);
          next();
        }}
      />,
    },
    {
      title: 'Your Information',
      component: <RegisterParticipant
        key="register-participant"
        cognitoUser={cognitoUser}
        participant={participant}
        onCompleteStep={(participantInfo, participantPilotProgram) => {
          setParticipant(Object.assign({}, participant, participantInfo));
          setPilotProgram(participantPilotProgram);
          next();
        }}
      />,
    },
    {
      title: 'Vehicle Information',
      component: <RegisterVehicle
        key="register-vehicle"
        username={cognitoUser.username}
        vehicle={vehicles[0]}
        onPreviousStep={() => {
          prev();
        }}
        onCompleteStep={(vehicle) => {
          setVehicles([vehicle]);
          setParticipant(Object.assign({}, participant, { mroDevicePreference: null }));
          next();
        }}
      />,
    },
    {
      title: 'Reporting Options',
      component: <RegisterReportingOptions
        key="register-reporting-options"
        cognitoUser={cognitoUser}
        pilotProgram={pilotProgram}
        vehicle={vehicles[0]}
        onPreviousStep={() => {
          prev();
        }}
        onCompleteStep={() => {
          next();
        }}
      />,
    },
    {
      title: 'Billing Information',
      exclude: !COLLECT_BILLING_INFORMATION,
      component: <RegisterBilling
        key="register-billing"
        participant={participant}
        username={cognitoUser.username}
        onPreviousStep={() => {
          prev();
        }}
        onCompleteStep={() => {
          next();
        }}
      />,
    },
    {
      title: 'Agreements',
      component: <RegisterAgreements
        key="register-agreements"
        participant={participant}
        username={cognitoUser.username}
        onPreviousStep={() => {
          prev();
        }}
        onCompleteStep={() => {
          history.push('/dashboard');
        }}
      />,
    },
  ].filter(({ exclude }) => !exclude);

  function next() {
    setActiveStep(activeStep + 1);
  }

  function prev() {
    if (activeStep <= 1) {
      return;
    }
    setActiveStep(activeStep - 1);
  }

  function handleCloseError() {
    setError(false);
  }

  if (isLoading) {
    return (
      <Grid container className={classes.spinner} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="sm" disableGutters={true}>
      <CssBaseline />
      <div className={classes.paper}>
        <Stepper data-stepper-id={'register'} activeStep={activeStep}>
          {steps.map((config, index) => {
            const { title } = config;
            return (
              <Step data-step-id={`register-${index}`} key={index}>
                <StepLabel>{title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {steps.map((config, index) => {
          if (index === activeStep) {
            const { component } = config;
            return (component);
          }

          return (
            <div key={index}></div>
          );
        })}

        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
      <Grid container justify="center" style={{ marginBottom: 16 }}>
        <Grid item>
          <Link to="/signin" href="#" variant="body2" component={RouteLink}>
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </Container >
  );
};

export default SignUp;
