export const getParticipantInquiry = /* GraphQL */ `
  query GetParticipantInquiry($id: ID!) {
    getParticipantInquiry(id: $id) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      participant {
        email
        firstName
        isVIP
        lastName
        mroDataProviderPreference
        mroDevicePreference
        onboardedDate
        pilotProgram {
          name
        }
        phoneNumber
        preferredContactType
        status
        username
        vehicles {
          items {
            isPrimary
            vin
            make
            model
            year
            licensePlate
            registrationState
            beginningOdometerReading
            currentOdometerReading
            mileage
            mro {
              deviceSerialNumber
              deviceImei
              manufacturer
              gpsEnabled
            }
          }
        }
      }
    }
  }
`;

export const updateParticipantInquiry = /* GraphQL */ `
  mutation UpdateParticipantInquiry(
    $input: UpdateParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    updateParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      participant {
        email
        firstName
        isVIP
        lastName
        mroDataProviderPreference
        mroDevicePreference
        onboardedDate
        pilotProgram {
          name
        }
        phoneNumber
        preferredContactType
        status
        username
      }
    }
  }
`;
